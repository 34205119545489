import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink, Modal, ModalBody } from 'reactstrap';
import './NavMenu.scss';
import LogoCGD from '../../img/logo_cgd.svg';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            visibility: true
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({
            visibility: !this.state.visibility
        });
    }

    render() {
        const { globalResources } = this.props;

        return (
            <header>
                <Navbar className="main-nav bg-info fixed-top" dark>
                    <Container>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-auto" />
                        <Modal isOpen={!this.state.visibility} fade={false} toggle={this.toggleNavbar} wrapClassName="main-menu" backdropClassName="bg-info">
                            <ModalBody>
                                <Navbar dark>
                                    <Container>
                                        <NavbarToggler onClick={this.toggleNavbar} className="mr-auto" />
                                        <NavbarBrand tag={Link} to="/" className="mr-2">
                                            <img src={LogoCGD} className="my-1" height="46" alt={globalResources.LogoCGDAltText} />
                                        </NavbarBrand>
                                    </Container>
                                </Navbar>

                                <Nav className="mt-4" navbar>
                                    <NavItem>
                                        <NavLink tag={Link} to="/" onClick={this.toggleNavbar} className="text-light">{globalResources.Menu1}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#aderir" onClick={this.toggleNavbar} className="text-light">{globalResources.Menu2}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#vantagens" onClick={this.toggleNavbar} className="text-light">{globalResources.Menu3}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#faq" onClick={this.toggleNavbar} className="text-light">{globalResources.Menu4}</NavLink>
                                    </NavItem>
                                </Nav>
                            </ModalBody>
                        </Modal>
                        <NavbarBrand tag={Link} to="/" className="mr-2">
                            <img src={LogoCGD} className="my-1" height="46" alt={globalResources.LogoCGDAltText} />
                        </NavbarBrand>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
