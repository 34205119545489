import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Form, FormGroup, Label, Alert } from 'reactstrap';
import { NifField } from '../Fields/NifField';
import { PhoneNumberField } from '../Fields/PhoneNumberField';
import TooltipItem from '../Fields/TooltipItem';
import { AuthNavigation } from './AuthNavigation';

class RequestTokenForm extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            authenticationData: {
                nif: '',
                mobilePhoneNumber: ''
            },
            countryCode: 'PT',
            mobilePhoneDialogValue: 'PT',
            response: '',
            loading: false,
            errors: [],
            isValid: false
        };

        this.requestFormSubmit = this.requestFormSubmit.bind(this);
        this.requestToken = this.requestToken.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    requestFormSubmit(event) {
        event.preventDefault();

        //console.log('erros:', this.state.errors);

        if (!this.hasErrors()) {
            this.setState({ loading: true });
            this.requestToken();
        }
    }

    async requestToken() {
        const { authenticationData } = this.state;
        const data = authenticationData;
        const response = await fetch('/Authentication/SendOtpToken', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        //console.log('requestToken', response);

        if (response.status === 200 && response.ok) {
            this.props.requestTokenSuccess(authenticationData);
        } else {
            this.setState({ loading: false });

            this.props.validateTokenError(this.props.globalResources.ErrorGateway);
        }
    }

    handleCompareKeys(a, b) {
        var aKeys = Object.keys(a).sort();
        var bKeys = Object.keys(b).sort();

        //console.log('handleCompareKeys', JSON.stringify(aKeys) === JSON.stringify(bKeys));

        return JSON.stringify(aKeys) === JSON.stringify(bKeys);
    }

    hasErrors() {
        var errors = Object.values(this.state.errors);
        var hasErrors = errors.length === 0 || errors.reduce(function (acc, value) {
            return acc || value;
        }, false);

        return hasErrors;
    }

    handleChange(event, target, errors, rawData) {
        const { name, value } = !!rawData ? rawData : target !== undefined ? event : event.target;
        //console.log(event, name, value);

        this.setState(prevState => ({
            authenticationData: {
                ...prevState.authenticationData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: errors
            }
        }), () => this.setState({
            isValid: !this.hasErrors() && this.handleCompareKeys(this.state.errors, this.state.authenticationData)
        }));
    }

    render() {
        const { authenticationData, countryCode, mobilePhoneDialogValue, isValid, loading } = this.state;
        const { globalResources, formResources, errorMessage } = this.props;

        return (
            <Form className="needs-validation" noValidate>
                <FormGroup tag="fieldset" className="mb-0 min-lg-h300">
                    <legend className="text-primary"><strong>{formResources.RequestTokenLegend}</strong></legend>

                    {errorMessage &&
                        <Alert key={errorMessage} color="danger"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                    }

                    <FormGroup>
                        <Label for="nif" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelNIF}*</Label>
                        <TooltipItem key="FormTooltipNIF" id="FormTooltipNIF" text={formResources.FormTooltipNIF} />
                        <NifField id="nif" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorNIF }} inputValue={authenticationData.nif} country={countryCode} onChangeFunction={this.handleChange} required={true} autoFocus={true} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="mobilePhoneNumber" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelMobilephoneNumb}*</Label>
                        <TooltipItem key="FormTooltipMobilephone" id="FormTooltipMobilephone" text={formResources.FormTooltipMobilephone} />
                        <PhoneNumberField id="mobilePhoneNumber" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorMobilephoneNumb }} inputValue={authenticationData.mobilePhoneNumber} country={mobilePhoneDialogValue} onChangeFunction={this.handleChange} required={true} />
                    </FormGroup>
                </FormGroup>

                <AuthNavigation linkURL={"/Authentication"} linkText={globalResources.NavBackText} buttonName={globalResources.NavSubmitText} buttonClickFunction={this.requestFormSubmit} buttonDisable={!isValid} loading={loading} loadingText={globalResources.LoadingPageText} />
            </Form>
        );
    }
}

export default withRouter(RequestTokenForm);