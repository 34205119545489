import React, { Component } from 'react';

export class MainFormNavInfo extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { activeStep, formResources } = this.props;
        const mainFormSteps = [
            {
                index: '1',
                text: formResources.Step1Title,
                activeStep: activeStep
            },
            {
                index: '2',
                text: formResources.Step2Title,
                activeStep: activeStep
            },
            {
                index: '3',
                text: formResources.Step3Title,
                activeStep: activeStep
            },
            {
                index: '4',
                text: formResources.Step4Title,
                activeStep: activeStep
            },
            {
                index: '5',
                text: formResources.Step5Title,
                activeStep: activeStep
            }
        ];

        const stepList = mainFormSteps.map((event, index) =>
            <li key={`step-${index}`} className="nav-item" {...event.index === activeStep.toString() ? { "aria-current": "step" } : {}}>
                <strong className="txt">{event.text}</strong>
            </li>
        );

        return (
            <ol className="nav nav-tabs nav-form">
                {stepList}
            </ol>
        );
    }
}
