import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import { LogoutButton } from '../Fields/LogoutButton';

export class LogoutBar extends Component {
    static displayName = LogoutBar.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            authenticated: false
        };
    }

    componentDidMount() {
        this.populateAuthenticationState();
    }

    async populateAuthenticationState() {
        await authService.isAuthenticated().then((response) => {
            response ? this.setState({ authenticated: true }) : this.setState({ authenticated: false });
        });
    }

    render() {
        const { authenticated } = this.state;
        const { globalResources } = this.props;

        if (authenticated) {
            return (
                <div className="page-logoutbar fixed-top bg-light">
                    <Container>
                        <Row>
                            <Col className="text-right my-2">
                                <LogoutButton name={globalResources.LogoutLinkButton} message={globalResources.LogoutTextMessage} loadingText={globalResources.LoadingPageText} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        } else {
            return null;
        }
    }
}
