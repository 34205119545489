import React, { Component } from 'react';
import IcnLock from '../../img/icn/icn_lock_field.svg';
import IcnEdit from '../../img/icn/icn_edit_field.svg';

export class SideBarStep2 extends Component {
    static displayName = SideBarStep2.name;

    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { formResources } = this.props;

        return (
            <aside className="sidebar">
                <h2 id="sidebar" className="mb-4">{formResources.SideBarStep2Title}</h2>

                <div dangerouslySetInnerHTML={{ __html: formResources.SideBarStep2Descrition1 }} />
                <div>
                    <dl>
                        <dt className="float-left">
                            <img src={IcnLock} className="mr-2" width="19" alt="Cadeado" />
                        </dt>
                        <dd className="mb-3">
                            Dados que não carecem de alteração
                        </dd>
                        <dt className="float-left">
                            <img src={IcnEdit} className="mr-2" width="19" alt="Lápis de edição" />
                        </dt>
                        <dd className="mb-3">
                            Dados que podem ser alterados
                        </dd>
                        <dt className="float-left">
                            <span className="text-primary ml-1 mr-3">*<span className="sr-only"> de obrigatoriedade</span></span>
                        </dt>
                        <dd className="mb-3">
                            Dados de preenchimento obrigatório
                        </dd>
                        <dt className="float-left">
                            <span className="icn-tooltip ml-0 mr-2">i<span className="sr-only"> de informação</span></span>
                        </dt>
                        <dd className="mb-3">
                            Consulta de informação adicional
                        </dd>
                    </dl>
                    <div dangerouslySetInnerHTML={{ __html: formResources.SideBarStep2Descrition2 }} />
                </div>
            </aside>
        );
    }
}
