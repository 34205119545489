import React, { Component } from 'react';
import { Row, Col, Form, FormGroup, Label, Alert, Button } from 'reactstrap';
import { SMSTokenField } from '../Fields/SMSTokenField';
import { SubmitButton } from '../Fields/SubmitButton';
import Loading from '../Global/Loading';
import cryptoService from './CryptoService';

export class DigitalSignatureConfirmation extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            authenticationData: {
                token: '',
                processId: this.props.processId || ''
            },
            loadingResend: false,
            loadingValidate: false,
            errors: [],
            errorMessage: '',
            infoMessage: '',
            isValid: false
        };

        this.submit = this.submit.bind(this);
        this.resend = this.resend.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.resendToken = this.resendToken.bind(this);
        this.validateToken = this.validateToken.bind(this);
    }

    cancel(event) {
        event.preventDefault();
        this.props.cancel();
    }

    resend(event) {
        event.preventDefault();
        this.setState({ loadingResend: true });
        this.resendToken();
    }

    submit(event) {
        event.preventDefault();

        if (!this.hasErrors()) {
            this.setState({ loadingValidate: true });
            this.validateToken();
        }
    }

    async resendToken() {
        const { authenticationData } = this.state;
        const data = {
            processId: authenticationData.processId
        };
        const response = await fetch('/DigitalSignature/RequestNewToken', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        var result = await response.json();

        if (response.status === 200 && response.ok && !result.error) {
            this.setState({
                infoMessage: this.props.globalResources.ErrorSMSCode,
                errorMessage: ''
            });
        } else {
            this.setState({
                infoMessage: '',
                errorMessage: this.props.globalResources.Erro_Interno
            });
        }

        this.setState({
            loadingResend: false
        });

        window.scrollTo(0, 0);
    }

    async validateToken() {
        const { authenticationData } = this.state;
        const publicKey = await cryptoService.getPublicKey();
        const encryptedToken = cryptoService.encryptMessage(authenticationData.token, publicKey);
        const data = {
            token: encryptedToken,
            processId: authenticationData.processId
        };
        const response = await fetch('/DigitalSignature/ValidateDigitalSignToken', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        var result = await response.json();

        if (response.status === 200 && response.ok && !result.error) {
            this.props.signPdfConfirmationSuccess(result.value);
        } else {
            this.setState({
                loadingValidate: false,
                errorMessage: result && result.message ? result.message : this.props.globalResources.Erro_Interno
            });

            window.scrollTo(0, 0);
        }
    }

    hasErrors() {
        var errors = Object.values(this.state.errors);

        var hasErrors = errors.length === 0 || errors.reduce(function (acc, value) {
            return acc || value;
        }, false);

        return hasErrors;
    }

    handleChange(event, target, errors, rawData) {
        const { name, value } = !!rawData ? rawData : target !== undefined ? event : event.target;

        this.setState(prevState => ({
            authenticationData: {
                ...prevState.authenticationData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: errors
            }
        }), () => this.setState({
            isValid: !this.hasErrors()
        }));
    }

    render() {
        const { authenticationData, isValid, loadingResend, loadingValidate, errorMessage, infoMessage } = this.state;
        const { globalResources, formResources } = this.props;

        return (
            <Row>
                <Col className="order-first order-lg-last col-lg-6 offset-lg-1">
                    <aside className="description" dangerouslySetInnerHTML={{ __html: globalResources.DigitalSignatureConfirmationDescription }} />
                </Col>
                <Col lg="5">
                    <Form className="needs-validation" noValidate>
                        <FormGroup tag="fieldset" className="mb-0">
                            <legend className="text-primary"><strong>{formResources.TokenLegend}</strong></legend>

                            <div className="lead" dangerouslySetInnerHTML={{ __html: formResources.AMADigitalSignatureDescription }} />

                            {errorMessage &&
                                <Alert key={errorMessage} color="danger"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                            }

                            {infoMessage &&
                                <Alert key={infoMessage} color="info" className="text-primary"><div dangerouslySetInnerHTML={{ __html: infoMessage }} /></Alert>
                            }

                            <Row>
                                <Col className="col-lg-12">
                                    <FormGroup>
                                        <Label for="smsCode" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelTokenCode}*</Label>
                                        <SMSTokenField id="token" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorSMSCode }} inputValue={authenticationData.token} onChangeFunction={this.handleChange} required={true} autoFocus={true} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        <Row>
                            <Col className="col-6 col-lg-6 align-self-center">
                                <Button
                                    type="button"
                                    color="link"
                                    onClick={this.cancel}
                                >
                                    <span aria-hidden="true">&lt;&nbsp;</span>
                                    {globalResources.NavBackText}
                                </Button>
                            </Col>
                            <Col className="col-6 col-lg-6 text-right align-self-center">
                                <SubmitButton
                                    name={globalResources.NavValidateText}
                                    onClickFunction={this.submit}
                                    disabled={!isValid}
                                    loading={loadingValidate}
                                />
                            </Col>
                        </Row>

                        <p className="mt-3">
                            {globalResources.TokenResendCodeText} {globalResources.TokenResendCode}.
                        </p>

                        {loadingResend
                            ? <Loading loadingText={globalResources.LoadingSentData} />
                            : <Button
                                type="button"
                                color="link"
                                onClick={this.resend}
                            >
                                {globalResources.TokenResendCode}
                            </Button>
                        }
                    </Form>
                </Col>
            </Row>
        );
    }
}