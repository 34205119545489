import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, FormGroup, Label, Alert, Button } from 'reactstrap';
import { PhoneNumberField } from '../Fields/PhoneNumberField';
import { PinField } from '../Fields/PinField';
import TooltipItem from '../Fields/TooltipItem';
import { SubmitButton } from '../Fields/SubmitButton';
import cryptoService from './CryptoService';
import { ConfigManager } from '../Global/SettingsManager';

export class DigitalSignatureRequest extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            mobilePhone: this.props.mobilePhone,
            authenticationData: {
                pin: ''
            },
            loading: false,
            errors: [],
            errorMessage: '',
            isValid: false,
            configs: {},
        };

        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.validateConfig = this.validateConfig.bind(this);
        this.signPdfRequest = this.signPdfRequest.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.validateConfig();
    }

    submit(event) {
        event.preventDefault();

        if (!this.hasErrors()) {
            this.setState({ loading: true });
            this.signPdfRequest();
        }
    }

    cancel(event) {
        event.preventDefault();
        this.props.cancel();
    }

    async validateConfig() {
        var configManager = new ConfigManager();
        const _enableDigitalSign = await configManager.enableDigitalSign();
        if (this.state.configs) {
            this.setState({
                configs: {
                    enableDigitalSign: _enableDigitalSign
                }
            });
        }
    }

    async signPdfRequest() {
        const { authenticationData } = this.state;
        const publicKey = await cryptoService.getPublicKey();
        const encryptedPin = cryptoService.encryptMessage(authenticationData.pin, publicKey);

        const data = {
            pin: encryptedPin
        };
        const response = await fetch('/DigitalSignature/DocumentDigitalSign', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        var result = await response.json();

        if (response.status === 200 && response.ok && !result.error) {
            this.props.signPdfRequestSuccess(result.value);
        } else {
            this.setState({
                loading: false,
                errorMessage: result && result.message ? result.message : this.props.globalResources.Erro_Interno
            });

            window.scrollTo(0, 0);
        }
    }

    handleCompareKeys(a, b) {
        var aKeys = Object.keys(a).sort();
        var bKeys = Object.keys(b).sort();

        return JSON.stringify(aKeys) === JSON.stringify(bKeys);
    }

    hasErrors() {
        var errors = Object.values(this.state.errors);
        var hasErrors = errors.length === 0 || errors.reduce(function (acc, value) {
            return acc || value;
        }, false);

        return hasErrors;
    }

    handleChange(event, target, errors, rawData) {
        const { name, value } = !!rawData ? rawData : target !== undefined ? event : event.target;

        this.setState(prevState => ({
            authenticationData: {
                ...prevState.authenticationData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: errors
            }
        }), () => this.setState({
            isValid: !this.hasErrors() && this.handleCompareKeys(this.state.errors, this.state.authenticationData)
        }));
    }

    render() {
        const { authenticationData, configs, mobilePhone, isValid, loading, errorMessage } = this.state;
        const { globalResources, formResources } = this.props;

        if (configs !== null && configs.enableDigitalSign) {
            return (
                <Row>
                    <Col className="order-first order-lg-last col-lg-6 offset-lg-1">
                        <aside className="description" dangerouslySetInnerHTML={{ __html: globalResources.DigitalSignatureDescription }} />
                    </Col>
                    <Col lg="5">
                        <Form className="needs-validation" noValidate>
                            <FormGroup tag="fieldset" className="mb-0">
                                <legend className="text-primary"><strong>{formResources.AMADigitalSignatureLegend}</strong></legend>

                                {errorMessage &&
                                    <Alert key={errorMessage} color="danger"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                                }

                                <FormGroup>
                                    <Label for="mobilePhone" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelMobilephoneNumb}</Label>
                                    <PhoneNumberField id="mobilePhone" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorMobilephoneNumb }} inputValue={mobilePhone} readonly={true} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pin" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelPin}*</Label>
                                    <TooltipItem key="FormTooltipPin" id="FormTooltipPin" text={formResources.FormTooltipPin} />
                                    <PinField id="pin" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorPin }} inputValue={authenticationData.pin} onChangeFunction={this.handleChange} required={true} />
                                </FormGroup>
                            </FormGroup>

                            <Row>
                                <Col className="col-6 align-self-center">
                                    <Button
                                        type="button"
                                        color="link"
                                        onClick={this.cancel}
                                    >
                                        <span aria-hidden="true">&lt;&nbsp;</span>
                                        {globalResources.NavBackText}
                                    </Button>
                                </Col>
                                <Col className="col-6 text-right align-self-center">
                                    <SubmitButton
                                        name={globalResources.NavSubmitText}
                                        disabled={!isValid}
                                        onClickFunction={this.submit}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            );
        } else {
            return (
                <>
                    <Row>
                        <Col className="order-first order-lg-last col-lg-6 offset-lg-1">
                            <aside className="description" dangerouslySetInnerHTML={{ __html: globalResources.DigitalSignatureDescription }} />
                        </Col>
                        <Col lg="5" className="align-self-center">
                            <Alert key={globalResources.DigitalSignatureDescriptionWarning} color="danger"><div dangerouslySetInnerHTML={{ __html: globalResources.DigitalSignatureDescriptionWarning }} /></Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6 align-self-center">
                            <Button
                                type="button"
                                color="link"
                                onClick={this.cancel}
                            >
                                <span aria-hidden="true">&lt;&nbsp;</span>
                                {globalResources.NavBackText}
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        }

    }
}