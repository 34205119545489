import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class EmailField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            minLength: 5,
            maxLength: 250,
            touched: {}
        }

        // this.regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&' * +/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        
        this.regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.turnFieldTouched = this.turnFieldTouched.bind(this);
    }

    componentDidMount() {
        const { id, inputValue } = this.props;

        !!inputValue && this.turnFieldTouched(id);

        let hasError = !this.validate(inputValue) ? true : '';
        this.setState({
            errors: !this.validate(inputValue)
        });
        let target = document.getElementById(`${id}`);
        this.props.onChangeFunction(target, '', hasError, { name: target.name, value: target.value, checked: false, files: undefined });
    }

    handleChange(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        this.turnFieldTouched(name);

        this.setState({
            errors: !this.validate(value)
        });

        //if (value === '' || (this.regex.test(value) && value.length >= this.state.minLength)) {
        this.props.onChangeFunction(event.target, '', hasError);
        //}
    }

    handleBlur(event) {
        const { name, value } = event.target;

        this.turnFieldTouched(name);

        if (value !== '') {
            var hasError = !this.validate(value);

            this.setState({
                errors: hasError
            });
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    validate(email) {
        if (email !== null) {
            if (!this.regex.test(email) && email.length !== this.state.minLength) {
                return false;
            }
        } else {
            return false;
        }

        return this.regex.test(email);
    }

    render() {
        const { minLength, maxLength, errors, touched } = this.state;
        const { id, inputHelpText, inputValidationText, inputValue, required, autoFocus, readonly, disabled } = this.props;

        return (
            <>
                <Input
                    type="email"
                    name={id}
                    id={id}
                    aria-describedby={`${id}Help`}
                    invalid={errors}
                    defaultValue={inputValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    minLength={minLength}
                    maxLength={maxLength}
                    required={required}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="email"
                    autoFocus={autoFocus}
                    spellCheck="false"
                    readOnly={readonly}
                    disabled={disabled}
                />
                {inputHelpText &&
                    <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                }
                {inputValidationText &&
                    <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                }
            </>
        );
    }
}
