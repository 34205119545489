import React, { Component } from 'react';
import { Row, FormGroup, CustomInput, Label, Input } from 'reactstrap';
import { CountingInput } from './CountingInput';

export class PoliticalRelations extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      politicalRelations: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    let element = this.props.politicalRelations.find((el) => el.code === this.props.selectValue)
    this.setState({
      politicalRelations: this.props.politicalRelations,
      setSelect: element ? element.code : '',
      errors: !this.props.selectValue,
    });

    this.props.onChangeFunction('', '', !this.props.selectValue, {
      name: 'politicalRelationCode',
      value: this.props.selectValue,
      checked: false,
      files: undefined,
      type: 'radio',
    });
  }



  handleChange(e) {
    this.setState({
      // politicalRelation: e.target.value,
      setSelect: e.target.value,
      errors: !e.target.value,
    });
    let hasError = !e.target.value;
    this.props.onChangeFunction(e.target, '', false, hasError);
  }

  render() {
    const { errors, setSelect } = this.state;
    const {
      data,
      touched,
      required,
      selectValue,
      formResources,
      onChangeFunction,
      inputValidationText,
    } = this.props;

    return (
      <>
        <FormGroup tag="fieldset">
          <legend className="form-label">
            {formResources.PoliticalRelationLegend1}
          </legend>

          <Row>
            <FormGroup tag="fieldset" className="col form-group">
              {this.state.politicalRelations.map((item, key) => (
                <CustomInput
                  className="py-1"
                  type="radio"
                  name="politicalRelationCode"
                  id={item.code}
                  key={item.code}
                  value={item.code}
                  label={item.description}
                  checked={setSelect === item.code}
                  onChange={this.handleChange}
                  data-description={item.description}
                  invalid={errors === null}
                  required={required}
                />
              ))}
              {inputValidationText && (
                <div
                  className={`invalid-feedback ${
                    !selectValue ? 'visible' : ''
                  }`}
                >
                  {this.state.errors ? inputValidationText.empty : null}
                </div>
            )}

                        {setSelect !== '' && setSelect !== '0'  && (
              <fieldset className="form-label mb-4 mt-2">
                {formResources.PoliticalRelationLegend3}
                            </fieldset>)}
            </FormGroup>
          </Row>

          {(setSelect === '1' || setSelect === '3') && (
            <>
              <FormGroup tag="fieldset">
                <legend className="form-label">
                  {formResources.PoliticalRelationLegend2}
                </legend>

                <FormGroup>
                  <Row>
                    <FormGroup className="col-sm-6 col-md-5">
                      <Label
                        for="position"
                        className="form-label text-uppercase"
                      >
                        {formResources.FormLabelPosition}*
                      </Label>
                      <Input
                        type="text"
                        name="position"
                        id="position"
                        maxLength={30}
                        defaultValue={data.position}
                        onBlur={this.handleBlur}
                        onChange={onChangeFunction}
                        onLoad={onChangeFunction}
                        required={true}
                        invalid={
                          data.politicalRelationCode &&
                          (data.position === null || data.position === '')
                        }
                      />
                      <small
                        id={`positionHelp`}
                        className="form-text text-right text-primary"
                      >
                        <CountingInput
                          limit="30"
                          actual={data.position ? data.position.length : 0}
                        />
                      </small>
                      <div className="invalid-feedback">
                        {touched.position
                          ? formResources.FormErrorPosition
                          : formResources.FormErrorFieldEmpty}
                      </div>
                    </FormGroup>

                    <FormGroup className="col-sm-6 col-md-4">
                      <Label for="entity" className="form-label text-uppercase">
                        {formResources.FormLabelEntity}*
                      </Label>
                      <Input
                        type="text"
                        name="entity"
                        id="entity"
                        maxLength={30}
                        defaultValue={data.entity}
                        onBlur={this.handleBlur}
                        onChange={onChangeFunction}
                        onLoad={onChangeFunction}
                        required={true}
                        invalid={
                          data.politicalRelationCode &&
                          (data.entity === null || data.entity === '')
                        }
                      />
                      <small
                        id={`entityHelp`}
                        className="form-text text-right text-primary"
                      >
                        <CountingInput
                          limit="30"
                          actual={data.entity !== null ? data.entity.length : 0}
                        />
                      </small>
                      <div className="invalid-feedback">
                        {touched.entity
                          ? formResources.FormErrorEntity
                          : formResources.FormErrorFieldEmpty}
                      </div>
                    </FormGroup>
                  </Row>
                </FormGroup>
              </FormGroup>
            </>
          )}
        </FormGroup>
      </>
    );
  }
}
