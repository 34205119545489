import React, { Component } from 'react';
import { LogoutBar } from '../Global/LogoutBar';
import { Banner } from './Banner';
import { Benefits } from './Benefits';
import { CreateCard } from './CreateCard';
import { FAQ } from './FAQs';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        const { globalResources, faqsHP } = this.props;

        return (
            <main className="page-content">
                <LogoutBar globalResources={globalResources} />
                <Banner globalResources={globalResources} />
                <Benefits globalResources={globalResources} />
                <CreateCard globalResources={globalResources} />
                <FAQ globalResources={globalResources} faqsHP={faqsHP} />
            </main>
        );
    }
}
