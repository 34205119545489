import { User } from './User';

export class UserManager {
    async isAuthenticated() {
        const response = await fetch('/Authentication/IsAuthenticated', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' }
        });
        var result = await response.json();

        //console.log('isAuthenticated', result);

        if (response.status === 200 && response.ok && !result.error) {
            return true;
        } else {
            return false;
        }
    }

    async signIn(data) {
        const response = await fetch('/Authentication/SignIn', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        var result = await response.json();

        if (response.status === 200 && !result.error) {
            let user = new User({
                access_token: result.jwtToken,
                profile: result.username,
                expires_at: result.expirationDate
            });

            return user;
        } else {
            let user = new User({
                access_token: '',
                profile: '',
                expires_at: '',
                errorCode: result.message
            });

            return user;
        }
    }

    async signOut() {
        const response = await fetch('/Authentication/Logout', {
            method: 'POST',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            keepalive: true
        });

        if (response.status === 200 && response.ok) {
            window.location.href = "/";
        }
    }
}