import React, { Component } from 'react';
import { Row, FormGroup, Label, Input } from 'reactstrap';
import TooltipItem from '../Fields/TooltipItem';

export class PhoneNumberField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            maxLength: '',
            touched: {}
        }

        this.regex = /^[0-9]*$/;

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.turnFieldTouched = this.turnFieldTouched.bind(this);
    }

    componentDidMount() {
        const { country, id, inputValue } = this.props;

        country === 'PT' &&
            this.setState({
                maxLength: 9
            });

        if (!!inputValue) {
            this.turnFieldTouched(id);
        } else {
            this.setState({
                errors: true
            });

            this.props.onChangeFunction && this.props.onChangeFunction('', '', true, { name: id, value: inputValue, checked: false, files: undefined });
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        this.turnFieldTouched(name);

        if (this.props.country === 'PT') {
            if (value === '' || (this.regex.test(value) && value.length <= this.state.maxLength)) {
                this.setState({
                    errors: hasError
                });

                this.props.onChangeFunction(event.target, '', hasError);
            }
        } else {
            //TODO
            if (value === '' || !this.regex.test(value)) {
                this.setState({
                    errors: true
                });

                this.props.onChangeFunction(event.target, '', true);
            }
        }
    }

    handleBlur(event) {
        const { name, value } = event.target;

        this.turnFieldTouched(name);

        if (this.props.country === 'PT') {
            if (value !== '') {
                var hasError = !this.validate(value);

                this.setState({
                    errors: hasError
                });
            }
        } else {
            //TODO
            if (value === '') {
                this.setState({
                    errors: true
                });
            } else {
                this.setState({
                    errors: false
                });
            }
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    validate(number) {
        if (number !== null) {
            if (!this.regex.test(number) && number.length !== this.state.maxLength) {
                return false;
            }
        } else {
            return false;
        }

        if (this.props.type === 'phone') {
            const phoneNumber = /^((2[\d]{8})|(30[\d]{7}))$/;

            return phoneNumber.test(number);
        } else {
            const mobilePhoneNumber = /^(9[1236][\d]{7})$/;

            return mobilePhoneNumber.test(number);
        }
    }

    render() {
        const { maxLength, errors, touched } = this.state;
        const { id, mobilePhoneDialogCodeID, mobilePhoneID, tootipMobilePhoneDialogCode, inputHelpText, inputValidationText, defaultDialogCode, inputValue, tootipMobilePhone, formResources, required, showContryCodeValue, autoFocus, readonly, disabled } = this.props;

        return (
            mobilePhoneDialogCodeID
                ? <Row>
                    <FormGroup className="col-4 col-md-2">
                        <Label for={mobilePhoneDialogCodeID} className="form-label text-uppercase">{formResources.FormLabelMobilePhoneDialogCode}{required && "*"}</Label>
                        {tootipMobilePhoneDialogCode &&
                            <TooltipItem key={`FormTooltip${mobilePhoneDialogCodeID}`} id={`FormTooltip${mobilePhoneDialogCodeID}`} text={tootipMobilePhoneDialogCode} />
                        }
                        <Input
                            type="text"
                            name={mobilePhoneDialogCodeID}
                            id={mobilePhoneDialogCodeID}
                            defaultValue={defaultDialogCode}
                            required={required}
                            autoComplete="tel-country-code"
                            autoFocus={autoFocus}
                            readOnly={true}
                            disabled={disabled}
                        />
                    </FormGroup>
                    <FormGroup className="col-8 col-md-4">
                        <Label for={mobilePhoneID} className="form-label text-uppercase">{formResources.FormLabelMobilephone}{required && "*"}</Label>
                        {tootipMobilePhone &&
                            <TooltipItem key={`FormTooltip${mobilePhoneID}`} id={`FormTooltip${mobilePhoneID}`} text={tootipMobilePhone} />
                        }
                        <Input
                            type="tel"
                            name={mobilePhoneID}
                            id={mobilePhoneID}
                            aria-describedby={`${id}Help`}
                            invalid={errors}
                            defaultValue={inputValue}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            maxLength={maxLength}
                            required={required}
                            autoComplete="tel-national"
                            autoFocus={autoFocus}
                            readOnly={readonly}
                            disabled={disabled}
                        />
                        {inputHelpText &&
                            <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                        }
                        {inputValidationText &&
                            <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                        }
                    </FormGroup>
                </Row>
                : <>
                    <Input
                        type="tel"
                        name={id}
                        id={id}
                        aria-describedby={`${id}Help`}
                        invalid={errors}
                        defaultValue={inputValue}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        maxLength={maxLength}
                        required={required}
                        autoComplete={showContryCodeValue ? "tel" : "tel-national"}
                        autoFocus={autoFocus}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                    {inputHelpText &&
                        <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                    }
                    {inputValidationText &&
                        <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                    }
                </>
        );
    }
}
