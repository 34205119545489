import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class NameField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            minLength: 2,
            maxLength: '',
            touched: {}
        }

        this.regex = /[a-zA-Z\u00C0-\u017F\s]+/;

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        const { id, inputValue } = this.props;

        if (!!inputValue) {
            this.turnFieldTouched(id);
        } else {
            this.setState({
                errors: true
            });

            this.props.onChangeFunction && this.props.onChangeFunction('', '', true, { name: id, value: inputValue, checked: false, files: undefined });
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        this.turnFieldTouched(name);

        if (value === '' || this.regex.test(value)) {
            this.props.onChangeFunction(event.target, '', hasError);
        }
    }

    handleBlur(event) {
        const { name, value } = event.target;

        this.turnFieldTouched(name);

        if (value !== '') {
            var hasError = !this.validate(value);

            this.setState({
                errors: hasError
            });
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    validate(text) {
        if (text !== null) {
            if (!this.regex.test(text) && text.length !== this.state.minLength) {
                return false;
            }
        } else {
            return false;
        }

        return this.regex.test(text);
    }

    render() {
        const { minLength, maxLength, errors, touched } = this.state;
        const { id, inputHelpText, inputValidationText, inputValue, required, autoFocus, readonly, disabled } = this.props;

        return (
            <>
                <Input
                    type="text"
                    name={id}
                    id={id}
                    aria-describedby={`${id}Help`}
                    invalid={errors}
                    defaultValue={inputValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    minLength={minLength}
                    maxLength={maxLength}
                    required={required}
                    autoComplete="name"
                    autoFocus={autoFocus}
                    readOnly={readonly}
                    disabled={disabled}
                />
                {inputHelpText &&
                    <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                }
                {inputValidationText &&
                    <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                }
            </>
        );
    }
}
