import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string'
import ImgCardCaixaIUOnly from '../../img/content/card-caixa-iu_only.png';
import './SuccessPage.scss';

class SuccessPage extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        const values = queryString.parse(this.props.location.search);
        let hasError = !values.code

        this.state = {
            error: hasError,
            values: !!values.code ? values.code : '',
            ...this.props.history.location.state
        }
    }

    componentDidMount() {
        if (this.state.error) {
            this.props.history.push('/ErrorPage');
        }
    }

    render() {
        const { globalResources } = this.props;
        const { values } = this.state;
        let sucessTitle, sucessDescription;

        switch (values) {
            case 'FormAlreadySubmittedException':
                sucessTitle = globalResources.AlreadySubmittedTitle;
                sucessDescription = globalResources.ErrorFormAlreadySubmitted;
                break;
            default:
                sucessTitle = globalResources.SuccessPageTitle;
                sucessDescription = globalResources.SuccessPageDescription;
                break;
        }

        return (
            <main className="page-content success-pages">
                <Container role="alert">
                    <Row>
                        <Col lg="5">
                            <h1>{sucessTitle}</h1>
                            <div className="mb-5 min-lg-h300" dangerouslySetInnerHTML={{ __html: sucessDescription }}></div>

                            <Link to="/" className="text-uppercase px-4 py-2 btn btn-primary">
                                <span aria-hidden="true">&lt;&nbsp;</span>
                                {globalResources.GoToHomepageText}
                            </Link>
                        </Col>
                        <Col lg="7" className="text-center text-lg-right">
                            <p><img className="img" src={ImgCardCaixaIUOnly} alt="" /></p>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
};

export default SuccessPage;