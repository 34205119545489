import React, { Component } from "react";
import { Label, Input } from 'reactstrap';
import TooltipItem from '../Fields/TooltipItem';

class CountryAutoCompleteField extends Component {
    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: this.props.defaultValue,
            userCode: this.props.defaultCode,
            noSuggestions: false,
            errors: '',
            touched: {}
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.validate = this.validate.bind(this);
        this.turnFieldTouched = this.turnFieldTouched.bind(this);
    }

    async fetchData(method, params = undefined) {
        const response = await fetch(`/form/${method}${params ? (`?${params}`) : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await response.json();
    }

    turnFieldTouched(field) {
        const { touched } = this.state

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    handleBlur(event) {
        const { name } = event.target;

        this.turnFieldTouched(name);
    }

    onChange = (e) => {
        const { fieldName, fieldNameCode, updateOnlyFields, onChangeFunction } = this.props

        const userInput = e.currentTarget.value;
        this.turnFieldTouched(fieldName);

        if (userInput.length > 2) {
            this.setState({ noSuggestions: false });

            this.fetchData('GetCountry', `description=${userInput}`)
                .then(filteredSuggestions => {
                    if (filteredSuggestions.length > 0 && (filteredSuggestions[0].description === userInput.toUpperCase())) {
                        if (updateOnlyFields) {
                            onChangeFunction('', '', '', { name: `${fieldNameCode}`, value: filteredSuggestions[0].code, checked: false, files: undefined });
                            onChangeFunction('', '', '', { name: `${fieldName}`, value: filteredSuggestions[0].description, checked: false, files: undefined });
                        } else {
                            onChangeFunction('', '', '', { name: "issuer", value: filteredSuggestions[0].code, checked: false, files: undefined });
                            onChangeFunction('', '', '', { name: `issuerValue`, value: filteredSuggestions[0].description, checked: false, files: undefined });
                        }
                        this.setState({
                            activeSuggestion: 0,
                            filteredSuggestions: [],
                            showSuggestions: false,
                            userInput: filteredSuggestions[0].description,
                            userCode: filteredSuggestions[0].code,
                            errors: false
                        });
                    } else {
                        this.setState({
                            activeSuggestion: 0,
                            filteredSuggestions,
                            showSuggestions: true,
                            userInput,
                            noSuggestions: filteredSuggestions.length > 0 ? false : true,
                            errors: true
                        });

                        var hasError = (userInput === '' || this.state.showSuggestions === true || this.state.noSuggestions === true) ? true : false;
                        this.setState({
                            errors: hasError
                        });

                        if (updateOnlyFields) {
                            onChangeFunction('', '', hasError, { name: `${fieldNameCode}`, value: hasError ? '' : e.target.dataset.code, checked: false, files: undefined });
                            onChangeFunction('', '', hasError, { name: `${fieldName}`, value: hasError ? '' : e.currentTarget.innerText, checked: false, files: undefined });
                        } else {
                            onChangeFunction('', '', hasError, { name: "issuer", value: hasError ? '' : e.target.dataset.code, checked: false, files: undefined });
                            onChangeFunction('', '', hasError, { name: "issuerValue", value: hasError ? '' : e.currentTarget.innerText, checked: false, files: undefined });
                        }
                    }
                });
        } else {
            var hasError = true;
            this.setState({
                userInput: e.currentTarget.value,
                errors: hasError
            });

            if (updateOnlyFields) {
                onChangeFunction('', '', hasError, { name: `${fieldNameCode}`, value: '', checked: false, files: undefined });
                onChangeFunction('', '', hasError, { name: `${fieldName}`, value: '', checked: false, files: undefined });
            } else {
                onChangeFunction('', '', hasError, { name: "issuer", value: '', checked: false, files: undefined });
                onChangeFunction('', '', hasError, { name: "issuerValue", value: '', checked: false, files: undefined });
            }
        }
    };

    onClick = (e) => {
        const { fieldName, fieldNameCode, updateOnlyFields, onChangeFunction } = this.props

        if (updateOnlyFields) {
            onChangeFunction('', '', '', { name: `${fieldNameCode}`, value: e.target.dataset.code, checked: false, files: undefined });
            onChangeFunction('', '', '', { name: `${fieldName}`, value: e.currentTarget.innerText, checked: false, files: undefined });
        } else {
            onChangeFunction('', '', '', { name: "issuer", value: e.target.dataset.code, checked: false, files: undefined });
            onChangeFunction('', '', '', { name: `issuerValue`, value: e.currentTarget.innerText, checked: false, files: undefined });
        }

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            userCode: e.target.dataset.code,
            errors: false
        });
    };

    validate() {
        const { userInput, showSuggestions, noSuggestions } = this.state;

        return !userInput || (userInput !== null && userInput.length < 2) || showSuggestions === true || noSuggestions === true ? true : false
    }

    componentDidMount() {
        const { fieldName, fieldNameCode, userInput, onChangeFunction } = this.props
        let target = document.getElementById(`${fieldName}`);

        userInput && this.turnFieldTouched(fieldName);

        var hasError = this.validate();
        this.setState({
            errors: hasError
        });

        if (hasError) {
            onChangeFunction(target, '', hasError, { name: fieldNameCode, value: target.dataset.code, checked: false, files: undefined });
            onChangeFunction(target, '', hasError, { name: fieldName, value: target.value, checked: false, files: undefined });
        }
    }

    render() {
        const {
            onClick,
            onChange,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
                userCode,
                noSuggestions,
                touched,
                errors
            },
            props: {
                fieldName,
                tootipText,
                labelText,
                validationText,
                required,
                readonly,
                disabled
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion.description} data-code={suggestion.code} onClick={onClick}>
                                    {suggestion.description}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {

            }
        }
        return (
            <>
                <Label for={fieldName} className="form-label text-uppercase">{labelText}{required && "*"}</Label>
                {tootipText &&
                    <TooltipItem key={`FormTooltip${fieldName}`} id={`FormTooltip${fieldName}`} text={tootipText} />
                }
                <Input
                    type="text"
                    name={fieldName}
                    id={fieldName}
                    onBlur={this.handleBlur}
                    onChange={onChange}
                    value={userInput ? userInput : ''}
                    invalid={!!noSuggestions || !userInput || !!errors}
                    data-code={userCode}
                    required={required}
                    autoComplete="country-name"
                    readOnly={readonly}
                    disabled={disabled}
                />
                {suggestionsListComponent}
                {validationText &&
                    <div className="invalid-feedback">{touched[fieldName] ? validationText.invalid : validationText.empty}</div>
                }
            </>
        );
    }
}

export default CountryAutoCompleteField;
