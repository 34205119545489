import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

export class CookieBar extends Component {
    static displayName = CookieBar.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            hidden: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        localStorage.getItem('cookies') &&
            this.setState({
                hidden: true
            });
    }

    handleClick(event) {
        event.preventDefault();

        this.setState({
            hidden: !this.state.hidden
        });

        localStorage.setItem('cookies', true);
    }

    render() {
        const { hidden } = this.state;
        const { globalResources } = this.props;

        return (
            <div className="page-cookiebar fixed-bottom bg-primary text-light" role="dialog" aria-hidden={hidden}>
                <Container>
                    <Row className="my-4 align-items-center text-center">
                        <Col className="text-md-left">
                            <div dangerouslySetInnerHTML={{ __html: globalResources.CookieBarDescription }} />
                        </Col>
                        <Col xs="12" md="auto" className="text-md-right">
                            <Button
                                type="button"
                                color="light"
                                outline
                                onClick={this.handleClick}
                            >
                                {globalResources.NavAcceptText}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
