import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Alert, Row, Col, Button } from 'reactstrap';
import { SubmitButton } from '../Fields/SubmitButton';
import {FirstExperienceField} from '../Fields/FirstExperienceField';
import { SecondExperienceFields } from '../Fields/SecondExperienceFields';
import { ThirdFiedQuiz } from '../Fields/ThirdFieldQuiz';
import { FouthFieldQuiz } from '../Fields/FourthFieldQuiz';
import { FifthFieldQuiz } from '../Fields/fifthFieldQuiz';

class DownloadPdf extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            loading: false,
            errorMessage: '',
            QuizData: {}
        };

        this.download = this.download.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        this.formSubmit();
    }

    componentDidMount(){
        this.getData();
    }

    async getData(){
        const responde = await fetch('/Form/GetQuiz',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if(responde.status === 200 && responde.ok){
            let result = await responde.json();

            this.setState({
                ...this.state,
                QuizData: result
            });
        }else{console.log('Error')}
    }

    async formSubmit() {
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append('Data', JSON.stringify(this.props.data));
        var headers = new Headers({});

        const response = await fetch('/Form/SubmitForm', {
            method: 'POST',
            headers:headers,
            body: formData,
        });
        var result = await response.json();

        if (response.status === 200 && response.ok && !result.error) {
            this.props.history.push('/SuccessPage', {error: false });
        } else {
            this.setState(
                {
                    errorMessage: this.props.globalResources.ErrorPageDescription,
                    loading: false
                }
            );

            window.scrollTo(0, 0);
        }
    }

    cancel(event) {
        event.preventDefault();
        this.props.cancel();
    }

    download(event) {
        event.preventDefault();
        window.open('/Form/DownloadForm', "_blank");
    }

    render() {
        const { loading, errorMessage } = this.state;
        const { globalResources, formResources, data} = this.props;


        let quiz1, quiz2, quiz3, quiz4, quiz5 = true;

        if(data && this.state.QuizData.questions){
            quiz1 = this.state.QuizData.questions[0].isRequired? !data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[0].code) : false
            quiz2 = this.state.QuizData.questions[1].isRequired? !data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[1].code) : false
            quiz3 = this.state.QuizData.questions[2].isRequired? (!data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[2].code)? true: data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[2].code).ResponseValue === ""? true: false ) : false

            let responseData = data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[3].code);
            
            let responseDataFifth = data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[4].code);
            
            if (this.state.QuizData.questions[3].isRequired || !!responseData ){
                let codeNeedComments = this.state.QuizData.questions[3].answers.find(elem => elem.needComments === true).code
                let needComments = !responseData? true : responseData.ResponseCodes.find(obj => obj.Code === codeNeedComments);
                if(needComments !== undefined){
                    if(responseDataFifth){
                        quiz4 = responseDataFifth.ResponseValue !== null? false: true
                        //console.log(quiz4);
                    }else{
                        quiz4 = true;
                    }
                }else if(responseData) {
                    quiz4 = false;
                }
            }else{
                quiz4 = false
            }
            
            quiz5 = this.state.QuizData.questions[5].isRequired? !data.Questions.find(obj => obj.QuestionCode === this.state.QuizData.questions[5].code) : false
        }

        return (
            <>
                <Row>
                    <Col className="col-lg-8 min-lg-h300">
                        <div className="">
                            <h1>{globalResources.DigitalSignatureTitle}</h1>

                            <div dangerouslySetInnerHTML={{ __html: globalResources.DigitalSignatureText }} />

                            <Button
                                type="button"
                                color="secondary"
                                outline
                                onClick={this.download}
                                className="mb-5"
                            >
                                {globalResources.NavDownloadForm}
                            </Button>
                        </div>

                        {errorMessage &&
                            <Alert key={errorMessage} color="danger" className="mb-5"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-8 min-lg-h300">
                        <p className='h3 mb-3'>{formResources.QuizTitle}</p>
                        <FirstExperienceField formResources={formResources} data={this.state.QuizData.questions} onChange={this.props.onChange} questionsData={this.props.data}></FirstExperienceField>
                        <SecondExperienceFields formResources={formResources} data={this.state.QuizData.questions} onChange={this.props.onChange} questionsData={this.props.data}></SecondExperienceFields>
                        <ThirdFiedQuiz formResources={formResources} maxLength={200} data={this.state.QuizData.questions} onChange={this.props.onChange} questionsData={this.props.data}></ThirdFiedQuiz>
                        <FouthFieldQuiz formResources={formResources} data={this.state.QuizData.questions} onChange={this.props.onChange} questionsData={this.props.data}></FouthFieldQuiz>
                        <FifthFieldQuiz formResources={formResources} data={this.state.QuizData.questions} onChange={this.props.onChange} questionsData={this.props.data}></FifthFieldQuiz>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6 col-lg-4 align-self-center">
                        <Button
                            type="button"
                            color="link"
                            onClick={this.cancel}
                        >
                            <span aria-hidden="true">&lt;&nbsp;</span>
                            {globalResources.NavBackText}
                        </Button>
                    </Col>
                    <Col className="col-6 col-lg-4 text-right align-self-center">
                        <SubmitButton
                            name={globalResources.NavSubmitProposalText}
                            onClickFunction={this.submit}
                            loading={loading}
                            disabled={quiz1 || quiz2 || quiz3 || quiz4 || quiz5}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withRouter(DownloadPdf);