import React, { Component } from "react";
import { Input, Label, Button } from "reactstrap";
import Loading from '../Global/Loading';
import { ReactComponent as IcnCamera } from '../../img/icn/icn_camera.svg';
import icnFileUploaded from '../../img/icn/icn_file_upload.svg';

export class UploadField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            loading: false,
            errors: false,
            isValid: false,
            touched: false,
            uploadStatus: false,
            fileMaxSize: '',
            fileSizes: {},
            errorType: ''
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleInputFile = this.handleInputFile.bind(this);
    }

    async fetchData(method, data) {
        let formData = new FormData();
        formData.append('files', data, "teste");
        const response = await fetch(`/form/${method}`, {
            method: 'POST',
            body: formData
        });

        if (response.status === 200) {
            return await response.json();
        } else {
            return [];
        }
    }

    handleInputFile(e, fromDefault = false) {
        const { inputName, onChangeFunction, renderThumb, onlyImages = false, fileMaxSize } = this.props;
        //const { fileMaxSize } = this.state;

        this.setState(() => ({
            touched: true,
            uploadStatus: true
        }));

        let fileEl = !fromDefault ? e.target.files[0] : e;
        document.querySelector(`#${inputName}__wrapper .result`).innerHTML = "";
        let fileTypes = fileEl !== undefined ? onlyImages ? (fileEl.type === 'image/jpeg' || fileEl.type === 'image/png') : (fileEl.type === 'application/pdf' || fileEl.type === 'image/jpeg' || fileEl.type === 'image/png') : '';

        if (fileEl && fileEl.size <= fileMaxSize && fileTypes) {
            if (renderThumb && fileEl.type !== 'application/pdf') {
                this.fetchData('PhotoPreview', fileEl).then(
                    ({ content }) => {
                        if (content !== undefined) {
                            let dataImg = `data:image/jpeg;base64,${content}`;

                            var img = document.createElement('img');
                            img.src = dataImg;
                            img.classList.add('uploadFileThumb')
                            var nameFile = document.createElement('span');
                            nameFile.innerHTML = fileEl.name
                            nameFile.classList.add('uploadFileName');

                            document.querySelector(`#${inputName}__wrapper .result`).appendChild(img);
                            document.querySelector(`#${inputName}__wrapper .result`).appendChild(nameFile);

                            this.setState(() => ({
                                uploadStatus: false, errors: false
                            }));
                        } else {
                            this.setState(() => ({
                                uploadStatus: false, errors: true
                            }));
                        }
                    }
                );
            } else {
                var img = document.createElement('img');
                img.src = icnFileUploaded;
                img.classList.add('uploadFileThumb');

                var nameFile = document.createElement('span');
                nameFile.innerHTML = fileEl.name;
                nameFile.classList.add('uploadFileName');

                document.querySelector(`#${inputName}__wrapper .result`).appendChild(img);
                document.querySelector(`#${inputName}__wrapper .result`).appendChild(nameFile);

                this.setState(() => ({
                    uploadStatus: false, errors: false
                }));
            }

            !fromDefault && onChangeFunction(e);
            fromDefault && onChangeFunction(e, '', null, { name: inputName, value: '', checked: false, files: [e] });
        } else {
            let errorType = fileEl && !(fileEl.size <= fileMaxSize) ? 'size' : 'type';

            var hasError = true;
            this.setState({
                errorType,
                errors: hasError,
                uploadStatus: false
            });

            onChangeFunction(e, '', hasError, { name: inputName, value: '', checked: false, files: undefined });
        }
    }

    componentDidMount() {
        const { defaultValue, onChangeFunction, inputName } = this.props;

        if (!!defaultValue) {
            this.setState({
                touched: true
            });

            this.handleInputFile(defaultValue, true);
        } else {
            var hasError = true;
            this.setState({
                errors: hasError
            });

            onChangeFunction('', '', hasError, { name: inputName, value: '', checked: false, files: undefined });
        }
    }

    render() {
        const { inputName, inputLabel, onlyImages, errorMessages, formResources, required, readonly, disabled } = this.props;
        const { uploadStatus, errors, touched, errorType } = this.state;

        return (
            <>
                <Label for={inputName} className="form-label text-uppercase">{inputLabel}{required && "*"}</Label>
                <div id={`${inputName}__wrapper`} className="customUploadFile" onClick={() => document.querySelector(`#${inputName}__wrapper .js-uploadFile`).click()}>
                    {uploadStatus && <Loading loadingText={formResources.LoadingImageContentText} />}

                    <div className={`customUploadFile__wrapper ${uploadStatus ? 'd-none' : ''}`}>
                        <Input
                            type="file"
                            name={inputName}
                            id={inputName}
                            aria-describedby={`${inputName}Help`}
                            invalid={errors}
                            className="d-none js-uploadFile"
                            onChange={this.handleInputFile}
                            required={required}
                            accept={onlyImages ? "image/jpeg,image/png" : "application/pdf,image/jpeg,image/png"}
                            autoComplete="photo"
                            readOnly={readonly}
                            disabled={disabled}
                        />
                        <div className="result">

                        </div>
                        <div className="controllers">
                            <Button
                                type="button"
                                color="link"
                            >
                                <IcnCamera alt="Camera" />
                                <span className="buttonDivisor" />
                                Upload
                            </Button>
                        </div>
                    </div>
                </div>
                <small id={`${inputName}Help`} className="form-text text-left text-primary">{onlyImages ? formResources.FormHelperUploadPhoto : formResources.FormHelperUploadDocument}</small>
                {errorMessages && <div className={`invalid-feedback ${!uploadStatus && errors ? 'visible' : ''}`} >{!touched ? errorMessages.empty : errorType === 'size' ? errorMessages.invalidSize : errorMessages.invalid}</div>}
            </>
        );
    }
};

export default UploadField;