import React, { Component } from 'react';
import { FormGroup, Label, Input} from 'reactstrap';

export class FouthFieldQuiz extends Component{
    constructor(props){
        super(props);
        this.onchangeValue = this.onchangeValue.bind(this);
        this.ischeck = this.ischeck.bind(this);
        this.verifyneedComments = this.verifyneedComments.bind(this);
    }

    onchangeValue(event){
        let obj = this.props.questionsData;
        let name= event.target.name;
        let value= event.target.value;
        
        if(event.target.type === "text"){
            let idxFifth = obj.Questions.findIndex(object => {return object.QuestionCode === name});
            value = value === "" ? null : value;
            obj.Questions[idxFifth].ResponseValue = value;
        }else{
            let idx =  obj.Questions.findIndex(object => {
                return object.QuestionCode === name;
            });

            if(idx === undefined || idx === -1){
                obj.Questions.push({QuestionCode: name, ResponseCodes:[{Code: value}]});
                if(value === this.verifyneedComments()){
                    obj.Questions.push({QuestionCode: '005', ResponseValue: null});
                }
            }else{
                let idxResponseCode = obj.Questions[idx].ResponseCodes.findIndex(object => {
                    return object.Code === value
                });
                //add ResponseCode
                if(idxResponseCode === -1){
                    obj.Questions[idx] = {QuestionCode: name, ResponseCodes:[...obj.Questions[idx].ResponseCodes, {Code: value}]}
                    if(value === this.verifyneedComments()){
                        obj.Questions.push({QuestionCode: '005', ResponseValue: null});
                    }
                }else{
                    //Remove Respose code
                    let responseCodes = obj.Questions[idx].ResponseCodes;
                    
                    let responseCodeRemoved = responseCodes.filter((elem) => {
                        return elem.Code !== value
                    });
                    
                    if(responseCodeRemoved.length === 0){
                        obj.Questions = obj.Questions.filter((elem) => {
                            return elem.QuestionCode !== name
                        });
                    }else{
                        obj.Questions[idx] = {QuestionCode: name, ResponseCodes: responseCodeRemoved}
                    }

                    if(value === this.verifyneedComments()){
                        obj.Questions = obj.Questions.filter((elem) =>{
                            return elem.QuestionCode !== '005'
                        });
                    }

                }
            }
        }
        this.props.onChange({name: "questionData", value: obj}, "", false);
    }


    ischeck(respose, code){
        return !!(!respose? false : respose.ResponseCodes.find(elem => elem.Code === code))
    }

    verifyneedComments(){
        return !this.props.data[3] ? undefined : this.props.data[3].answers.find(elem => elem.needComments === true).code; 
    }

    render(){
        const {data, questionsData,formResources} = this.props;
        let fourthData = ""
        let fifthData = "";
        let codeRequiredInput;
        if(data){
            fourthData = data[3];
            fifthData = data[4];
            codeRequiredInput = fourthData.answers.find(elem => {
                return elem.needComments === true; 
            }).code;
        }

        let responseData = questionsData.Questions.find(obj => obj.QuestionCode === fourthData.code);
        let responseDataFifth = questionsData.Questions.find(obj => obj.QuestionCode === fifthData.code);
        

        let needComments = !responseData? true : responseData.ResponseCodes.find(obj => obj.Code === this.verifyneedComments());
        let otherInputValid = true;
        if(needComments !== undefined){
            if(responseDataFifth){
                otherInputValid = responseDataFifth.ResponseValue !== null? false: true
            }
        }else if(responseData){
            otherInputValid = false;
        }

        return(
            <FormGroup className="col p-0 mb-5">
                <Label className="h6"><strong>{fourthData.text}</strong> {fourthData.isRequired? " *": ""} </Label>

                {fourthData && fourthData.answers.map((obj) => 
                    <FormGroup key={`form-${obj.code}`} className={this.verifyneedComments() === obj.code? "mb-0": ""}>
                        <Input 
                            key={`checkbox-${obj.code}`} id={obj.code}
                            name={fourthData.code} 
                            className="ml-0" 
                            checked = {this.ischeck(responseData, obj.code)}
                            value={obj.code} 
                            type="checkbox" 
                            onChange={this.onchangeValue}
                        />
                        <Label key={`label-${obj.code}`} htmlFor={obj.code} className="form-label ml-4">{obj.text}</Label>
                    </FormGroup>
                )}

                {responseDataFifth && 
                    <Input
                        name={fifthData.code}
                        id={codeRequiredInput}
                        value={!responseData? "" : responseData.ResponseCodes.find((el) => el.Code === this.verifyneedComments()).Comments}
                        onChange={this.onchangeValue}
                        type="text"
                    />
                }

                {((otherInputValid && fourthData && fourthData.isRequired) || (otherInputValid && responseData)) &&
                        <>
                        <div className="is-invalid"></div>
                        <div className="invalid-feedback">
                            {formResources.FieldRequired}
                        </div> 
                    </>
                }
            </FormGroup>
        )
    }
}