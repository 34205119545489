export class ResourcesManager {
    async getResources() {
        const response = await fetch('/Resources/GetResources', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data;
        }

        return null;
    }
}