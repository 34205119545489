import React, { Component } from 'react';
import { FormGroup, Label, Input} from 'reactstrap';
import { CountingInput } from './CountingInput';

export class ThirdFiedQuiz extends Component{
    constructor(props){
        super(props);
        this.onchangeValue = this.onchangeValue.bind(this);
    }

    onchangeValue(event){
        let obj = this.props.questionsData;

        let name= event.target.name;
        let value= event.target.value;
        let idx =  obj.Questions.findIndex(object => {
            return object.QuestionCode === name;
        });

        if(idx === undefined || idx === -1){
            obj.Questions.push({QuestionCode: name, ResponseValue: value});
        }else{
            obj.Questions[idx] = {QuestionCode: name, ResponseValue: value}
        }


        this.props.onChange({name: "questionData", value:obj}, "", false);
    }

    render(){
        const {data, maxLength, questionsData, formResources} = this.props;
        let thirdData = ""
        if(data){thirdData = data[2];}

        let responseData = questionsData.Questions.find(obj => obj.QuestionCode === thirdData.code);

        let responseEmpty = !responseData? 0 : responseData.ResponseValue.length

        return(
            <FormGroup className="col p-0 mt-4">
                <Label className="h6"><strong>{thirdData.text}</strong> {thirdData.isRequired? " *": ""}</Label>
                <Input
                    type="textarea" 
                    className="FeedBackTextAreaQuiz" 
                    rows={6}  
                    maxLength={maxLength} 
                    onChange={this.onchangeValue}
                    defaultValue = {!responseData? "" : responseData.ResponseValue}
                    name={thirdData.code}>
                </Input>
                <div className='row ml-0 justify-content-between'>
                    <div>
                        {thirdData && thirdData.isRequired && !responseEmpty &&
                            <>
                                <div className="is-invalid d-none"></div>
                                <div className="invalid-feedback w-auto" >
                                    {formResources.FieldRequired}
                                </div> 
                            </>
                        }
                    </div>
                    
                    <small className="col-auto form-text text-right text-primary">
                        <CountingInput limit={maxLength} actual={!responseData? 0 : responseData.ResponseValue.length} />
                    </small>
                </div>
            </FormGroup>
        )
    }
}