import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class NifField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            maxLength: '',
            touched: {}
        }

        this.regex = /^[0-9]*$/;

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        const { country, inputValue, id } = this.props;

        country === 'PT' &&
            this.setState({
                maxLength: 9
            });

        if (!!inputValue) {
            this.turnFieldTouched(id);
        } else {
            this.setState({
                errors: true
            });

            this.props.onChangeFunction && this.props.onChangeFunction('', '', true, { name: id, value: inputValue, checked: false, files: undefined });
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        this.turnFieldTouched(name);

        if (this.props.country === 'PT') {
            if (value === '' || (this.regex.test(value) && value.length <= this.state.maxLength)) {
                this.setState({
                    errors: hasError
                });

                this.props.onChangeFunction(event.target, '', hasError);
            }
        } else {
            //TODO
            if (value === '' || this.regex.test(value)) {
                this.setState({
                    errors: true
                });

                this.props.onChangeFunction(event.target, '', true);
            }
        }
    }

    handleBlur(event) {
        const { name, value } = event.target;

        this.turnFieldTouched(name);

        if (this.props.country === 'PT') {
            if (value !== '') {
                var hasError = !this.validate(value);

                this.setState({
                    errors: hasError
                });
            }
        } else {
            //TODO
            if (value === '') {
                this.setState({
                    errors: true
                });
            } else {
                this.setState({
                    errors: false
                });
            }
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    validate(number) {
        if (number !== null) {
            if (!this.regex.test(number) && number.length !== this.state.maxLength) {
                return false;
            }
        } else {
            return false;
        }

        const total = number[0] * 9 + number[1] * 8 + number[2] * 7 + number[3] * 6 + number[4] * 5 + number[5] * 4 + number[6] * 3 + number[7] * 2;
        const mod11 = (Number(total) % 11);
        const checkDigit = mod11 < 2 ? 0 : 11 - mod11;

        return checkDigit === Number(number[8]);
    }

    render() {
        const { maxLength, errors, touched } = this.state;
        const { id, inputHelpText, inputValidationText, inputValue, required, autoFocus, readonly, disabled } = this.props;

        return (
            <>
                <Input
                    type="text"
                    name={id}
                    id={id}
                    aria-describedby={`${id}Help`}
                    invalid={errors}
                    defaultValue={inputValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    maxLength={maxLength}
                    required={required}
                    autoFocus={autoFocus}
                    inputMode="numeric"
                    readOnly={readonly}
                    disabled={disabled}
                />
                {inputHelpText &&
                    <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                }
                {inputValidationText &&
                    <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                }
            </>
        );
    }
}
