import React, { Component } from 'react';
import { Button } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import Loading from '../Global/Loading';

export class LogoutButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            loading: false
        };

        this.requestLogout = this.requestLogout.bind(this);
    }

    requestLogout(event) {
        event.preventDefault();
        this.setState({ loading: true });

        this.logoutForm();
    }

    async logoutForm() {
        await authService.signOut();
    }

    render() {
        const { loading } = this.state;
        const { name, loadingText } = this.props;

        return (
            loading
                ? <Loading loadingText={loadingText} />
                : <Button
                    type="button"
                    color="link"
                    className="p-2"
                    onClick={this.requestLogout}
                >
                    {name ? name : ''}
                </Button>
        );
    }
}
