import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = props => {
    const { id, text, invertColor } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <span className={`icn-tooltip${invertColor ? ` icn-tooltip__invert` : ''} mr-1`} id={`Tooltip-${id}`}>i<span className="sr-only"> de informação</span></span>
            <Tooltip
                placement="right"
                modifiers={{ offset: { offset: '0, 15px' } }}
                isOpen={tooltipOpen}
                target={`Tooltip-${id}`}
                toggle={toggle}
                autohide={false}
            >
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </Tooltip>
        </>
    );
};

export default TooltipItem;