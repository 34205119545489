import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class SMSTokenField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            maxLength: 6,
            touched: {}
        }

        this.regex = /^[0-9]*$/;

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        const { id, inputValue } = this.props;

        if (!!inputValue) {
            this.turnFieldTouched(id);
        } else {
            this.setState({
                errors: true
            });

            this.props.onChangeFunction && this.props.onChangeFunction('', '', true, { name: id, value: inputValue, checked: false, files: undefined });
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        if (this.props.required) {
            this.turnFieldTouched(name);

            if (value === '' || (this.regex.test(value) && value.length <= this.state.maxLength)) {
                this.props.onChangeFunction(event.target, '', hasError);

                if (value.length === this.state.maxLength) {
                    this.setState({
                        errors: hasError
                    });
                }

                if (value === '') {
                    this.setState({
                        errors: true
                    });
                } else {
                    this.setState({
                        errors: false
                    });
                }
            }
        } else {
            if (value !== '' || (this.regex.test(value) && value.length <= this.state.maxLength)) {
                this.props.onChangeFunction(event.target, '', hasError);

                if (value.length === this.state.maxLength) {
                    this.setState({
                        errors: hasError
                    });
                }

                if (value === '') {
                    this.setState({
                        errors: false
                    });
                }
            }
        }
    }

    handleBlur(event) {
        const { name, value } = event.target;
        var hasError = !this.validate(value);

        this.turnFieldTouched(name);

        if (this.props.required) {
            this.setState({
                errors: hasError
            });
        } else {
            if (value !== '') {
                this.setState({
                    errors: hasError
                });
            }
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }

    validate(number) {
        if (this.regex.test(number) && number.length === this.state.maxLength) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { maxLength, errors, touched } = this.state;
        const { id, inputHelpText, inputValidationText, inputValue, required, autoFocus, readonly, disabled } = this.props;

        return (
            <>
                <Input
                    type="text"
                    name={id}
                    id={id}
                    aria-describedby={`${id}Help`}
                    invalid={errors}
                    value={inputValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    maxLength={maxLength}
                    required={required}
                    autoComplete="one-time-code"
                    autoFocus={autoFocus}
                    inputMode="numeric"
                    readOnly={readonly}
                    disabled={disabled}
                />
                {inputHelpText &&
                    <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                }
                {inputValidationText &&
                    <div className="invalid-feedback">{touched[id] ? inputValidationText.invalid : inputValidationText.empty}</div>
                }
            </>
        );
    }
}
