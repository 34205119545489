import React, { Component } from 'react';
import { Row, FormGroup, Label, Input } from 'reactstrap';
import TooltipItem from '../Fields/TooltipItem';
import CountryAutoCompleteField from './CountryAutoCompleteField';

export class IdentificationField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            identificationTypes: {},
            inputHelpTextState: '',
            maxLength: 8,
            bidNumb: 8,
            ccNumb: 12,
            othersNumb: 20,
            step: 1,
            errors: false,
            showHiddenField: false,
            setSelect: this.props.selectValue,
            touched: {}
        }

        this.regex = /^[0-9]*$/;
        this.biNumb = 8;
        this.ccNumb = 12;
        this.othersNumb = 20;
        this.validate = this.validate.bind(this);
        
        this.dropdownResults = this.dropdownResults.bind(this);
        this.inputHelpText = this.inputHelpText.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.turnFieldTouched = this.turnFieldTouched.bind(this);
    }


    componentDidMount() {
        const { selectValue, inputValue, onChangeFunction, data } = this.props

        if (selectValue) {
            this.inputHelpText(selectValue);
        }

        this.setState({
            identificationTypes: this.props.identificationTypes
        });

        
        let element = this.props.identificationTypes.find((el) => el.code === selectValue);

        if (!!element && element.isRequired && element.defaultCode === null) {
            onChangeFunction('', '', '', { name: 'showIssuedBy', value: true, checked: false, files: undefined });

            if (!data.issuerValue || !data.issuer) {
                onChangeFunction('', '', true, { name: 'issuer', value: '', checked: false, files: undefined });
                onChangeFunction('', '', true, { name: 'issuerValue', value: '', checked: false, files: undefined });
            }
        }else if(!element){
            this.setState({
                setSelect: ''
            });
            onChangeFunction('', '', true, { name: 'identificationTypeCode', value: '', checked: false, files: undefined });
        }

        var hasErrorId = !inputValue || !this.validate(inputValue, selectValue);
        this.setState({
            errors: hasErrorId
        });

        !!inputValue && this.turnFieldTouched('identification');

        onChangeFunction('', '', hasErrorId, { name: 'identification', value: inputValue, checked: false, files: undefined });
    }

    turnFieldTouched(field) {
        const { touched } = this.state

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }
    dropdownResults(object) {
        const { setSelect } = this.state;
        const { selectID, required, selectAutoFocus, selectReadonly, selectDisabled, formResources } = this.props;

        const array = Object.values(object);

        const result = array.map((obj, index) => (
            <option key={`option-${index + 1}`} value={obj.code} >{obj.description}</option>
        ));
        const input = <Input
            type="select"
            name={selectID}
            id={selectID}
            aria-describedby={`${selectID}Help`}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={setSelect}
            multiple={false}
            invalid={setSelect === '' ? true : false}
            required={required}
            autoFocus={selectAutoFocus}
            readOnly={selectReadonly}
            disabled={selectDisabled}
        >
            <option key={`option-0`} value="" >{formResources.SelectDefaultOption}</option>
            {result}
        </Input>;

        return input;
    }

    inputHelpText(value) {
        if (value === 101) {
            this.setState(prevState => ({
                ...prevState.inputHelpTextState,
                inputHelpTextState: this.props.formResources.FormHelperIdentificationID
            }));
        } else if (value === 801) {
            this.setState(prevState => ({
                ...prevState.inputHelpTextState,
                inputHelpTextState: this.props.formResources.FormHelperIdentificationCard
            }));
        } else {
            this.setState(prevState => ({
                ...prevState.inputHelpTextState,
                inputHelpTextState: ''
            }));
        }
    }

    handleChange(event, raw) {
        const { onChangeFunction, inputValue } = this.props
        const { name, value } = event.target;

        if (name === 'identification') {
            var hasError = !value || !this.validate(value);
            this.setState({
                errors: hasError
            });

            this.turnFieldTouched(name);

            onChangeFunction(event.target, '', hasError);
        } else {
            hasError = false;
            let element = this.state.identificationTypes.find((el) => el.code === value);
         
            this.inputHelpText(value);
            this.setState({
                setSelect: element ? element.code : ''
            });

            if (element && element.isRequired && element.defaultCode === null) {
                onChangeFunction('', '', '', { name: 'showIssuedBy', value: true, checked: false, files: undefined });
                onChangeFunction('', '', true, { name: 'issuer', value: '', checked: false, files: undefined });
                onChangeFunction('', '', true, { name: 'issuerValue', value: '', checked: false, files: undefined });
            } else {
                onChangeFunction('', '', '', { name: 'showIssuedBy', value: false, checked: false, files: undefined });
                onChangeFunction('', '', false, { name: 'issuer', value: null, checked: false, files: undefined });
                onChangeFunction('', '', false, { name: 'issuerValue', value: null, checked: false, files: undefined });
            }
            
            onChangeFunction(event.target, '', hasError, raw);

            var hasErrorId = !this.validate(inputValue, value) || !inputValue;
            this.setState({
                errors: hasErrorId
            });

            onChangeFunction('', '', hasErrorId, { name: 'identification', value: inputValue, checked: false, files: undefined });
        }
    }

    handleBlur(event) {
        const { name, value } = event.target;

        if (name === 'identification') {
            var hasError = !value || !this.validate(value);
            this.setState({
                errors: hasError
            });

            this.turnFieldTouched(name);

            this.props.onChangeFunction(event.target, '', hasError);
        }
    }

    validate(number, select = false) {
        const { selectValue } = this.props;

        let idCode = !!select ? select : selectValue;

        function escapeRegExp(string) {
            return string.replace(/([.*+?^=!:${}()|\]\\])/g, "\\$1");
        }

        function replaceAll(find, replace, str) {
            return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
        }

        function isNumber(char) {
            var asciiVal = char.charCodeAt(0);
            return (asciiVal >= 48 && asciiVal <= 57);
        }

        function getNumberFromChar(letter) {
            if (isNumber(letter)) {
                return parseInt(letter);
            } else {
                return letter.charCodeAt(0) - 55;
            }
        }

        switch (idCode) {
            case '101':
                const validBi = /^[A-Za-z0-9]{0,8}$/;

                return validBi.test(number);
            case '801':
                const regex = /^[A-Za-z0-9]{0,12}$/;
                var ccVal = replaceAll(" ", "", number).toUpperCase();
                if (!regex.test(ccVal)) {
                    return false;
                }

                var sum = 0;
                var secondDigit = false;
                if (ccVal.length === 12) {
                    for (let i = ccVal.length - 1; i >= 0; i--) {
                        var valor = getNumberFromChar(ccVal.charAt(i));

                        if (secondDigit) {
                            valor *= 2;
                            if (valor > 9) {
                                valor -= 9;
                            }
                        }

                        sum += valor;
                        secondDigit = !secondDigit;
                    }

                    return !!(sum % 10 === 0 ? 1 : 0);
                } else {
                    return false;
                }
            default:
                const others = /^[A-Za-z0-9]{0,20}$/;

                return others.test(number);
        }

    }

    render() {
        const { identificationTypes, inputHelpTextState, errors, touched, setSelect } = this.state;
        const { formResources, selectID, selectLabel, tootipSelectText, selectHelpText, selectValidationText, required, inputID, inputLabel, tootipInputText, inputValidationText, inputValue, inputAutoFocus, inputReadonly, inputDisabled, data, onChangeFunction } = this.props;

        return (
            <>
                <Row>
                    <FormGroup className="col-sm-8 col-lg-6">
                        <Label for={selectID} className="form-label text-uppercase">{selectLabel}{required && "*"}</Label>
                        {tootipSelectText &&
                            <TooltipItem key={`FormTooltip${selectID}`} id={`FormTooltip${selectID}`} text={tootipSelectText} />
                        }
                        {this.dropdownResults(identificationTypes)}
                        {selectHelpText &&
                            <small id={`${inputID}Help`} className="form-text text-right text-primary">{selectHelpText}</small>
                        }
                        <div className={`invalid-feedback ${setSelect === '' || setSelect === null ? 'visible' : ''}`} >{selectValidationText}</div>
                        
                    </FormGroup>
                    <FormGroup className="col-sm-4 col-lg-3">
                        <Label for={inputID} className="form-label text-uppercase">{inputLabel}{required && "*"}</Label>
                        {tootipInputText &&
                            <TooltipItem key={`FormTooltip${inputID}`} id={`FormTooltip${inputID}`} text={tootipInputText} />
                        }
                        <Input
                            type="text"
                            name={inputID}
                            id={inputID}
                            aria-describedby={`${inputID}Help`}
                            invalid={!!errors}
                            defaultValue={inputValue}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            maxLength={20}
                            required={required}
                            autoFocus={inputAutoFocus}
                            readOnly={inputReadonly}
                            disabled={inputDisabled}
                        />
                        {inputHelpTextState &&
                            <small id={`${inputID}Help`} className="form-text text-right text-primary">{inputHelpTextState}</small>
                        }
                        {inputValidationText &&
                            <div className="invalid-feedback">{touched[inputID] ? inputValidationText.invalid : inputValidationText.empty}</div>
                        }
                    </FormGroup>
                </Row>
                {data.showIssuedBy &&
                    <Row>
                        <FormGroup className="col-lg-4">
                            <CountryAutoCompleteField fieldName="issuer" fieldNameCode="issuerValue" labelText={formResources.FormLabelIssuer} validationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorIssuer }} defaultValue={data.issuerValue} defaultCode={data.issuer} onChangeFunction={onChangeFunction} updateOnlyFields={false} required={true} />
                        </FormGroup>
                    </Row>
                }
            </>
        );
    }
}
