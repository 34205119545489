import React, { Component } from 'react';
import { FormGroup, Label, Input, Tooltip} from 'reactstrap';
import { CountingInput } from './CountingInput';

export class SourceFundsField extends Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            //isOpen: false,
            //tooltipOpen: false,
            clientSourceFunds: !this.props.data.clientSourceFunds? "-1" : this.props.data.clientSourceFunds,
            sourceFundsComments: !this.props.data.sourceFundsComments ? "": this.props.data.sourceFundsComments
        }
        //this.handlerOpen = this.handlerOpen.bind(this);
        //this.toggleTooltip = this.toggleTooltip.bind(this);
        this.handlecodeChange = this.handlecodeChange.bind(this);
        this.handlerSourceFundsComments = this.handlerSourceFundsComments.bind(this);

        this.select = this.select.bind(this);
    }

    //componentDidUpdate() {
    //    if (this.state.isOpen === true) {
    //        document.addEventListener('click', this.handlerOpen);
    //    }
    //    else {
    //        document.removeEventListener('click', this.handlerOpen);
    //    }
    //}

    //toggleTooltip() {
    //    this.setState({
    //        tooltipOpen: !this.state.tooltipOpen
    //    });
    //}

    //handlerOpen(e) {
    //    if (this.state.isOpen && this.containerSource.contains(e.target) && e.target !== e.currentTarget) {
    //        return;
    //    } else {
    //        this.setState((prevState) => { return { isOpen: !prevState.isOpen } });
    //    }
    //}


    //handlecodeChange(event, value) {
    //    event.persist();
    //    var sourceFundsTemp;
    //    var resetComments = false;

    //    if (this.state.clientSourceFunds !== null && this.state.clientSourceFunds.includes(value)) {

    //        if (this.props.options.filter((e) => e.code === value && e.requiredComments).length === 1) {
    //            resetComments = true;
    //        }

    //        sourceFundsTemp = this.state.clientSourceFunds.filter((e) => e !== value);
    //    } else {
    //        if (this.state.clientSourceFunds === null) {
    //            sourceFundsTemp = [value];
    //        } else {
    //            sourceFundsTemp = [...this.state.clientSourceFunds, value];
    //        }
    //    }

    //    this.setState({
    //        clientSourceFunds: sourceFundsTemp
    //    });

    //    const clientSourceFunds = { name: 'clientSourceFunds', value: sourceFundsTemp }
    //    this.props.onChange(clientSourceFunds, "", false);
    //    if (resetComments) {
    //        this.setState({sourceFundsComments: ""})
    //        this.props.onChange({ name: 'sourceFundsComments', value: null }, "", false);
    //    }
    //}

    handlecodeChange(event) {
        var sourceFundsTemp = [event.target.value]
        this.setState({
            clientSourceFunds: sourceFundsTemp,
            sourceFundsComments: ""
        })
        const clientSourceFunds = { name: 'clientSourceFunds', value: sourceFundsTemp }
        this.props.onChange(clientSourceFunds, "", false);
        this.props.onChange({ name: 'sourceFundsComments', value: null }, "", false);
    }

    handlerSourceFundsComments(event) {
        this.setState({
            sourceFundsComments: event.target.value
        })
        this.props.onChange(event.target, "", false);
    }

    select(codeValue) {
        return this.state.clientSourceFunds && this.state.clientSourceFunds.includes(codeValue);
    }

    render(){

        const { options } = this.props;
        /*const { isOpen, tooltipOpen } = this.state;*/
        const others = options && this.state.clientSourceFunds ? options.filter((e) => this.state.clientSourceFunds.includes(e.code) && e.requiredComments) : '';
            
        const selectList = !options ? "" : options.map((event, index) =>
            <option key={`option-${index + 1}`} value={event.code}>{event.description}  </option>
        );
 

        return(
            <>

                <FormGroup>
                    <Label className="form-label text-uppercase">
                        {this.props.formResources.SourceFundsLabel}
                    </Label>
                    {/*<p className="form-label m-0"><small>{this.props.formResources.SourceFundsSubLabel}</small></p>*/}

                    {/*<div style={{ position: "relative" }}>*/}
                    {/*    <div id="TooltipSelect-2" className={`form-control ${this.state.clientSourceFunds === null || this.state.clientSourceFunds.length === 0 ? 'is-invalid' : ''}`} onClick={this.handlerOpen}>*/}
                    {/*        {this.state.clientSourceFunds === null || this.state.clientSourceFunds === undefined || options === undefined || this.state.clientSourceFunds.length === 0 ?*/}
                    {/*            <p>{this.props.formResources.SourceFundsText}</p>*/}
                    {/*            :*/}
                    {/*            <>*/}
                    {/*                <p className="selectedOptions">{this.state.clientSourceFunds.map((value, index) => {*/}
                    {/*                    return `${options.filter((e) => e.code === value)[0].description}${(this.state.clientSourceFunds.length > 1) && (index > 0 || index < this.state.clientSourceFunds.length - 1) ? '; ' : ''}`*/}
                    {/*                })}*/}
                    {/*                </p>*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*    <div ref={(el) => { this.containerSource = el; }} className={`${isOpen ? 'open' : ''} options-multiple`}>*/}
                    {/*        {options && options.map((value, index) => {*/}
                    {/*            return (*/}
                    {/*                <div className={this.select(value.code) ? 'options selected' : 'options'} key={`optionsM-${index}`} onClick={(e) => this.handlecodeChange(e, value.code)}>*/}
                    {/*                    <Input type="checkbox" readOnly checked={this.select(value.code) ? true : false}></Input>*/}
                    {/*                    <p>{value.description}</p>*/}
                    {/*                </div>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*    {(this.state.clientSourceFunds === null || this.state.clientSourceFunds.length === 0) &&*/}
                    {/*        <div className='invalid-feedback'>{this.props.formResources.FormErrorFieldEmpty}</div>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    {/*{options !== undefined && this.state.clientSourceFunds !== null && this.state.clientSourceFunds.length > 0 &&*/}
                    {/*   <Tooltip*/}
                    {/*       placement="right"*/}
                    {/*        modifiers={{ offset: { offset: '0, 15px' } }}*/}
                    {/*        isOpen={tooltipOpen}*/}
                    {/*        target={`TooltipSelect-2`}*/}
                    {/*        toggle={this.toggleTooltip}*/}
                    {/*        autohide={false}*/}
                    {/*    >*/}
                    {/*       {this.state.clientSourceFunds.map((value, index) => {*/}
                    {/*           return `${options.filter((e) => e.code === value)[0].description}${(this.state.clientSourceFunds.length > 1) && (index > 0 || index < this.state.clientSourceFunds.length) ? '; ' : ''}`*/}
                    {/*       })}*/}
                    {/*   </Tooltip>*/}
                    {/*}*/}
                    <Input
                        type="select"
                        name="sourceFunds"
                        id="sourceFunds"
                        key={this.state.clientSourceFunds}
                        value={this.state.clientSourceFunds[0]}
                        onChange={this.handlecodeChange}
                        required={true}
                        invalid={this.state.clientSourceFunds === "-1"}
                        disabled={false}
                    >
                        <option key={`option-0`} value="-1" disabled >{this.props.formResources.SourceFundsText}</option>
                        {selectList}
                    </Input>
                    {this.state.sourceFunds === "-1" &&
                        <div className='invalid-feedback'>{this.props.formResources.FormErrorFieldEmpty}</div>
                    }
                </FormGroup>

                {others.length === 1 ?
                    <FormGroup>
                        <Label className="form-label">{this.props.formResources.OtherFundsLabel}</Label>

                        <Input
                            type="text"
                            name="sourceFundsComments"
                            defaultValue={this.state.sourceFundsComments}
                            required={true}
                            onChange={this.handlerSourceFundsComments}
                            invalid={this.state.sourceFundsComments === ""}
                            disabled={false}
                            maxLength={35}
                        />

                        <small className='form-text text-right text-primary'>
                            <CountingInput limit="35" actual={this.state.sourceFundsComments? this.state.sourceFundsComments.length : 0}/>
                        </small>
                        {this.state.sourceFundsComments === "" &&
                          <div className='invalid-feedback'>{this.props.formResources.FormErrorFieldEmpty}</div>
                        }
                    </FormGroup>
                    :
                    ''}
             
            </>
        )
    }
}
