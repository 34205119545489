import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Homepage/Home';
import { Authentication } from './components/Authentication/Authentication';
import { AuthByToken } from './components/Authentication/AuthByToken';
import { AuthByDigitalKey } from './components/Authentication/AuthByDigitalKey';
import TokenForm from './components/Authentication/TokenForm';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { MainForm } from './components/Form/MainForm';
import ErrorPage from './components/Global/ErrorPage';
import SuccessPage from './components/Global/SuccessPage';
import { ResourcesManager } from './components/Global/ResourcesManager';
import GoogleTagManager from './components/Global/GoogleTagManager';


export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            resources: {},
            globalResources: {},
            faqsHP: [],
            formResources: {},
        };
    }

    async componentDidMount() {
        var resourceManager = new ResourcesManager();
        const resources = await resourceManager.getResources();

        this.setState({
            globalResources: resources.globalResources,
            faqsHP: resources.faqs,
            formResources: resources.formResources,
            amaErrorResources: resources.amaErrorResources,
            faqsStep2: resources.faqsStep2
        });
    }

    render() {
        return (
            <Layout globalResources={this.state.globalResources}>
                {/* Google Tag Manager Component */}
                <GoogleTagManager/>

                <Switch>
                    <Route exact path='/' render={(props) => (<Home {...props} globalResources={this.state.globalResources} faqsHP={this.state.faqsHP} />)} />
                    <Route exact path='/Authentication' render={(props) => (<Authentication {...props} globalResources={this.state.globalResources} formResources={this.state.formResources} amaErrorResources={this.state.amaErrorResources} />)} />
                    <Route exact path='/Authentication/Token' render={(props) => (<AuthByToken {...props} globalResources={this.state.globalResources} formResources={this.state.formResources} />)} />
                    <Route exact path='/Authentication/Token/ValidateToken' render={(props) => (<TokenForm {...props} globalResources={this.state.globalResources} formResources={this.state.formResources} />)} />
                    <Route exact path='/Authentication/DigitalKey' render={(props) => (<AuthByDigitalKey {...props} globalResources={this.state.globalResources} formResources={this.state.formResources} />)} />
                    <Route exact path='/SuccessPage' render={(props) => (<SuccessPage {...props} globalResources={this.state.globalResources} />)} />
                    <Route exact path='/ErrorPage' render={(props) => (<ErrorPage {...props} globalResources={this.state.globalResources} />)} />
                    <AuthorizeRoute path='/MainForm' component={MainForm} globalResources={this.state.globalResources} formResources={this.state.formResources} faqsStep2={this.state.faqsStep2} />
                    <Route component={(props) => <ErrorPage {...props} globalResources={this.state.globalResources} />} />
                </Switch>
            </Layout>
        );
    }
}
