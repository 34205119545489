import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class ContinenteCardNumberField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            errors: false,
            maxLength: 13
        }

        this.regex = /^[\d\s]{1,13}$/;
        this.continenteCardMin = 1850351240247; //1 850351 240247
        this.continenteCardMax = 1850352440219; //1 850352 440219
        //hasRange = 1850351240254; //1 850351 240254

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        const { id, inputValue, onChangeFunction, hasRange } = this.props
         
        if (inputValue !== null && inputValue.length > 0 ) {
            var val = this.replaceAll(" ", "", inputValue);
            var hasError = hasRange ? this.checkRange(val) : !this.validate(val);

            if (val === '' || (this.regex.test(val) && val.length <= this.state.maxLength)) {
                this.setState({
                    errors: hasError
                });

                onChangeFunction('', '', hasError, { name: id, value: inputValue, checked: false, files: undefined });

                if (this.regex.test(val) && val.length === this.state.maxLength) {
                    this.setState({
                        errors: hasRange ? this.checkRange(val) : hasError
                    });
                }

                if (val === '') {
                    this.setState({
                        errors: false
                    });

                    onChangeFunction('', '', false, { name: id, value: inputValue, checked: false, files: undefined });
                }
            }
        }
    }

    handleChange(event) {
        const { value } = event.target;
        var val = this.replaceAll(" ", "", value);
        var hasError = !this.validate(val);

        if(value === ""){
            this.props.onChangeFunction({name: "continentCardNumber", value: null}, '', '', '');
            return;
        }

        if (this.props.required) {
            if (val !== '' || (val.length <= this.state.maxLength)) {
                this.props.onChangeFunction(event.target, '', hasError);

                if (val.length === this.state.maxLength) {
                    this.setState({
                        errors: this.props.hasRange ? this.checkRange(val) : hasError
                    });
                } else {
                    this.setState({
                        errors: true
                    });

                    this.props.onChangeFunction(event.target, '', true);
                }
            }
        } else {
            if (val === '' || (this.regex.test(val) && val.length <= this.state.maxLength)) {
                this.props.onChangeFunction(event.target, '', hasError);

                if (this.regex.test(val) && val.length === this.state.maxLength) {
                    this.setState({
                        errors: this.props.hasRange ? this.checkRange(val) : hasError
                    });
                }

                if (val === '') {
                    this.setState({
                        errors: false
                    });
                    this.props.onChangeFunction(event.target, '', false);
                }
            }
        }
    }

    handleBlur(event) {
        const { value } = event.target;
        var val = this.replaceAll(" ", "", value);
        var hasError = !this.validate(val);

        if (this.props.required) {
            this.setState({
                errors: this.props.hasRange ? this.checkRange(val) : hasError
            });
        } else {
            if (val !== '' && val.length <= this.state.maxLength) {
                this.setState({
                    errors: this.props.hasRange ? this.checkRange(val) : hasError
                });
            } else {
                this.setState({
                    errors: false
                });

                this.props.onChangeFunction(event.target, '', false);
            }
        }
    }

    escapeRegExp(string) {
        return string.replace(/([.*+?^=!:${}()|\]\\])/g, "\\$1");
    }

    replaceAll(find, replace, str) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    checkRange(value) {
        if (!(this.continenteCardMin < value && value < this.continenteCardMax)) {
            return true;
        } else {
            var hasError = !this.validate(value);

            return hasError;
        }
    }

    checkGTIN(number) {
        var checkDigit = Number(number[12]);
        var total = (10 - ((Number(number[0])
            + Number(number[2])
            + Number(number[4])
            + Number(number[6])
            + Number(number[8])
            + Number(number[10])
            +
            (Number(number[1])
                + Number(number[3])
                + Number(number[5])
                + Number(number[7])
                + Number(number[9])
                + Number(number[11])) * 3) % 10)) % 10;

        //console.log('checkDigit', checkDigit);
        //console.log('total', total);
        //console.log('checkDigit === total', checkDigit === total);

        return total === checkDigit;
    }

    validate(number) {
        if (this.regex.test(number) && number.length === this.state.maxLength && this.checkGTIN(number)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { maxLength, errors } = this.state;
        const { id, inputHelpText, inputValidationText, inputValue, required, autoFocus, readonly, disabled } = this.props;

        return (
            <>
                <Input
                    type="text"
                    name={id}
                    id={id}
                    aria-describedby={`${id}Help`}
                    invalid={errors}
                    value={!inputValue? "" : inputValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    maxLength={maxLength + 2}
                    required={required}
                    autoFocus={autoFocus}
                    inputMode="numeric"
                    readOnly={readonly}
                    disabled={disabled}
                />
                {inputHelpText &&
                    <small id={`${id}Help`} className="form-text text-right text-primary">{inputHelpText}</small>
                }
                {inputValidationText &&
                    <div className="invalid-feedback">{inputValidationText}</div>
                }
            </>
        );
    }
}
