import React, { Component } from "react";
import { Row, Label, Input } from "reactstrap";

export default class PlaceOfBirthField extends Component {
	constructor(props) {
		super(props);

		this.props = props;
		this.state = {
			districts: [],
			counties: { enable: false, list: [] },
			errors: false,
			touched: {},
			invalidFields: {
				addressErrors: false,
				postalCodeErrors: false,
				placeErrors: false,
			},
			nameFields: {
				placeOfBirthDistrictCode: "placeOfBirthDistrictCode",
				placeOfBirthDistrictValue: "placeOfBirthDistrictValue",
				placeOfBirthCountyCode: "placeOfBirthCountyCode",
				placeOfBirthCountyValue: "placeOfBirthCountyValue",
			},
			setSelectDistrict: this.props.defaultDistrict,
			setSelectCounty: this.props.defaultCounty,
			showValidateAddress: !this.props.secondaryAddress && !this.props.addressIsValid,
		};

		this.fetchData = this.fetchData.bind(this);
		this.onChangeDistricts = this.onChangeDistricts.bind(this);
		this.onChangeCounties = this.onChangeCounties.bind(this);
		this.turnFieldTouched = this.turnFieldTouched.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	async fetchData(method, params = undefined) {
		const response = await fetch(`/form/${method}${params ? `?${params}` : ""}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.status === 200) {
			return await response.json();
		} else {
			return [];
		}
	}

	handleChange(event) {
		const { name, value } = event.target;
		var hasError = value === "" || value === null ? true : false;

		this.turnFieldTouched(name);
		this.props.onChangeFunction(event.target, "", hasError);
	}

	handleBlur(event) {
		const { name } = event.target;
		this.turnFieldTouched(name);
	}

	onChangeDistricts = (e) => {
		const el = { code: e.target.value, description: e.target[e.target.selectedIndex].getAttribute("data-description") };
		const { nameFields } = this.state;

		this.turnFieldTouched(nameFields.districtValue);

		var hasError;

		if (el.code !== "") {
			this.fetchData("GetCounties", `districtCode=${el.code}`).then((list) => {
				this.setState({
					counties: { enable: true, list },
					setSelectDistrict: el.code,
					setSelectCounty: "",
					errors: false,
				});
			});
			hasError = false;
		} else {
			hasError = true;
			this.setState({
				counties: { enable: false, list: [] },
				setSelectDistrict: "",
				setSelectCounty: "",
			});
		}

		this.props.onChangeFunction(e, e.target, hasError, {
			name: nameFields.placeOfBirthDistrictCode,
			value: el.code ? el.code : "",
			checked: false,
			files: undefined,
		});
		this.props.onChangeFunction(e, e.target, hasError, {
			name: nameFields.placeOfBirthDistrictValue,
			value: el.description,
			checked: false,
			files: undefined,
		});
	};

	onChangeCounties = (e) => {
		var hasError = this.state.errors;
		const el = { code: e.target.value, description: e.target[e.target.selectedIndex].getAttribute("data-description") };
		const { nameFields } = this.state;

		this.turnFieldTouched(nameFields.placeOfBirthCountyValue);

		if (el.code !== "") {
			hasError = false;
			this.setState({
				setSelectCounty: el.code,
			});
		} else {
			hasError = true;

			this.setState({
				setSelectCounty: null,
			});
		}

		this.props.onChangeFunction(e, "", hasError, { name: nameFields.placeOfBirthCountyCode, value: el.code, checked: false, files: undefined });
		this.props.onChangeFunction(e, "", hasError, { name: nameFields.placeOfBirthCountyValue, value: el.description, checked: false, files: undefined });
	};

	turnFieldTouched(field) {
		const { touched } = this.state;

		this.setState({
			touched: {
				...touched,
				[field]: true,
			},
		});
	}

	componentDidMount() {
		const { defaultDistrict, defaultCounty, onChangeFunction, districts } = this.props;
		const { nameFields } = this.state;

		this.setState({
			districts,
		});

		if (!!defaultDistrict) {
			this.turnFieldTouched(nameFields.placeOfBirthDistrictValue);

			let element = districts.find((el) => el.code === defaultDistrict);

			onChangeFunction("", "", false, { name: nameFields.placeOfBirthDistrictValue, value: element.description, checked: false, files: undefined });

			this.fetchData("GetCounties", `districtCode=${defaultDistrict}`).then((list) => {
				this.setState({
					counties: { enable: true, list },
				});

				let element = list.find((el) => el.code === defaultCounty);

				if (element === null || element === undefined) {
					this.setState({
						setSelectCounty: "",
					});
					onChangeFunction("", "", true, { name: nameFields.placeOfBirthCountyValue, value: "", checked: false, files: undefined });
					onChangeFunction("", "", true, { name: nameFields.placeOfBirthCountyCode, value: "", checked: false, files: undefined });
				} else {
					this.turnFieldTouched(nameFields.placeOfBirthCountyValue);

					onChangeFunction("", "", false, {
						name: nameFields.placeOfBirthCountyValue,
						value: element.description,
						checked: false,
						files: undefined,
					});
				}
			});
		} else {
			this.setState({
				setSelectDistrict: "",
				setSelectCounty: "",
			});
			var hasError = true;
			onChangeFunction("", "", hasError, { name: nameFields.placeOfBirthDistrictCode, value: "", checked: false, files: undefined });
			onChangeFunction("", "", hasError, { name: nameFields.placeOfBirthDistrictValue, value: "", checked: false, files: undefined });
			onChangeFunction("", "", hasError, { name: nameFields.placeOfBirthCountyValue, value: "", checked: false, files: undefined });
			onChangeFunction("", "", hasError, { name: nameFields.placeOfBirthCountyCode, value: "", checked: false, files: undefined });
		}
	}

	render() {
		const {
			nameFields: { placeOfBirthDistrictValue, placeOfBirthCountyCode },
			setSelectDistrict,
			setSelectCounty,
		} = this.state;
		const { required, disabled, readonly, formResources } = this.props;

		return (
			<>
				<Row>
					<div className="col-sm-6 col-lg-6">
						<Label for={placeOfBirthDistrictValue} className="form-label text-uppercase">
							{formResources.FormLabelDiscrictValue}
							{required && "*"}
						</Label>
						<Input
							type="select"
							name={placeOfBirthDistrictValue}
							id={placeOfBirthDistrictValue}
							invalid={setSelectDistrict === null || setSelectDistrict === ""}
							value={!!setSelectDistrict && setSelectDistrict}
							onChange={this.onChangeDistricts}
							onBlur={this.handleBlur}
							required={required}
							readOnly={readonly}
							disabled={disabled}>
							<option value="">{formResources.SelectDefaultOption}</option>
							{this.state.districts.map((el, i) => (
								<option key={i} value={el.code} data-description={el.description}>
									{el.description}
								</option>
							))}
						</Input>
						<div className="invalid-feedback">
							{this.state.touched[placeOfBirthDistrictValue] ? formResources.FormErrorDiscrictValue : formResources.FormErrorFieldEmpty}
						</div>
					</div>
					<div className="col-sm-6 col-lg-6">
						<Label for={placeOfBirthCountyCode} className="form-label text-uppercase">
							{formResources.FormLabelCountyValue}
							{required && "*"}
						</Label>
						<Input
							type="select"
							name={placeOfBirthCountyCode}
							id={placeOfBirthCountyCode}
							invalid={setSelectCounty === null || setSelectCounty === ""}
							value={!!setSelectCounty && setSelectCounty}
							onChange={this.onChangeCounties}
							onBlur={this.handleBlur}
							required={required}
							readOnly={readonly}
							disabled={!this.state.counties.enable}>
							{this.state.counties.enable && <option value="">{formResources.SelectDefaultOption}</option>}
							{this.state.counties.list.map((el, i) => (
								<option key={i} value={el.code} data-description={el.description}>
									{el.description}
								</option>
							))}
						</Input>
						<div className="invalid-feedback">
							{this.state.touched[placeOfBirthCountyCode] ? formResources.FormErrorCountyValue : formResources.FormErrorFieldEmpty}
						</div>
					</div>
				</Row>
			</>
		);
	}
}
