import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Benefits.scss';

export class Benefits extends Component {
    static displayName = Benefits.name;

    render() {
        const { globalResources } = this.props;

        return (
            <section className="benefits">
                <div className="benefits__header">
                    <Container>
                        <Row className="pt-2 pb-5 py-lg-4 justify-content-center justify-content-lg-start">
                            <Col className="col-12 mb-4 mb-lg-0 benefits__title">
                                <h2 id="vantagens" className="benefits__title--h h1 mb-0 anchor-position">
                                    {globalResources.HPBenefitsTitle1}
                                    <div className="benefits__title--img"><span className="sr-only">{globalResources.HPBenefitsTitle2}</span></div>
                                </h2>
                            </Col>
                            <div className="icon"><span className="btn-arrow-icon"></span></div>
                        </Row>
                    </Container>
                </div>
                <div className="bg-light">
                    <Container>
                        <Row className="py-5">
                            <Col lg="4" className="mb-3 m-lg-0">
                                <div className="h1" dangerouslySetInnerHTML={{ __html: globalResources.HPBenefitsLead }} />
                            </Col>
                            <Col lg="4" className="my-3 m-lg-0 px-lg-5">
                                <h3 className="h4">{globalResources.HPBenefitsSubTitle1}</h3>
                                <div className="mt-3" dangerouslySetInnerHTML={{ __html: globalResources.HPBenefitsDescription1 }} />
                            </Col>
                            <Col lg="4" className="mt-3 m-lg-0 pl-lg-4">
                                <h3 className="h4">{globalResources.HPBenefitsSubTitle2}</h3>
                                <div className="mt-3" dangerouslySetInnerHTML={{ __html: globalResources.HPBenefitsDescription2 }} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        );
    }
}
