import React, { Component } from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import ImgContaPoupanca from '../../../img/bg/bg_conta-poupanca.png';

export class ContaPoupanca extends Component {
    static displayName = ContaPoupanca.name;
    
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onPoupancaTermsChanged = this.onPoupancaTermsChanged.bind(this);
        this.handleTermsLinkModalContaPoupancaClicked = this.handleTermsLinkModalContaPoupancaClicked.bind(this);
    }

    onPoupancaTermsChanged = () => {
        this.props.onPoupancaTermsChanged();
    }

    handleTermsLinkModalContaPoupancaClicked = () => {
        this.props.handleTermsLinkModalContaPoupancaClicked();
    }

  

    render() {
        const { formResources, data } = this.props;

        return (
            <section className="product__conta-poupanca my-5">
                <Row>

                    <Col xs="11" lg="6" xl="6"  className="pr-0">

                        <div className="ml-3 pl-3 my-sm-3">

                            <FormGroup check className="form-check__big">
                                <Label check>
                                    <Input type="checkbox" name="contaPoupanca" defaultValue={!data.contaPoupanca} checked={this.props.data.contaPoupanca} onChange={this.onPoupancaTermsChanged} disabled={!this.props.data.contaPoupanca} />
                                    <h2 className="form-check-text h3">{formResources.FormLabelContaPoupanca}</h2>
                                </Label>
                                <div className="pl-5" dangerouslySetInnerHTML={{ __html: formResources.ContaPoupancaDescription }} />
                                <Label check>
                                    <Input type="checkbox" name="contaPoupanca" defaultValue={!data.contaPoupanca} checked={this.props.data.contaPoupanca} onChange={this.onPoupancaTermsChanged} />
                                    <p className="pl-5 mt-1"><strong>{formResources.FormLabelContaPoupancaKnowledge}</strong></p>
                                </Label>
                            </FormGroup>

                            <div className="mt-4 mb-3">
                                <div className="mb-3 ml-5 mt-3 title-info-docs" dangerouslySetInnerHTML={{ __html: formResources.TitleInfoDocs }} />
                                <div className="mb-2 pl-5" dangerouslySetInnerHTML={{ __html: formResources.ContaPoupancaInfoDocs }} />
                            </div>

                        </div>
                    </Col>
                    <Col lg="6" xl="6" className="text-right pl-0 cropImage">
                        <img className="img" src={ImgContaPoupanca} alt="" />
                    </Col>
                </Row>
            </section>
        );
    }
}
