import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Container, Row, Col, CustomInput, FormGroup } from 'reactstrap';

import { LogoutBar } from '../Global/LogoutBar';
import { MainFormNavInfo } from './MainFormNavInfo';
import { MainFormNavigation } from './MainFormNavigation';
import { AMADigitalSignature } from '../DigitalSignature/AMADigitalSignature';
import DownloadPdf from '../DigitalSignature/DownloadPdf';
import Loading from '../Global/Loading';
import FileViewer from "react-file-viewer";
class MainFormStep5 extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            finalData: {
                numPages: null,
                mobilePhone: this.props.data != null ? this.props.data.mobilePhone : ''
            },
            zoomPDF: false,
            pdfSigned: '',
            step: 'previewPDF',
            loading: false,
            errors: []
        };

        this.handleLoadSuccess = this.handleLoadSuccess.bind(this);
        this.handleZoomToogle = this.handleZoomToogle.bind(this);

        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);

        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this);

        this.cancelDigitalSignature = this.cancelDigitalSignature.bind(this);
        this.pdfSigned = this.pdfSigned.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.props.handleChange('', '', '', { name: 'confirmDataAndProducts', value: null, checked: false, files: undefined });
        
    }

    back() {
        this.props.prevStep('finalData', this.state.finalData);
    }

    continue() {
        this.props.nextStep('finalData', this.state.finalData);
    }

    handleLoadSuccess({ numPages }) {
        this.setState(prevState => ({
            ...prevState,
            finalData: {
                ...prevState.finalData,
                numPages: numPages
            }
        }));
    }

    handleZoomToogle() {
        this.setState({
            ...this.state.zoomPDF,
            zoomPDF: !this.state.zoomPDF
        });
    }

    handlePrevStep() {
        this.setState({
            step: 'previewPDF'
        });
    }

    handleNextStep() {
        this.setState({
            step: 'signPDF'
        });
    }

    cancelDigitalSignature() {
        this.setState({
            step: 'previewPDF',
            error: ''
        });
    }

    pdfSigned(pdf) {
        this.setState({
            step: 'previewSignedPDF',
            pdfSigned: pdf,
            error: ''
        });
    }

    render() {
        const { step, finalData, loading } = this.state;
        const { activeStep, hasPreviousStep, hasNextStep, globalResources, formResources, loadingData, data, handleChange, questiondata } = this.props;

        if (loadingData) {
            return (
                <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <Loading loadingText={globalResources.LoadingPageContentText} />
                </Container>
            );
        }

        switch (step) {
            case 'signPDF':
                return (
                    <AMADigitalSignature
                        globalResources={globalResources}
                        formResources={formResources}
                        activeStep={activeStep}
                        cancelDigitalSignature={this.cancelDigitalSignature}
                        sucess={this.pdfSigned}
                        mobilePhone={finalData.mobilePhone}
                        mobilePhoneDialogCode={finalData.mobilePhoneDialogCode}
                    />
                );
            case 'previewSignedPDF':
                return (
                    <main className="page-content main-form main-form__step5">
                        <Container>
                            <Row className="main-form__header">
                                <Col>
                                    <h1 className="sr-only">{formResources.Step5Title}</h1>

                                    <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                                </Col>
                            </Row>

                            <DownloadPdf
                                data={questiondata}
                                globalResources={globalResources}
                                formResources={formResources}
                                onChange={handleChange}
                                cancel={this.cancelDigitalSignature}
                            />
                        </Container>
                    </main>
                );
            case 'previewPDF':
            default:
                return (
                    <main className="page-content main-form main-form__step5">
                        <LogoutBar globalResources={globalResources} />

                        <Container>
                            <Row className="main-form__header">
                                <Col>
                                    <h1 className="sr-only">{formResources.Step5Title}</h1>

                                    <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="react-pdf__Wrapper mb-5">
                                        {/* <Document
                                            file={`data:application/pdf;base64,${data.linkPdf}`}
                                            className={`${zoomPDF && 'zoom-page'}`}
                                            onClick={() => this.handleZoomToogle()}
                                            onTouchStartCapture={() => this.handleZoomToogle()}
                                            onLoadSuccess={this.handleLoadSuccess}
                                            loading={
                                                <Loading loadingText={globalResources.LoadingPageContentText} />
                                            }
                                        >
                                            {Array.from(
                                                new Array(finalData.numPages),
                                                (el, index) => (
                                                    <Page
                                                        key={`page_${index + 1}`}
                                                        renderMode="svg"
                                                        pageNumber={index + 1}
                                                        loading={
                                                            <Loading loadingText={globalResources.LoadingPageContentText} />
                                                        }
                                                    />
                                                ),
                                            )}
                                        </Document> */}
                                       
                                        <FileViewer fileType={'pdf'} filePath={`data:application/pdf;base64,${data.linkPdf}`} onError={()=> console.log('error')}></FileViewer>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-primary">
                                        <h2 className="h6">{formResources.ConfirmDataAndProductsTitle}</h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup className="col">
                                    <FormGroup tag="fieldset">
                                        <legend>{formResources.FormLabelConfirmDataAndProducts}*</legend>

                                        <FormGroup className="mb-0">
                                            <div className="d-flex">
                                                <CustomInput type="radio" id="confirmDataAndProducts1" name="confirmDataAndProducts" label="Sim" value={true} onChange={handleChange} required />
                                                <CustomInput type="radio" id="confirmDataAndProducts2" name="confirmDataAndProducts" label="Não" value={false} onChange={handleChange} required />

                                            </div>
                                            <div className={`invalid-feedback ${data.confirmDataAndProducts === false ? 'visible' : ''}`} >{`Por favor corrige os teus dados.`}</div>
                                        </FormGroup>
                                    </FormGroup>
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col className="align-self-center">
                                    <MainFormNavigation analyticsId="continue_6" prevStep={this.back} nextStep={this.handleNextStep} hasPreviousStep={hasPreviousStep} hasNextStep={hasNextStep} prevText={globalResources.NavBackText} nextText={globalResources.NavSignText} lastStep={true} loading={loading} loadingText={globalResources.LoadingPageText} buttonDisable={!this.props.isValid} />
                                </Col>
                            </Row>
                        </Container>
                    </main>
                );
        }
    }
}

export default withRouter(MainFormStep5);