import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DigitalSignatureRequest } from './DigitalSignatureRequest';
import { DigitalSignatureConfirmation } from './DigitalSignatureConfirmation';
import { MainFormNavInfo } from '../Form/MainFormNavInfo';

export class AMADigitalSignature extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            processId: '',
            step: 'signPdfRequest',
            error: ''
        };

        this.handleStep = this.handleStep.bind(this);
        this.signPdfRequestError = this.signPdfRequestError.bind(this);
        this.signPdfRequestSuccess = this.signPdfRequestSuccess.bind(this);
        this.signPdfConfirmationSuccess = this.signPdfConfirmationSuccess.bind(this);
    }

    handleStep() {
        this.setState({
            step: 'signPdfRequest'
        });
    }

    signPdfRequestError(errorMessage) {
        this.setState({
            processId: '',
            step: 'signPdfRequest',
            error: errorMessage
        });

        window.scrollTo(0, 0);
    }

    signPdfRequestSuccess(data) {
        this.setState({
            processId: data,
            step: 'signPdfConfirmation',
            error: ''
        });
    }

    signPdfConfirmationSuccess(pdf) {
        this.props.sucess(pdf);
    }

    render() {
        const { step, processId, error } = this.state;
        const { globalResources, formResources, mobilePhone, mobilePhoneDialogCode, activeStep, cancelDigitalSignature } = this.props;

        switch (step) {
            case 'signPdfConfirmation':
                return (
                    <main className="page-content main-form main-form__step5">
                        <Container>
                            <Row className="main-form__header">
                                <Col>
                                    <h1 className="sr-only">{formResources.Step5Title}</h1>

                                    <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                                </Col>
                            </Row>

                            <DigitalSignatureConfirmation
                                formResources={formResources}
                                globalResources={globalResources}
                                processId={processId}
                                cancel={this.handleStep}
                                signPdfConfirmationSuccess={this.signPdfConfirmationSuccess}
                                validateTokenError={this.validateTokenError}
                                errorMessage={error}
                            />
                        </Container>
                    </main>
                );
            case 'signPdfRequest':
            default:
                return (
                    <main className="page-content main-form main-form__step5">
                        <Container>
                            <Row className="main-form__header">
                                <Col>
                                    <h1 className="sr-only">{formResources.Step5Title}</h1>

                                    <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                                </Col>
                            </Row>

                            <DigitalSignatureRequest
                                mobilePhone={mobilePhone}
                                mobilePhoneDialogCode={mobilePhoneDialogCode}
                                globalResources={globalResources}
                                formResources={formResources}
                                cancel={cancelDigitalSignature}
                                signPdfRequestSuccess={this.signPdfRequestSuccess}
                                signPdfRequestError={this.signPdfRequestError}
                                errorMessage={error}
                            />
                        </Container>
                    </main>
                );
        }
    }
}
