import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import TooltipItem from '../../Fields/TooltipItem';
import ImgCardCaixaIsic from '../../../img/content/card-caixa-isic.png';

import LogoBooking from '../../../img/logo/logo_Booking.png';
import LogoGalp from '../../../img/logo/logo_Galp.png';
import LogoLenovo from '../../../img/logo/logo_Lenovo.png';
import LogoPcParaTodos from '../../../img/logo/logo_PC para todos.png'
import LogoKalorias from '../../../img/logo/logo_Kalorias.png';
import LogoPaez from '../../../img/logo/logo_Paez.jpg'

import LogoAmbar from '../../../img/logo/logo_ambar.png';
import LogoPapelariaFernandes from '../../../img/logo/logo_Papelaria Fernandes.jpg';
import LogoHardRockCafe from '../../../img/logo/logo_Hard-Rock-Cafe.png';
import LogoMakeNotes from '../../../img/logo/logo_MakeNotes.jpg';
import LogoiServices from '../../../img/logo/logo_iServices.png';
import LogoMyProtein from '../../../img/logo/logo_Myprotein.jpg';
import LogoTheBodyShop from '../../../img/logo/logo_TheBodyShop.jpg';

export class CardCaixaISIC extends Component {
    static displayName = CardCaixaISIC.name;

    render() {
        const { formResources } = this.props;

        return (
            <section className="product__card-caixa-isic">
                <div className="product__bg pl-5">
                    <Row className="mx-0">
                        <Col xs="11" lg="7">
                            <h2 className="h3 icn-position"><span className="icn-approved"></span><strong>{formResources.CardCaixaISICTitle}</strong>
                                <TooltipItem key="FormTooltipCardCaixaISIC" id="FormTooltipCardCaixaISIC" text={formResources.FormTooltipCardCaixaISIC} />
                                <br />
                                <div className="mt-2">{formResources.InternationalStudentStatus}</div>
                            </h2>
                            <div dangerouslySetInnerHTML={{ __html: formResources.CardCaixaISICDescription }} />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs="11" lg="4">
                            <h2 className="h3 text-uppercase"><strong>{formResources.SaveEurosTitle}</strong></h2>
                            <div className="mt-4" dangerouslySetInnerHTML={{ __html: formResources.SaveEurosDescription }} />
                            <p className="text-center text-lg-left mt-4"><img className="img" src={ImgCardCaixaIsic} alt="" /></p>
                        </Col>
                        <Col xs="11" lg="8" className="align-flex-end ">
                            <p className="mt-4 mb-0">{formResources.CaixaISICTAEG}</p>
                        </Col>
                    </Row>
                </div>
                <Row className="mx-0 mt-4 align-items-center">
                    <Col xs="11" lg="5">
                        <div className="h5 text-secondary pl-5 mb-0" dangerouslySetInnerHTML={{ __html: formResources.CardCaixaISISAdvantagesText }} />
                    </Col>
                    <Col xs="11" lg={{ size: 6, offset: 1 }}>
                        <div className="pl-5 pl-lg-0 pr-md-4">
                            <Row xs="3" sm="4" md="5">
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoBooking} alt={formResources.BookingAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoGalp} alt={formResources.GalpAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoLenovo} alt={formResources.LenovoAltText} />
                                    <img className="img" src={LogoPcParaTodos} alt={formResources.PcParaTodosAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoKalorias} alt={formResources.KaloriasAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoPaez} alt={formResources.PaezAltText} />
                                </Col>
                            </Row>
                            <Row xs="3" sm="4" md="5" className="mt-3">
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoAmbar} alt={formResources.AmberAltText} />
                                    <img className="img" src={LogoPapelariaFernandes} alt={formResources.PapelariaFAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoHardRockCafe} alt={formResources.HardRockCafeAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoMakeNotes} alt={formResources.MakeNotesAltText} />
                                    <img className="img" src={LogoiServices} alt={formResources.IServicesAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoMyProtein} alt={formResources.MyProteinAltText} />
                                </Col>
                                <Col className="text-center align-self-center">
                                    <img className="img" src={LogoTheBodyShop} alt={formResources.TheBodyShopAltText} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                        <div className="pl-5">
                            <div className="mb-3 ml-5 mt-3 title-info-docs" dangerouslySetInnerHTML={{ __html: formResources.TitleInfoDocs }} />
                            <div className="mb-2 pl-5" dangerouslySetInnerHTML={{ __html: formResources.CardCaixaISICInfoDocs }} />
                        </div>

                </Row>
            </section>
        );
    }
}
