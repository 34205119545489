import React, { Component } from 'react';
import { Row, FormGroup, Label, CustomInput} from 'reactstrap';
import icon_whatsapp from '../../img/icn/icon-whatsapp.svg'

export class FifthFieldQuiz extends Component{
    constructor(props){
        super(props);
        this.onchangeValue = this.onchangeValue.bind(this);
    }

    onchangeValue(event){
        let obj = this.props.questionsData;
        let name= event.target.name;
        let value= event.target.value;
        
        let idx =  obj.Questions.findIndex(object => {
            return object.QuestionCode === name;
        });

        if(idx === undefined || idx === -1){
            obj.Questions.push({QuestionCode: name, ResponseCodes:[{Code: value}]});
        }else{
            obj.Questions[idx] = {QuestionCode: name, ResponseCodes:[{Code: value}]}
        }
        
        this.props.onChange({name: "questionData", value: obj}, "", false);
    }

    render(){
        const {data, questionsData, formResources} = this.props;
        let fifthData = ""
        let texts = [];
        let textsWhat = [];
        if(data){
            fifthData = data[5];
            texts = fifthData.text.split('<br />');
            textsWhat = texts[1].split('\r\n\r\n');
        }

        let responseData = questionsData.Questions.find(obj => obj.QuestionCode === fifthData.code)

        return(
            <FormGroup className="col p-0 pt-3">
                <Label className="h6"><strong>{texts[0]}</strong> {fifthData.isRequired? " *": ""}</Label>
                <Row className="align-items-center m-0">
                    <img className="img" src={icon_whatsapp} alt=""/>
                    <Label className="form-label mb-0 ml-1">{textsWhat[0]} <br/> {textsWhat[1]}</Label>
                </Row>
                <Label className="h6 mt-3"><strong>{texts[2]}</strong></Label>
                <p className='form-label'>{texts[3]}</p>
                <p className='form-label'>{texts[4]}</p>
                <Row className="columngap-2 m-0 pt-2">
                    {fifthData && 
                        fifthData.answers.map((obj) => 
                            <div key={`col5-${obj.code}`} className="d-flex flex-column align-items-center">
                                <FormGroup key={`formgroup5-${obj.code}`} className="mb-0">
                                    <CustomInput
                                        type="radio" 
                                        value={obj.code} 
                                        name={fifthData.code} 
                                        key={`CustomInput5-${obj.code}`} 
                                        id={obj.code} 
                                        checked = {!responseData? false : responseData.ResponseCodes[0].Code === obj.code}
                                        onChange={this.onchangeValue}
                                        className="mr-0 checkLabelPad-0">
                                    </CustomInput>
                                </FormGroup>
                                <Label key={`label5-${obj.code}`} htmlFor={obj.code} className="form-label">{(obj.text)}</Label>
                            </div>    
                        )
                    }
                </Row>

                {fifthData && fifthData.isRequired && !responseData &&
                    <>
                        <div className="is-invalid"></div>
                        <div className="invalid-feedback">
                            {formResources.FieldRequired}
                        </div> 
                    </>
                }

            </FormGroup>
        )
    }
}