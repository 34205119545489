import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Container, Row, Col, Form, FormGroup, Label, Alert, Button } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import { SMSTokenField } from '../Fields/SMSTokenField';
import { AuthNavigation } from './AuthNavigation';
import './Authentication.scss';

class TokenForm extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            authenticationData: {
                nif: this.props.authenticationData.nif || '',
                mobilePhoneNumber: this.props.authenticationData.mobilePhoneNumber || '',
                smsCode: this.props.authenticationData.smsCode || ''
            },
            toMainForm: false,
            loading: false,
            errors: [],
            isValid: false
        };

        this.requestFormSubmit = this.requestFormSubmit.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    requestFormSubmit(event) {
        event.preventDefault();

        //console.log('errors', this.state.errors);

        if (!this.hasErrors()) {
            this.setState({ loading: true });
            this.validateToken();
        }
    }

    async validateToken() {
        const { authenticationData } = this.state;
        const data = {
            nif: authenticationData.nif,
            mobilePhoneNumber: authenticationData.mobilePhoneNumber,
            smsCode: authenticationData.smsCode,
        }
        const response = await authService.signIn(data);

        //console.log('response', response);

        if (response.status === 'success') {
            this.props.history.push('/MainForm');
        } else {
            if (response.status === 'fail' && response.message === 'Student_NotFound') {
                this.setState({ loading: false });

                this.props.validateTokenError(this.props.globalResources.ErrorUserNotFound);
            } else if (response.status === 'fail' && response.message === 'Form_AlreadySubmitted') {
                this.setState({ loading: false });

                this.props.validateTokenError(this.props.globalResources.ErrorFormAlreadySubmitted);
            } else if (response.status === 'fail' && response.message === 'Code_NotFound') {
                this.setState({ loading: false });

                this.props.validateTokenError(this.props.globalResources.ErrorSMSCode);
            } else {
                this.setState({ loading: false });

                this.props.validateTokenError(this.props.globalResources.ErrorGateway);
            }
        }
    }

    hasErrors() {
        var errors = Object.values(this.state.errors);
        var hasErrors = errors.length === 0 || errors.reduce(function (acc, value) {
            return acc || value;
        }, false);

        return hasErrors;
    }

    handleChange(event, target, errors, rawData) {
        const { name, value } = !!rawData ? rawData : target !== undefined ? event : event.target;

        this.setState(prevState => ({
            authenticationData: {
                ...prevState.authenticationData,
                [name]: value
            },
            errors: {
                ...prevState.errors,
                [name]: errors
            }
        }), () => this.setState({
            isValid: !this.hasErrors()
        }));
    }

    render() {
        const { authenticationData, isValid, loading } = this.state;
        const { setStep, globalResources, formResources, errorMessage } = this.props;

        const mobilePhoneNumber = authenticationData.mobilePhoneNumber;
        const mobileLastNumbers = mobilePhoneNumber ? mobilePhoneNumber.slice(-2) : '';
        const formMessageTokenSMS = formResources.FormMessageTokenSMS;
        const tokenMessage = mobileLastNumbers !== '' ? formMessageTokenSMS.replace('[NUM]', '[' + mobileLastNumbers + ']') : formMessageTokenSMS;

        return (
            <main className="page-content authentication">
                <Container>
                    <Row>
                        <Col className="col-lg-5">
                            <h1>{formResources.TokenTitle}</h1>

                            <div className="lead" dangerouslySetInnerHTML={{ __html: tokenMessage }} />

                            <Form className="needs-validation" noValidate>
                                <FormGroup tag="fieldset" className="mb-0">
                                    <legend className="sr-only">{formResources.TokenLegend}</legend>

                                    {errorMessage &&
                                        <Alert key={errorMessage} color="danger"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                                    }

                                    <FormGroup>
                                        <Label for="smsCode" className="form-label text-uppercase mr-sm-2">{formResources.FormLabelTokenCode}*</Label>
                                        <SMSTokenField id="smsCode" inputValidationText={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorSMSCode }} inputValue={authenticationData.smsCode} onChangeFunction={this.handleChange} required={true} autoFocus={true} />
                                    </FormGroup>
                                </FormGroup>

                                <AuthNavigation linkText={globalResources.NavBackText} linkClickFunction={setStep} buttonName={globalResources.NavStartText} buttonClickFunction={this.requestFormSubmit} buttonDisable={!isValid} loading={loading} loadingText={globalResources.LoadingPageText} />

                                <p className="mt-3">
                                    <Button type="button" color="link" onClick={setStep}>{globalResources.TokenResendCode}</Button><br />
                                    <a href={globalResources.TokenCodeProblemsLinkURL} target="_blank" rel="external noopener noreferrer">{globalResources.TokenCodeProblemsLinkText}</a>
                                </p>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}

export default withRouter(TokenForm);