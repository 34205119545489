import React,  { Component } from "react";
import { Input } from 'reactstrap';

export class AccountNumberField extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        if(event.target.value === ""){
            this.props.onChangeFunction('', '', '', { name: event.target.name, value: null, checked: false, files: undefined });
        }else{
            this.props.onChangeFunction(event.target, "",false);
        }
    }

    render(){
        const {valid} = this.props;          
        return(
            <>
                <Input 
                    type="text"
                    name="accountNumber"
                    onChange={this.handleChange}
                    invalid={!valid}
                    value={!this.props.data.accountNumber ? "": this.props.data.accountNumber }
                    inputMode="numeric"
                    maxLength={13}
                />
                {!valid && 
                    <div className="invalid-feedback">{this.props.formResources.FormAccountNumberError}</div>
                }
            </>
        )
    }
}