export class FormManager {
    async getForm() {
        const response = await fetch('/Form/GetForm', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data;
        }

        return null;
    }
}