import React, { Component } from "react";

class GoogleTagManager extends Component {
    constructor(props){
        super(props);
        this.state = {
            siteId: null,
            endpoint: null,
            isDataLoaded: false
        };
    }

    componentDidMount() {
        fetch('/Settings/GetAnalitcsData', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        }).then(response => response.json()).then(data => {
            if (data && data.isEnable) {
                this.setState({
                    siteId: data.siteId,
                    endpoint: data.endpoint,
                    isDataLoaded: true,
                });
            }
        }).catch((error) => console.error('Error GTM', error));
    }

    render() {
        const { siteId, endpoint, isDataLoaded } = this.state;        

        if (!isDataLoaded) {
            return null;
        }

        const iframeHTML = `
        <iframe
            src="${endpoint + siteId}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
        ></iframe>`;

        return (
            <noscript
            dangerouslySetInnerHTML={{__html: iframeHTML}}
            />
        );
    }
}

export default GoogleTagManager;