import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import Loading from '../Global/Loading';

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;

        var link = document.createElement("a");
        link.href = this.props.path;

        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.ApiAuthorizationPrefix}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`

        if (!ready) {
            return (
                <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <Loading loadingText={this.props.globalResources.LoadingPageText} />
                </Container>
            );
        } else {
            const { globalResources, formResources, faqsStep2, component: Component, ...rest } = this.props;

            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <Component {...props} globalResources={globalResources} formResources={formResources} faqsStep2={faqsStep2} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
