import React, { Component } from 'react';
import { FormGroup, Label, Row, Col, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import { ContinenteCardNumberField } from '../../Fields/ContinenteCardNumberField';
import AppContinente1 from '../../../img/content/app-continente-1.png';
import AppContinente2 from '../../../img/content/app-continente-2.png';
import AppContinente3 from '../../../img/content/app-continente-3.png';
import AppContinente4 from '../../../img/content/app-continente-4.png';
import ContinenteOffers from '../../../img/content/continente-offers.png';
import AppStore from '../../../img/icn/icn_app-store.png';
import GooglePlay from '../../../img/icn/icn_google-play.png';

export class CardContinente extends Component {
    static displayName = CardContinente.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            activeIndex: 0,
            animating: false,
            loading: false
        };

        this.sliderItems = [
            {
                src: AppContinente1,
                altText: '',
                caption: this.props.formResources.AdvantagesContinenteCardDescription1
            },
            {
                src: AppContinente2,
                altText: '',
                caption: this.props.formResources.AdvantagesContinenteCardDescription2
            },
            {
                src: AppContinente3,
                altText: '',
                caption: this.props.formResources.AdvantagesContinenteCardDescription3
            },
            {
                src: AppContinente4,
                altText: '',
                caption: this.props.formResources.AdvantagesContinenteCardDescription4
            }
        ];

        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    prevSlide() {
        if (this.state.animating) {
            return;
        }

        const nextIndex = this.state.activeIndex === 0 ? this.sliderItems.length - 1 : this.state.activeIndex - 1;
        this.setState({
            activeIndex: nextIndex
        });
    }

    nextSlide() {
        if (this.state.animating) {
            return;
        }

        const nextIndex = this.state.activeIndex === this.sliderItems.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({
            activeIndex: nextIndex
        });
    }

    goToIndex(newIndex) {
        if (this.state.animating) {
            return;
        }

        this.setState({
            activeIndex: newIndex
        });
    }

    render() {
        const { activeIndex } = this.state;
        const { onChangeFunction, globalResources, formResources, data } = this.props;

        const sliderImage = this.sliderItems.map((item) => {
            return (
                <CarouselItem
                    key={item.src}
                    className="text-center"
                    onExiting={() => this.setState({ animating: true })}
                    onExited={() => this.setState({ animating: false })}
                >
                    <img src={item.src} alt="" onClick={() => this.nextSlide()} />
                </CarouselItem>
            );
        });

        const sliderCaption = this.sliderItems.map((item) => {
            return (
                <CarouselItem
                    key={item.src}
                    className="mb-4"
                >
                    <div key={item.src} dangerouslySetInnerHTML={{ __html: item.caption }} />
                </CarouselItem>
            );
        });

        return (
            <section className="product__card-continente mb-1">
                <Row className="mx-0">
                    <Col sm="11" lg="5">
                        <div className="pl-5 mt-4">
                            <h2 className="h3 text-dark icn-position"><span className="icn-approved"></span><strong>{formResources.ContinentePartnershipTitle}</strong></h2>
                            <FormGroup tag="fieldset">
                                <legend className="h3 text-dark mb-lg-4">{formResources.ContinenteCardLegend}</legend>

                                <Row className="mt-3 mt-lg-4">
                                    <Col>
                                        <FormGroup>
                                            <Label for="continentCardNumber" className="form-label text-uppercase"><span dangerouslySetInnerHTML={{ __html: formResources.FormLabelContineteCardNumber }} /></Label>
                                            <ContinenteCardNumberField id="continentCardNumber" inputValidationText={formResources.FormErrorContinenteCardNumber} inputHelpText={formResources.FormHelperContinenteCardNumber} inputValue={data.continentCardNumber} onChangeFunction={onChangeFunction} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>
                    </Col>
                    <Col sm="11" lg="3">
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.nextSlide}
                            previous={this.prevSlide}
                            className="app mb-5"
                            aria-hidden="true"
                        >
                            <CarouselIndicators items={this.sliderItems} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                            {sliderImage}
                        </Carousel>
                    </Col>
                    <Col sm="11" lg="4">
                        <div className="pl-5 pl-lg-0 pr-lg-5 mt-4">
                            <h3 className="sr-only">{formResources.AdvantagesContinenteCardTitle}</h3>
                            <Carousel
                                activeIndex={activeIndex}
                                next={this.nextSlide}
                                previous={this.prevSlide}
                                className="caption"
                                slide={false}
                            >
                                {sliderCaption}
                            </Carousel>

                            <h3 className="h6 text-dark mb-3"><strong>{formResources.DownloadAppsText}</strong></h3>
                            <Row className="icn-apps">
                                <Col xs="6" sm="auto" lg="6" xl="auto" className="pr-1">
                                    <a href="https://apps.apple.com/pt/app/cart%C3%A3o-continente/id600514064" target="_blank" rel="external noopener noreferrer"><img className="img" src={AppStore} alt={globalResources.AppStoreAltText} /></a>
                                </Col>
                                <Col xs="6" sm="auto" lg="6" xl="auto" className="pl-1">
                                    <a href="https://play.google.com/store/apps/details?id=pt.continente.ContinenteTelemovel&hl=pt_PT" target="_blank" rel="external noopener noreferrer"><img className="img" src={GooglePlay} alt={globalResources.GooglePlayAltText} /></a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="mx-0 mt-4">
                    <Col sm={{ size: 10, offset: 1 }}>
                        <h3 className="h6 text-dark text-center"><strong>{formResources.ContinenteCardAdvantagesText}</strong></h3>
                    </Col>
                </Row>

                <Row className="mx-0 mt-4">
                    <div className="native-slider">
                        <Col className="slide text-center">
                            <div>
                                <img className="img" width={1000} height={301} src={ContinenteOffers} alt={formResources.ContinenteOffersAltText} />
                            </div>
                        </Col>
                    </div>
                </Row>

                <div className="pl-5 mt-5">
                    <div className="mb-3 ml-5 mt-3 title-info-docs" dangerouslySetInnerHTML={{ __html: formResources.TitleInfoDocs }} />
                    <div className="mb-2 pl-5" dangerouslySetInnerHTML={{ __html: formResources.ContinenteCardInfoDocs }} />
                </div>

            </section>
        );
    }
}
