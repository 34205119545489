import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Loading from '../Global/Loading';

export class SubmitButton extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { name, disabled, onClickFunction, loading, loadingText, paddings } = this.props;
        const attrClass = paddings === '' || paddings === undefined ? 'text-uppercase px-4 py-2' : 'text-uppercase ' + paddings;

        return (
            loading
                ? <Loading loadingText={loadingText} />
                : <Button
                    type="submit"
                    color="primary"
                    className={attrClass}
                    onClick={onClickFunction}
                    disabled={disabled}
                >
                    {name ? name : ''}
                </Button>
        );
    }
}
