import React, { Component } from 'react';
import { Row, FormGroup, CustomInput, Label, Col } from 'reactstrap';

export class ClientQuestionField extends Component {

    constructor(props){
        super(props);

        this.state={
            errors: false,
            reponse: [
                {
                    code: 'S',
                    description: 'SIM',
                    value: true
                },
                {
                    code: 'N',
                    description: 'NÃO',
                    value: false
                },
            ],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({
          checked: e.target.value,
          errors: !e.target.value,
        });
        let hasError = !e.target.value;

        const response = e.target.value === 'true'? true: false;
        this.props.onChangeFunction({name: e.target.name, value:response }, '', false, hasError);

        //reset values 
        this.props.onChangeFunction({name: "accountNumber", value: null}, '', false, hasError);
        this.props.onChangeFunction({name: "cardNumber", value: null}, '', false, hasError);
        this.props.onChangeFunction({name: "clientNumber", value: null}, '', false, hasError);
        this.props.onChangeFunction({name: "contaPoupanca", value: false}, '', false, hasError);
        this.props.onChangeFunction({name: "contaCaixaM", value: false}, '', false, hasError);
        this.props.onChangeFunction({name: "continentCardNumber", value: null}, '', false, hasError);
        this.props.onChangeFunction({name: "ValidUseExistingAccount", value: false}, '', false, hasError);
        this.props.resetCardStudent();
    }

    render(){
        return(
            <>
                <Row>
                    <Col>
                        <FormGroup tag="fieldset" className="col">
                            <Label className="h5">
                                {this.props.formResources.QuestionIsClientText}
                            </Label>
                            <div className='d-flex'>
                                {this.state.reponse.map((item) => (
                                    <CustomInput
                                        className="py-1 radioIsclient"
                                        type="radio"
                                        name="useExistingAccount"
                                        id={item.code}
                                        key={item.code}
                                        value={item.value}
                                        label={item.description}
                                        onChange={this.handleChange}
                                        data-description={item.description}
                                        checked={this.props.data.useExistingAccount === item.value}
                                        invalid={this.state.errors === null}
                                        required={true}
                                    />
                                ))}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        )
    }


}
