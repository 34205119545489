import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LogoCGD from '../../img/logo_cgd.svg';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        const { globalResources } = this.props;

        return (
            <footer className="page-footer sticky-footer bg-primary text-light">
                <Container>
                    <Row className="my-4 align-items-center">
                        <Col xs="12" md="auto">
                            <div className="d-block d-sm-inline-block" dangerouslySetInnerHTML={{ __html: globalResources.FooterContacts }} />
                            <div className="d-inline-block" dangerouslySetInnerHTML={{ __html: globalResources.FooterContactsNumber }} />
                        </Col>
                        <Col xs="9" md="auto pl-lg-5" dangerouslySetInnerHTML={{ __html: globalResources.FooterCopyRight }} />
                        <Col xs="3" className="text-right col-md">
                            <img src={LogoCGD} width="44" height="36" alt={globalResources.LogoCGDAltText} />
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}
