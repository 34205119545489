export class User {
    constructor({ access_token, profile, expires_at, errorCode }) {
        this.access_token = access_token;
        this.profile = profile;
        this.expires_at = expires_at;
        this.errorCode = errorCode;
    }

    get expires_in() {
        if (this.expires_at) {
            let now = parseInt(Date.now() / 1000);
            return this.expires_at - now;
        }
        return undefined;
    }
    set expires_in(value) {
        let expires_in = parseInt(value);
        if (typeof expires_in === 'number' && expires_in > 0) {
            let now = parseInt(Date.now() / 1000);
            this.expires_at = now + expires_in;
        }
    }

    get expired() {
        let expires_in = this.expires_in;
        if (expires_in !== undefined) {
            return expires_in <= 0;
        }
        return undefined;
    }

    toStorageString() {
        return JSON.stringify({
            access_token: this.access_token,
            profile: this.profile,
            expires_at: this.expires_at
        });
    }

    static fromStorageString(storageString) {
        return new User(JSON.parse(storageString));
    }
}
