import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

export class FooterDocuments extends Component {
    static displayName = FooterDocuments.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.downloadLinkDoc = false;
    }

    render() {
        const { globalResources, noGlossary } = this.props;

        return (
            <div className="page-footer page-footer--second-level sticky-footer bg-color-gray-300">
                <Container>
                    <Row className="my-2 align-items-center">
                        <Col>
                            <Nav>
                                <NavItem>
                                    <NavLink href="/File/downloadFile?filename=PP" target="_blank" rel="external noopener noreferrer">{globalResources.FooterLinkFAQs}</NavLink>
                                </NavItem>
                                {!noGlossary &&
                                    <NavItem>
                                    <NavLink href="/File/downloadFile?filename=G" target="_blank" rel="external noopener noreferrer">{globalResources.FooterLinkGlossario}</NavLink>
                                    </NavItem>
                                }
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
