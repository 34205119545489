import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MainFormNavInfo } from './MainFormNavInfo';
import { MainFormNavigation } from './MainFormNavigation';
import Loading from '../Global/Loading';

export class MainFormStep1 extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this);
    }

    back() {
        this.props.prevStep('summary', this.props.data);
    }

    continue() {
        this.props.nextStep('summary', this.props.data);
    }

    render() {
        const { activeStep, hasPreviousStep, hasNextStep, globalResources, formResources, data, loadingData } = this.props;

        const step1Summary = formResources.Step1Summary;
        const dataSummary = data;
        var schoolReplace, summaryDataReplace = '';

        if (Object.keys(dataSummary).length > 0 && dataSummary.constructor === Object) {
            const nameReplace = dataSummary.name ? step1Summary.replace('[NAME]', dataSummary.name) : '';
            schoolReplace = dataSummary.school ? nameReplace.replace('[SCHOOL]', dataSummary.school) : '';
            summaryDataReplace = dataSummary.memberNumber ? schoolReplace.replace('[MEMBERNUMBER]', dataSummary.memberNumber) : '';
        }

        if (loadingData) {
            return (
                <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <Loading loadingText={globalResources.LoadingPageContentText} />
                </Container>
            );
        }

        return (
            <Container>
                <Row className="main-form__header">
                    <Col>
                        <h1 className="sr-only">{formResources.Step1Title}</h1>

                        <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="8" xl="6">
                        <div className="summary" dangerouslySetInnerHTML={{ __html: summaryDataReplace }} />

                        <div className="description" dangerouslySetInnerHTML={{ __html: formResources.Step1Description }} />

                        <MainFormNavigation analyticsId="continue_2" prevStep={this.back} nextStep={this.continue} hasPreviousStep={hasPreviousStep} hasNextStep={hasNextStep} prevText={globalResources.NavBackText} nextText={globalResources.NavForwardText} loadingText={globalResources.LoadingPageText}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}