import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MainFormNavigation } from './MainFormNavigation';

export class LegalDocumentation extends Component {
    static displayName = LegalDocumentation.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    back() {
        this.props.onGoToStep(this.props.data);
    }

    render() {
        const { hasPreviousStep, globalResources, formResources } = this.props;

        return (
            <Container>
                <Row>
                    <Col lg="8">
                        <h1>{formResources.LegalDocumentationTitle}</h1>

                        <dl className="mt-4 mb-5">
                            <dt className="h6"><strong>Abertura de Conta e Prestação de Serviços</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=CGP" target="_blank" rel="external noopener noreferrer">Condições Gerais</a>
                            </dl>

                            <dt className="h6"><strong>Conta Caixa</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=CGCC" target="_blank" rel="external noopener noreferrer">Condições Gerais de Adesão</a>
                            </dl>

                            <dt className="h6"><strong>Conta Extrato</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=FCE" target="_blank" rel="external noopener noreferrer">Ficha de Informação Normalizada</a><br />
                                <a href="/File/downloadFile?filename=DCE" target="_blank" rel="external noopener noreferrer">Documento de Informação sobre Comissões</a>
                            </dl>

                            <dt className="h6"><strong>Conta Jovem Extrato</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=FCJE" target="_blank" rel="external noopener noreferrer">Ficha de Informação Normalizada</a><br />
                                <a href="/File/downloadFile?filename=DCJE" target="_blank" rel="external noopener noreferrer">Documento de Informação sobre Comissões</a>
                            </dl>

                            <dt className="h6"><strong>Conta CaixaPoupança</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=CP" target="_blank" rel="external noopener noreferrer">Ficha de Informação Normalizada</a>
                            </dl>

                            <dt className="h6"><strong>Caixadirecta</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=RSCD" target="_blank" rel="external noopener noreferrer">Recomendações de Segurança na utilização do Caixadirecta através da Internet</a>
                            </dl>

                            <dt className="h6"><strong>Cartão ISIC</strong></dt>
                            <dl className="text">
                                <a href="/File/downloadFile?filename=FCCISICO" target="_blank" rel="external noopener noreferrer">Ficha de Informação Normalizada</a><br />
                                <a href="/File/downloadFile?filename=RSPI" target="_blank" rel="external noopener noreferrer">Recomendações de Segurança Cartões</a><br />
                                <a href="/File/downloadFile?filename=SCISIC" target="_blank" rel="external noopener noreferrer">Seguro ISIC</a>
                            </dl>
                        </dl>

                        <MainFormNavigation prevStep={this.back} hasPreviousStep={hasPreviousStep} prevText={globalResources.NavBackText} />
                    </Col>
                </Row>
            </Container>
        );
    }
}
