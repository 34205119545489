import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { AuthNavigation } from './AuthNavigation';
import './Authentication.scss';

export class AuthByDigitalKey extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            loading: false,
            errors: [],
            isValid: false
        };
    }

    render() {
        const { isValid, loading } = this.state;
        const { globalResources, formResources } = this.props;

        return (
            <main className="page-content authentication">
                <Container>
                    <Row>
                        <Col className="col-lg-5">
                            <h1>{formResources.AuthenticationByDigitalKeyTitle}</h1>

                            <Form>
                                <FormGroup tag="fieldset" className="mb-0">
                                    <legend className="text-primary"><strong></strong></legend>


                                </FormGroup>

                                <AuthNavigation linkURL={"/Authentication"} linkText={globalResources.NavBackText} buttonName={globalResources.NavForwardText} buttonDisable={!isValid} loading={loading} loadingText={globalResources.LoadingPageText} />
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}
