import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import AllPagesPDFViewer from "./pdf/all-pages";
import './Modal.scss';

export class ModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.props.isChecked,
            isHidden: true
        };

        this.onHide = this.onHide.bind(this);
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ isChecked: props.isChecked })
    }

    onHide = () => {
        this.props.onHide({ isChecked: this.state.isChecked });
        this.setState({
            isChecked: false,
            isHidden: true
        });
    }

    onFileLoaded = () => {
        this.setState({
            isHidden: false,
        });
    }

    render() {
        var terms = "";

        if (this.props.show)
        {
            if (this.props.modalInputCaller === "contaCaixaM" || this.props.modalInputCaller === "contaCaixaMUseAccount") {
                terms = "/file/getfile?filename=CC";
            }

            if (this.props.modalInputCaller === "contaPoupanca") {
                terms = "/file/getfile?filename=CPM";
            }
        }

        return (
            <div>
                <Modal
                    scrollable={true}
                    dialogClassName="modal-pdf"
                    show={this.props.show}
                    onHide={this.onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <p className="pl-1 mb-0">{this.props.title}</p>
                    </Modal.Header>

                    <Modal.Body className="mr-3 modal-scrollbar">
                        <AllPagesPDFViewer pdf={terms} onDocumentLoadSuccess={this.onFileLoaded} />
                        <FormGroup check className="form-check__big mt-2" hidden={this.state.isHidden}>
                            <Label check className="pl-1 cursor-pointer">
                                <Input type="checkbox" id="modalCheckboxContaCaixaM" name="modalCheckboxContaCaixaM" defaultValue={this.props.isChecked} checked={this.state.isChecked} onChange={this.toggleChange} />
                                <div className="form-check-text mt-1 mb-0 ml-4 text-indigo">{this.props.label}</div>
                            </Label>
                        </FormGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        
                    </Modal.Footer>

                </Modal>
            </div>
        )
    };
}