import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string'
import './ErrorPage.scss';

class ErrorPage extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { globalResources } = this.props;
        const values = queryString.parse(this.props.location.search);
        let errorDescription = globalResources.Erro_Interno;

        if (values !== null && values !== undefined && values.errorCode !== null) {
            switch (values.errorCode) {
                case 'InvalidSignature':
                case 'InvalidNIF':
                case 'StudentNotFoundException':
                    errorDescription = globalResources.ErrorUserNotFound;
                    break;
                default:
                    errorDescription = globalResources.Erro_Interno;
                    break;
            }
        }

        return (
            <main className="page-content error-pages">
                <Container className="d-flex flex-column h-100 bg-img" role="alert">
                    <Row className="h-100">
                        <Col lg="6">
                            <h1>{globalResources.ErrorPageTitle}</h1>
                            <div dangerouslySetInnerHTML={{ __html: errorDescription }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="align-self-center">
                            <Link to="/">
                                <span aria-hidden="true">&lt;&nbsp;</span>
                                {globalResources.NavBackText}
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
};

export default ErrorPage;