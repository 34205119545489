import React, { Component } from 'react';
import { Row, FormGroup, Label, Input } from 'reactstrap';
import TooltipItem from '../Fields/TooltipItem';
import { CountingInput } from './CountingInput';

export class AddressField extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            districts: [],
            counties: { enable: false, list: [] },
            parishs: { enable: false, list: [] },
            step: 1,
            errors: false,
            maxLength: {
                address: 40,
                place: 30
            },
            touched: {},
            invalidFields: {
                addressErrors: false,
                postalCodeErrors: false,
                placeErrors: false
            },
            nameFields: {
                addressName: (this.props.secondaryAddress ? 'fiscalAddress' : 'address'),
                postalCode: (this.props.secondaryAddress ? 'fiscalPostalCodeCode' : 'postalCodeCode'),
                postalCodeValue: (this.props.secondaryAddress ? 'fiscalPostalCodeValue' : 'postalCodeValue'),
                districtCode: (this.props.secondaryAddress ? 'fiscalDistrictCode' : 'districtCode'),
                districtValue: (this.props.secondaryAddress ? 'fiscalDistrictValue' : 'districtValue'),
                countyCode: (this.props.secondaryAddress ? 'fiscalCountyCode' : 'countyCode'),
                countyValue: (this.props.secondaryAddress ? 'fiscalCountyValue' : 'countyValue'),
                parishCode: (this.props.secondaryAddress ? 'fiscalParishCode' : 'parishCode'),
                parishValue: (this.props.secondaryAddress ? 'fiscalParishValue' : 'parishValue'),
                place: (this.props.secondaryAddress ? 'fiscalPlace' : 'place'),
            },
            setSelectDistrict: this.props.defaultDistrict,
            setSelectCounty: this.props.defaultCounty,
            setSelectParish: this.props.defaultParish,
            showValidateAddress: !this.props.secondaryAddress && !this.props.addressIsValid
        }

        this.postalCodeRegex = /^\d{4}-\d{3}?$/;

        this.fetchData = this.fetchData.bind(this);

        this.onChangeDistricts = this.onChangeDistricts.bind(this);
        this.onChangeCounties = this.onChangeCounties.bind(this);
        this.onChangeParishs = this.onChangeParishs.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.turnFieldTouched = this.turnFieldTouched.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    async fetchData(method, params = undefined) {
        const response = await fetch(`/form/${method}${params ? (`?${params}`) : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            return await response.json();
        } else {
            return [];
        }
    }

    handleChange(event, el) {
        const { invalidFields, showValidateAddress } = this.state
        const { name, value, checked } = event.target;


        var hasError = (value === '' || value === null) ? true : false;

        this.turnFieldTouched(name);

        if (name === 'address' || name === 'fiscalAddress') {
            this.setState({
                errors: hasError,
                invalidFields: {
                    ...invalidFields,
                    addressErrors: hasError
                }
            });

            showValidateAddress && this.props.onChangeFunction('', '', '', { name: 'addressIsvalid', value: true, checked: false, files: undefined });
        }

        if (name === 'place' || name === 'fiscalPlace') {
            this.setState({
                errors: hasError,
                invalidFields: {
                    ...invalidFields,
                    placeErrors: hasError
                }
            });
        }

        if (name === 'addressIsvalid') {
            hasError = !checked;

        }

        this.props.onChangeFunction(event.target, '', hasError);
    }

    handleBlur(event) {
        const { name } = event.target;

        this.turnFieldTouched(name);
    }

    onChangeDistricts = (e) => {
        const el = { code: e.target.value, description: e.target[e.target.selectedIndex].getAttribute('data-description') }
        const { nameFields} = this.state;

        this.turnFieldTouched(nameFields.districtValue);

        var hasError;

        if (el.code !== "") {
            this.fetchData('GetCounties', `districtCode=${el.code}`)
                .then(list => {
                    // const { districts } = data
                    this.setState({
                        counties: { enable: true, list },
                        parishs: { enable: false, list: [] },
                        setSelectDistrict: el.code,
                        setSelectCounty: null,
                        setSelectParish: null,
                        errors: false

                    });
                });
            
            hasError = false;
        } else {
            hasError = true;
            this.setState({
                counties: { enable: false, list: [] },
                parishs: { enable: false, list: [] },
                setSelectDistrict: null,
                setSelectCounty: null,
                setSelectParish: null
            });
        }
        let hasErrorOthers = true;

        this.props.onChangeFunction(e, e.target, hasError, { name: nameFields.districtCode, value: el.code ? el.code : null, checked: false, files: undefined });
        this.props.onChangeFunction(e, e.target, hasError, { name: nameFields.districtValue, value: el.description, checked: false, files: undefined });
        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.countyCode, value: null, checked: false, files: undefined });
        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.countyValue, value: null, checked: false, files: undefined });
        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.parishCode, value: null, checked: false, files: undefined });
        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.parishValue, value: null, checked: false, files: undefined });
    }

    onChangeCounties = (e) => {
        var hasError = this.state.errors;
        const el = { code: e.target.value, description: e.target[e.target.selectedIndex].getAttribute('data-description') }
        const { nameFields } = this.state;

        this.turnFieldTouched(nameFields.countyValue);

        if (el.code !== "") {
            hasError = false;

            this.fetchData('GetParishs', `countyCode=${el.code}`)
                .then(list => {
                    // const { districts } = data
                    this.setState({
                        parishs: { enable: true, list },
                        setSelectCounty: el.code,
                    });
                });
        } else {
            hasError = true;

            this.setState({
                parishs: { enable: false, list: [] },
                setSelectCounty: null,
                setSelectParish: null
            });
        }

        let hasErrorOthers = true;

        this.props.onChangeFunction(e, '', hasError, { name: nameFields.countyCode, value: el.code, checked: false, files: undefined });
        this.props.onChangeFunction(e, '', hasError, { name: nameFields.countyValue, value: el.description, checked: false, files: undefined });

        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.parishCode, value: null, checked: false, files: undefined });
        this.props.onChangeFunction('', '', hasErrorOthers, { name: nameFields.parishValue, value: null, checked: false, files: undefined });
    }

    onChangeParishs = (e) => {
        var hasError = this.state.errors;
        const el = { code: e.target.value, description: e.target[e.target.selectedIndex].getAttribute('data-description') }
        const { nameFields } = this.state;

        this.turnFieldTouched(nameFields.parishValue);

        if (el.code !== "") {
            hasError = false;

            this.setState({
                setSelectParish: el.code,

            });
        } else {
            hasError = true;

            this.setState({
                setSelectParish: null
            });
        }

        this.props.onChangeFunction(e, '', hasError, { name: nameFields.parishCode, value: el.code, checked: false, files: undefined });
        this.props.onChangeFunction(e, '', hasError, { name: nameFields.parishValue, value: el.description, checked: false, files: undefined });
    }

    onChangePostalCode = (e) => {
        const { invalidFields, nameFields } = this.state;
        const el = e.target.value;

        invalidFields.postalCodeErrors = !this.postalCodeRegex.test(e.target.value);
        this.turnFieldTouched(nameFields.postalCode);

        if (!invalidFields.postalCodeErrors) {
            this.fetchData('ValidatePostalCode', `code=${e.target.value}`).then(result => {
                this.setState({
                    errors: !result,
                    invalidFields: {
                        ...invalidFields,
                        postalCodeErrors: !result
                    }
                });

                this.props.onChangeFunction('', '', !result, { name: nameFields.postalCode, value: result ? el : null, checked: false, files: undefined });
            }
            )
        } else {
            this.setState({
                errors: this.postalCodeRegex
                    .test(e.target.value)
            });
            this.props.onChangeFunction('', '', !this.postalCodeRegex.test(e.target.value), { name: nameFields.postalCode, value: el, checked: false, files: undefined });
        }
    }

    turnFieldTouched(field) {
        const { touched } = this.state;

        this.setState({
            touched: {
                ...touched,
                [field]: true
            }
        });
    }
    
    componentDidMount() {
        const { defaultDistrict, defaultCounty, defaultParish, defaultPostalCode, defaultAddress, defaultPlace, onChangeFunction, districts } = this.props;
        const { nameFields, invalidFields } = this.state;

        this.setState({
            districts
        });

        if (!!defaultDistrict) {
            this.turnFieldTouched(nameFields.districtValue);

            let element = districts.find((el) => el.code === defaultDistrict);

            onChangeFunction('', '', false, { name: nameFields.districtValue, value: element.description, checked: false, files: undefined });

            this.fetchData('GetCounties', `districtCode=${defaultDistrict}`)
                .then(list => {
                    this.setState({
                        counties: { enable: true, list }
                    });

                    let element = list.find((el) => el.code === defaultCounty);

                    if (element === null || element === undefined) {
                        this.setState({
                            setSelectCounty: null,
                            setSelectParish: null
                        });
                        onChangeFunction('', '', true, { name: nameFields.countyValue, value: null, checked: false, files: undefined });
                        onChangeFunction('', '', true, { name: nameFields.countyCode, value: null, checked: false, files: undefined });
                        onChangeFunction('', '', true, { name: nameFields.parishValue, value: null, checked: false, files: undefined });
                        onChangeFunction('', '', true, { name: nameFields.parishCode, value: null, checked: false, files: undefined });

                    } else {
                        this.turnFieldTouched(nameFields.countyValue);

                        onChangeFunction('', '', false, { name: nameFields.countyValue, value: element.description, checked: false, files: undefined });
                        this.fetchData('GetParishs', `countyCode=${defaultCounty}`)
                            .then(list => {
                                // const { districts } = data
                                this.setState({
                                    parishs: { enable: true, list }
                                });

                                let element = list.find((el) => el.code === defaultParish);

                                if (element === null || element === undefined) {
                                    this.setState({
                                        setSelectParish: null
                                    });
                                    onChangeFunction('', '', true, { name: nameFields.parishValue, value: null, checked: false, files: undefined });
                                    onChangeFunction('', '', true, { name: nameFields.parishCode, value: null, checked: false, files: undefined });
                                } else {

                                    this.turnFieldTouched(nameFields.parishValue);

                                    onChangeFunction('', '', false, { name: nameFields.parishValue, value: element.description, checked: false, files: undefined });
                                }
                            });
                    }

                });
        } else {
            this.setState({
                setSelectDistrict: null,
                setSelectCounty: null,
                setSelectParish: null
            });
            var hasError = true;
            onChangeFunction('', '', hasError, { name: nameFields.districtCode, value: null, checked: false, files: undefined });
            onChangeFunction('', '', hasError, { name: nameFields.districtValue, value: null, checked: false, files: undefined });
            onChangeFunction('', '', hasError, { name: nameFields.countyValue, value: null, checked: false, files: undefined });
            onChangeFunction('', '', hasError, { name: nameFields.countyCode, value: null, checked: false, files: undefined });
            onChangeFunction('', '', hasError, { name: nameFields.parishCode, value: null, checked: false, files: undefined });
            onChangeFunction('', '', hasError, { name: nameFields.parishValue, value: null, checked: false, files: undefined });
        }
            
        if (!!defaultPostalCode) {
            let validate = !this.postalCodeRegex.test(defaultPostalCode);
            invalidFields.postalCodeErrors = validate;

            this.turnFieldTouched(nameFields.postalCode);

            if (!invalidFields.postalCodeErrors) {
                this.fetchData('ValidatePostalCode', `code=${defaultPostalCode}`).then(result => {
                    this.setState({
                        errors: !result,
                        invalidFields: {
                            ...invalidFields,
                            postalCodeErrors: !result
                        }
                    });

                    onChangeFunction('', '', !result, { name: nameFields.postalCode, value: defaultPostalCode, checked: false, files: undefined });
                }
                )
            } else {
                this.setState({
                    errors: this.postalCodeRegex
                        .test(defaultPostalCode)
                });

                onChangeFunction('', '', true, { name: nameFields.postalCode, value: defaultPostalCode, checked: false, files: undefined });


            }
        } else {

            this.setState({
                errors: this.postalCodeRegex
                    .test(defaultPostalCode)
            });

            this.props.onChangeFunction('', '', !this.postalCodeRegex.test(defaultPostalCode), { name: nameFields.postalCode, value: defaultPostalCode, checked: false, files: undefined });


        }
        if (!defaultAddress) {

            onChangeFunction('', '', true, { name: nameFields.addressName, value: defaultAddress ? defaultAddress : null, checked: false, files: undefined });
        } else {

            this.turnFieldTouched(nameFields.addressName);
        }
        if (!defaultPlace) {

            onChangeFunction('', '', true, { name: nameFields.place, value: defaultPlace ? defaultPlace : null, checked: false, files: undefined });
        } else {

            this.turnFieldTouched(nameFields.place);

        }

        if (this.state.showValidateAddress) {

            this.props.onChangeFunction('', '', true, { name: 'addressIsvalid', value: false, checked: false, files: undefined });
        }
    }
    render() {
        const { maxLength, invalidFields: { addressErrors, postalCodeErrors, placeErrors }, nameFields: { addressName, districtValue, countyValue, parishValue, postalCode, place }, setSelectDistrict, setSelectCounty, setSelectParish, showValidateAddress } = this.state;
        const { required, readonly, disabled, formResources, tooltipAddressText, defaultAddress, defaultPostalCode, defaultPlace, addressIsValid } = this.props;

        return (
            <>
                <Row>
                    <FormGroup className="col-lg-9">
                        <Label for={addressName} className="form-label text-uppercase">{formResources.FormLabelAddress}{required && "*"}</Label>
                        {tooltipAddressText &&
                            <TooltipItem key={`FormTooltip${addressName}`} id={`FormTooltip${addressName}`} text={tooltipAddressText} />
                        }
                        <Input
                            type="text"
                            name={addressName}
                            id={addressName}
                            aria-describedby={`${addressName}Help`}
                            invalid={addressErrors || defaultAddress == null}
                            defaultValue={defaultAddress}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            maxLength={maxLength.address}
                            required={required}
                            autoComplete="street-address"
                            readOnly={readonly}
                            disabled={disabled}
                        />
                        <div className="row">
                            <small id={`${addressName}Help`} className="col-auto order-last form-text text-right text-primary"><CountingInput limit={maxLength.address} actual={defaultAddress !== null ? defaultAddress.length : 0} /></small>
                            <div className="col invalid-feedback">{this.state.touched[addressName] ? formResources.FormErrorAddress : formResources.FormErrorFieldEmpty}</div>
                        </div>
                    </FormGroup>
                </Row>
                {showValidateAddress &&
                    <FormGroup tag="fieldset">
                        <legend className="form-label">{formResources.ValidateAddressLegend}</legend>

                        <Row>
                            <FormGroup className="col-lg-9">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" name="addressIsvalid" invalid={showValidateAddress && !addressIsValid} checked={addressIsValid} onChange={this.handleChange} required={true} />
                                        <strong className="form-label ml-1">{formResources.FormLabelValidateAddress}{required && "*"}</strong>
                                    </Label>
                                </FormGroup>
                                <div className={`invalid-feedback ${!addressIsValid ? 'visible' : ''}`} >{formResources.FormErrorFieldEmpty}</div>
                            </FormGroup>
                        </Row>
                    </FormGroup>
                }

                <Row>
                    <FormGroup className="col-6 col-sm-4 col-md-3">
                        <Label for={postalCode} className="form-label text-uppercase">{formResources.FormLabelPostalCodeCode}{required && "*"}</Label>
                        <Input
                            type="text"
                            name={postalCode}
                            id={postalCode}
                            aria-describedby={`${postalCode}Help`}
                            invalid={postalCodeErrors || !defaultPostalCode}
                            defaultValue={defaultPostalCode}
                            onChange={this.onChangePostalCode}
                            onBlur={this.handleBlur}
                            required={required}
                            autoComplete="postal-code"
                            readOnly={readonly}
                            disabled={disabled}
                        />
                        <small id={`${postalCode}Help`} className="form-text text-right text-primary">{formResources.FormHelperPostalCodeCode}</small>
                        <div className="invalid-feedback">{!!this.state.touched[postalCode] ? formResources.FormErrorPostalCodeCode : formResources.FormErrorFieldEmpty}</div>
                    </FormGroup>
                    <FormGroup className="col-6 col-sm-8 col-md-4">
                        <Label for={place} className="form-label text-uppercase">{formResources.FormLabelPlace}{required && "*"}</Label>
                        <Input
                            type="text"
                            name={place}
                            id={place}
                            invalid={placeErrors || defaultPlace === null}
                            defaultValue={defaultPlace}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            maxLength={maxLength.place}
                            required={required}
                            readOnly={readonly}
                            disabled={disabled}
                        />
                        <small id={`${place}Help`} className="col-auto order-last form-text text-right text-primary"> <CountingInput limit={maxLength.place} actual={defaultPlace !== null ? defaultPlace.length : 0} /></small>
                        <div className="invalid-feedback">{!!this.state.touched[place] ? formResources.FormErrorPlace : formResources.FormErrorFieldEmpty}</div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="col-sm-4 col-lg-3">
                        <Label for={districtValue} className="form-label text-uppercase">{formResources.FormLabelDiscrictValue}{required && "*"}</Label>
                        <Input
                            type="select"
                            name={districtValue}
                            id={districtValue}
                            invalid={setSelectDistrict === null || setSelectDistrict === ''}
                            value={!!setSelectDistrict && setSelectDistrict}
                            onChange={this.onChangeDistricts}
                            onBlur={this.handleBlur}
                            required={required}
                            readOnly={readonly}
                            disabled={disabled}
                        >
                            <option value="">{formResources.SelectDefaultOption}</option>
                            {this.state.districts.map((el, i) =>
                                <option key={i} value={el.code} data-description={el.description}>{el.description}</option>
                            )}
                        </Input>
                        <div className="invalid-feedback">{this.state.touched[districtValue] ? formResources.FormErrorDiscrictValue : formResources.FormErrorFieldEmpty}</div>
                    </FormGroup>
                    <FormGroup className="col-sm-4 col-lg-3">
                        <Label for={countyValue} className="form-label text-uppercase">{formResources.FormLabelCountyValue}{required && "*"}</Label>
                        <Input
                            type="select"
                            name={countyValue}
                            id={countyValue}
                            invalid={setSelectCounty === null || setSelectCounty === ''}
                            value={!!setSelectCounty && setSelectCounty}
                            onChange={this.onChangeCounties}
                            onBlur={this.handleBlur}
                            required={required}
                            readOnly={readonly}
                            disabled={!this.state.counties.enable}
                        >
                            {this.state.counties.enable && <option value="">{formResources.SelectDefaultOption}</option>}
                            {this.state.counties.list.map((el, i) =>
                                <option key={i} value={el.code} data-description={el.description}>{el.description}</option>
                            )}
                        </Input>
                        <div className="invalid-feedback">{this.state.touched[countyValue] ? formResources.FormErrorCountyValue : formResources.FormErrorFieldEmpty}</div>
                    </FormGroup>
                    <FormGroup className="col-sm-4 col-lg-3">
                        <Label for={parishValue} className="form-label text-uppercase">{formResources.FormLabelParishValue}{required && "*"}</Label>
                        <Input
                            type="select"
                            name={parishValue}
                            id={parishValue}
                            invalid={setSelectParish === null || setSelectParish === ''}
                            value={!!setSelectParish && setSelectParish}
                            onChange={this.onChangeParishs}
                            onBlur={this.handleBlur}
                            required={required}
                            readOnly={readonly}
                            disabled={!this.state.parishs.enable}
                        >
                            {this.state.parishs.enable && <option value="">{formResources.SelectDefaultOption}</option>}
                            {this.state.parishs.list.map((el, i) =>
                                <option key={i} value={el.code} data-description={el.description}>{el.description}</option>
                            )}
                        </Input>
                        <div className="invalid-feedback">{this.state.touched[parishValue] ? formResources.FormErrorParishValue : formResources.FormErrorFieldEmpty}</div>
                    </FormGroup>
                </Row>
            </>
        );
    }
}