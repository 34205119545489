import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import RequestTokenForm from './RequestTokenForm';
import TokenForm from './TokenForm';
import ImgCardCaixaIUOnly from '../../img/content/card-caixa-iu_only.png';
import './Authentication.scss';

export class AuthByToken extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            authenticationData: {
                nif: '',
                mobilePhoneNumber: '',
                smsCode: ''
            },
            step: 'requestToken',
            error: ''
        };

        this.handleStep = this.handleStep.bind(this);
        this.validateTokenError = this.validateTokenError.bind(this);
        this.requestTokenSuccess = this.requestTokenSuccess.bind(this);
    }

    handleStep() {
        this.setState({
            step: 'requestToken'
        });
    }

    validateTokenError(errorMessage) {
        this.setState({
            authenticationData: {},
            step: 'requestToken',
            error: errorMessage
        });

        window.scrollTo(0, 0);
    }

    requestTokenSuccess(data) {
        this.setState({
            authenticationData: data,
            step: 'validateToken',
            error: ''
        });
    }

    render() {
        const { step, authenticationData } = this.state;
        const { globalResources, formResources } = this.props;

        switch (step) {
            case 'validateToken':
                return (
                    <TokenForm globalResources={globalResources} formResources={formResources} setStep={this.handleStep} authenticationData={authenticationData} validateTokenError={this.validateTokenError} errorMessage={this.state.error} />
                );
            case 'requestToken':
            default:
                return (
                    <main className="page-content authentication">
                        <Container>
                            <Row>
                                <Col lg="5">
                                    <h1>{formResources.AuthenticationByTokenTitle}</h1>
                                    <RequestTokenForm globalResources={globalResources} formResources={formResources} requestTokenSuccess={this.requestTokenSuccess} validateTokenError={this.validateTokenError} errorMessage={this.state.error} />
                                </Col>
                                <Col lg="7" className="text-center text-lg-right">
                                    <p><img className="img" src={ImgCardCaixaIUOnly} alt="" /></p>
                                </Col>
                            </Row>
                        </Container>
                    </main>
                );
        }
    }
}
