import React, { Component } from 'react';
import { NavMenu } from './Global/NavMenu';
import { Footer } from './Global/Footer';
import { CookieBar } from './Global/CookieBar';

export class Layout extends Component {

    render() {
        return (
            <>
                <CookieBar globalResources={this.props.globalResources} />
                <NavMenu globalResources={this.props.globalResources} />
                {this.props.children}
                <Footer globalResources={this.props.globalResources} />
            </>
        );
    }
}