import React, { Component } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import './FAQs.scss';

export class FAQ extends Component {
    static displayName = FAQ.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            activeBTN: {}
        };

        this.handleToogle = this.handleToogle.bind(this);
    }

    handleToogle(id) {
        this.setState({
            activeBTN: {
                [id]: !this.state.activeBTN[id]
            }
        });
    }

    render() {
        const { activeBTN } = this.state;
        const { globalResources, faqsHP } = this.props;

        const faqsList = faqsHP.map((obj, index) => (
            <article key={`faq-${index}`}>
                <Button type="button" id={`faq-${index}`} color="link" block className="btn-faqs text-left" onClick={() => this.handleToogle(index)} active={activeBTN[index]}>
                    <h3 className="faqs__sub-title h4">{obj.question}<span className="btn-arrow-icon"></span></h3>
                </Button>
                <Collapse toggler={`#faq-${index}`} isOpen={activeBTN[index] ? activeBTN[index] : false}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: obj.answer }}></div>
                </Collapse>
            </article>
        ));

        return (
            <section className="faqs bg-light">
                <Container>
                    <Row className="pt-3">
                        <Col>
                            <div id="faq" className="anchor-position">
                                <h2 className="faqs__title text-center my-4"><span className="sr-only">{globalResources.HPFAQTitle}</span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pb-5">
                            {faqsList}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
