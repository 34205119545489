import React, { Component } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { MainFormNavInfo } from "./MainFormNavInfo";
import { NameField } from "../Fields/NameField";
import { IdentificationField } from "../Fields/IdentificationField";
import { GendersField } from "../Fields/GendersField";
import { PoliticalRelations } from "../Fields/PoliticalRelations";
import { NifField } from "../Fields/NifField";
import { EmailField } from "../Fields/EmailField";
import { PhoneNumberField } from "../Fields/PhoneNumberField";
import { MainFormNavigation } from "./MainFormNavigation";
import { SideBarStep2 } from "./SideBarStep2";
import { AddressField } from "../Fields/AddressField";
import Loading from "../Global/Loading";
import CountryAutoCompleteField from "../Fields/CountryAutoCompleteField";
import PlaceOfBirthField from "../Fields/PlaceOfBirthField";

export class MainFormStep2 extends Component {
	constructor(props) {
		super(props);

		this.props = props;
		this.state = {
			loading: false,
			errors: [],
			isValid: false,
			touched: {
				entity: !!this.props.data.entity,
				position: !!this.props.data.position,
			},
		};

		this.back = this.back.bind(this);
		this.continue = this.continue.bind(this);
		this.onChangeFunction = this.onChangeFunction.bind(this);
		this.turnFieldTouched = this.turnFieldTouched.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.checkFormValidate();
	}

	back() {
		this.props.prevStep("person", this.props.data);
	}

	continue() {
		this.props.nextStep("person", this.props.data);
	}

	turnFieldTouched(field) {
		const { touched } = this.state;

		this.setState({
			touched: {
				...touched,
				[field]: true,
			},
		});
	}
	handleBlur(event) {
		const { name } = event.target;
		this.turnFieldTouched(name);
	}
	onChangeFunction(e) {
		let hasError = e.target.value === "" || e.target.value === null ? true : false;

		this.props.handleChange(e.target, "", hasError);
	}

	render() {
		const { loading, touched } = this.state;
		const { activeStep, handleChange, hasPreviousStep, hasNextStep, globalResources, formResources, faqsStep, data, loadingData } = this.props;

		if (loadingData) {
			return (
				<Container className="d-flex flex-column h-100 justify-content-center align-items-center">
					<Loading loadingText={globalResources.LoadingPageContentText} />
				</Container>
			);
		}

		return (
			<Container>
				<Row className="main-form__header">
					<Col>
						<h1 className="sr-only">{formResources.Step2Title}</h1>

						<MainFormNavInfo activeStep={activeStep} formResources={formResources} />
					</Col>
				</Row>
				<Row>
					<Col lg="3" xl="4" className="order-first order-lg-last">
						<SideBarStep2 formResources={formResources} faqsStep={faqsStep} />
					</Col>

					<Col lg="9" xl="8">
						<Form className="needs-validation" noValidate onChange={this.checkFormStatus}>
							<FormGroup tag="fieldset" className="mb-0">
								<legend className="sr-only">Confirma os teus dados pessoais</legend>

								<Row>
									<FormGroup className="col-lg-9 pb-1">
										<Label for="name" className="form-label text-uppercase">
											{formResources.FormLabelName}
										</Label>
										<NameField
											id="name"
											inputValidationText={{
												empty: formResources.FormErrorFieldEmpty,
												invalid: formResources.FormErrorName,
											}}
											inputValue={data.name}
											readonly={true}
										/>
									</FormGroup>
								</Row>

								<GendersField
									data={data}
									inputID="gender"
									selectID="gender"
									selectValue={data.gender}
									selectLabel={formResources.FormLabelGender}
									onChangeFunction={handleChange}
									inputValidationText={{
										empty: formResources.FormErrorFieldEmpty,
									}}
									formResources={formResources}
									required={true}
								/>

								<IdentificationField
									identificationTypes={!this.props.referenceData? null : this.props.referenceData.identificationTypes}
									formResources={formResources}
									selectID="identificationTypeCode"
									selectLabel={formResources.FormLabelIdentificationTypeCode}
									tootipSelectText={formResources.FormTooltipIdentificationType}
									selectValidationText={formResources.FormErrorIdentificationTypeCode}
									selectValue={data.identificationTypeCode}
									inputID="identification"
									inputLabel={formResources.FormLabelIdentification}
									tootipInputText={formResources.FormTooltipIdentification}
									inputValidationText={{
										empty: formResources.FormErrorFieldEmpty,
										invalid: formResources.FormErrorIdentification,
									}}
									inputValue={data.identification}
									onChangeFunction={handleChange}
									data={data}
									required={true}
								/>
								<Row>
									<FormGroup className="col-lg-9">
										<Label for="email" className="form-label text-uppercase">
											{formResources.FormLabelEmail}*
										</Label>
										<EmailField
											id="email"
											inputValidationText={{
												empty: formResources.FormErrorFieldEmpty,
												invalid: formResources.FormErrorEmail,
											}}
											inputValue={data.email}
											onChangeFunction={handleChange}
											required={true}
										/>
									</FormGroup>
								</Row>
								<Row>
									<FormGroup className="col-sm-12 col-lg-9">
										<CountryAutoCompleteField
											fieldName="placeOfBirthCountryValue"
											fieldNameCode="placeOfBirthCountryCode"
											labelText={formResources.FormLabelPlaceOfBirth}
											defaultCode={data.placeOfBirthCountryCode}
											defaultValue={data.placeOfBirthCountryValue}
											onChangeFunction={handleChange}
											updateOnlyFields={true}
											validationText={{
												empty: formResources.FormErrorFieldEmpty,
												invalid: formResources.FormErrorPlaceOfBirth,
											}}
										/>
									</FormGroup>
									<FormGroup className="col-sm-12 col-lg-9">
										{data.placeOfBirthCountryCode === "PT" && (
											<PlaceOfBirthField
												districts={!this.props.referenceData? null : this.props.referenceData.districts}
												formResources={formResources}
												defaultDistrict={data.placeOfBirthDistrictCode}
												defaultCounty={data.placeOfBirthCountyCode}
												onChangeFunction={handleChange}
												required={true}
											/>
										)}
									</FormGroup>
								</Row>

								<Row>
									<FormGroup className="col-sm-6 col-lg-5">
										<CountryAutoCompleteField
											fieldName="fiscalCountryValue"
											fieldNameCode="fiscalCountryCode"
											labelText={formResources.FormLabelFiscalCountry}
											validationText={{
												empty: formResources.FormErrorFieldEmpty,
												invalid: formResources.FormErrorFiscalCountry,
											}}
											defaultValue={data.fiscalCountryValue}
											defaultCode={data.fiscalCountryCode}
											updateOnlyFields={true}
											readonly={true}
										/>
									</FormGroup>

									<FormGroup className="col-sm-6 col-lg-4">
										<Label for="fiscalNumber" className="form-label text-uppercase">
											{formResources.FormLabelFiscalNumber}
										</Label>
										<NifField
											id="fiscalNumber"
											inputValidationText={{
												empty: formResources.FormErrorFieldEmpty,
												invalid: formResources.FormErrorNIF,
											}}
											inputValue={data.fiscalNumber}
											country={data.countryCode}
											readonly={true}
										/>
									</FormGroup>
								</Row>

								<PhoneNumberField
									mobilePhoneDialogCodeID="mobilePhoneDialogCode"
									mobilePhoneID="mobilePhone"
									formResources={formResources}
									inputValidationText={{
										empty: formResources.FormErrorFieldEmpty,
										invalid: formResources.FormErrorMobilephoneNumb,
									}}
									defaultDialogCode={data.mobilePhoneDialogCode}
									inputValue={data.mobilePhone}
									country={data.mobilePhoneDialogValue}
									readonly={true}
								/>

								<AddressField
									districts={!this.props.referenceData? null : this.props.referenceData.districts}
									formResources={formResources}
									tooltipAddressText={formResources.FormTooltipAddress}
									defaultAddress={data.address}
									defaultPostalCode={data.postalCodeCode}
									defaultPlace={data.place}
									defaultParish={data.parishCode}
									defaultDistrict={data.districtCode}
									defaultCounty={data.countyCode}
									onChangeFunction={handleChange}
									addressIsValid={data.addressIsvalid}
									required={true}
								/>

								<Row>
									<FormGroup className="col">
										<FormGroup check>
											<Label check>
												<Input type="checkbox" name="secondAddress" onChange={handleChange} checked={!!data.secondAddress} />
												<strong className="form-label ml-1">
													{formResources.FormLabelSecondAddress} <small>({formResources.FormInfoOptional})</small>
												</strong>
											</Label>
										</FormGroup>
									</FormGroup>
								</Row>

								{!!data.secondAddress && (
									<FormGroup tag="fieldset">
										<legend className="sr-only">{formResources.SecondAddressLegend}</legend>

										<AddressField
											districts={!this.props.referenceData? null : this.props.referenceData.districts}
											formResources={formResources}
											tooltipAddressText={formResources.FormTooltipSecondAddress}
											defaultAddress={data.fiscalAddress}
											defaultPostalCode={data.fiscalPostalCodeCode}
											defaultPlace={data.fiscalPlace}
											defaultParish={data.fiscalParishCode}
											defaultDistrict={data.fiscalDistrictCode}
											defaultCounty={data.fiscalCountyCode}
											onChangeFunction={handleChange}
											secondaryAddress={true}
											addressIsValid={true}
											required={true}
										/>
									</FormGroup>
								)}

								<PoliticalRelations
									politicalRelations={!this.props.referenceData? null : this.props.referenceData.politicalRelations}
									data={data}
									touched={touched}
									inputID="politicalRelationCode"
									selectID="politicalRelationCode"
									selectValue={data.politicalRelationCode}
									onChangeFunction={handleChange}
									inputValidationText={{
										empty: formResources.FormErrorFieldEmpty,
									}}
									formResources={formResources}
									required={true}
								/>

								<MainFormNavigation
									analyticsId="continue_3"
									prevStep={this.back}
									nextStep={this.continue}
									hasPreviousStep={hasPreviousStep}
									hasNextStep={hasNextStep}
									prevText={globalResources.NavBackText}
									nextText={globalResources.NavForwardText}
									buttonDisable={!this.props.isValid}
									loading={loading}
									loadingText={globalResources.LoadingPageText}
								/>
							</FormGroup>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}
}
