import React, { Component } from 'react';
import { Row, FormGroup, CustomInput, Label} from 'reactstrap';

export class SecondExperienceFields extends Component{
    constructor(props){
        super(props)

        this.onchangeValue = this.onchangeValue.bind(this);
    }


    onchangeValue(event){
        let obj = this.props.questionsData;
        let name= event.target.name;
        let value= event.target.value;
        
        let idx =  obj.Questions.findIndex(object => {
            return object.QuestionCode === name;
        });

        if(idx === undefined || idx === -1){
            obj.Questions.push({QuestionCode: name, ResponseCodes:[{Code: value}]});
        }else{
            obj.Questions[idx] = {QuestionCode: name, ResponseCodes:[{Code: value}]}
        }

        this.props.onChange({name: "questionData", value:obj}, "", false);
    }

    render(){
        const {data, questionsData, formResources} = this.props;
        let secondData = ""
        if(data){secondData = data[1];}
        
        let responseData = questionsData.Questions.find(obj => obj.QuestionCode === secondData.code);

        return(
            <FormGroup className="col p-0 mt-4">
                <Label className="h6"><strong>{secondData.text}</strong> {secondData.isRequired? " *": ""}</Label>
                <Row className="m-0 pt-3 columngap-1_5">
                    {secondData &&
                        secondData.answers.map((obj) => 
                        <div key={`col2-${obj.code}`} className="d-flex flex-column align-items-center">
                            <FormGroup className="mb-0"  key={`formgroup2-${obj.code}`}>
                                <CustomInput 
                                    key={`CustomInput2-${obj.code}`} 
                                    id={obj.code} 
                                    name={secondData.code} 
                                    value={obj.code} 
                                    onChange={this.onchangeValue}
                                    checked={!responseData? false: responseData.ResponseCodes[0].Code === obj.code }
                                    className="mr-0 checkLabelPad-0"
                                    type="radio">
                                </CustomInput>
                            </FormGroup>
                            <Label key={`label2-${obj.code}`} htmlFor={obj.code} className="form-label">{Number(obj.text)+1}</Label>
                        </div>
                        )
                    }
                    {secondData && secondData.isRequired && !responseData &&
                        <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                                {formResources.FieldRequired}
                            </div> 
                        </>
                    }
                </Row>
            </FormGroup>
        )
    }
}