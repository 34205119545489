import React, { Component } from 'react';
import { Row, FormGroup, CustomInput, Label } from 'reactstrap';

export class GendersField extends Component {
  constructor(props) {
    super(props);

    this.props = props;

      this.state = {
          errors: false,
          genders : [
              {
                  code: 'F',
                  description: 'FEMININO',
              },
              {
                  code: 'M',
                  description: 'MASCULINO',
              },
          ]
      };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
      const { selectValue, onChangeFunction } = this.props;

      let element = this.state.genders.find((el) => el.code === selectValue);
      this.setState({
          setSelect: element ? element.code : '',
          errors: !selectValue,
      });


      onChangeFunction('', '', !selectValue, {
          name: 'gender',
          value: selectValue,
          checked: false,
          files: undefined,
          type: 'radio',
      });
  }

  handleChange(e) {
    this.setState({
      setSelect: e.target.value,
      errors: !e.target.value,
    });
    let hasError = !e.target.value;
    this.props.onChangeFunction(e.target, '', false, hasError);
  }

  render() {
    const { errors, setSelect } = this.state;
    const {
      required,
      selectLabel,
      selectValue,
      inputValidationText,
    } = this.props;

    return (
      <>
        <Row>
          <FormGroup tag="fieldset" className="col form-group pb-4">
            <Label for="Genders" className="form-label text-uppercase">
              {selectLabel}
            </Label>
            <div className="d-flex">
              {this.state.genders.map((item, key) => (
                <CustomInput
                  className="py-1"
                  type="radio"
                  name="gender"
                  id={item.code}
                  key={item.code}
                  value={item.code}
                  label={item.description}
                  onChange={this.handleChange}
                  data-description={item.description}
                  checked={setSelect === item.code}
                  invalid={errors === null}
                  required={required}
                />
              ))}
            </div>

            {inputValidationText && (
              <div
                className={`invalid-feedback ${!selectValue ? 'visible' : ''}`}
              >
                {this.state.errors ? inputValidationText.empty : null}
              </div>
            )}
          </FormGroup>
        </Row>
      </>
    );
  }
}
