import React, { Component } from 'react';
import { FormGroup, Label, Input, Tooltip } from 'reactstrap';

export class BusinessRelationshipField extends Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            isOpen: false,
            clientPurposeBusinessRelationships: this.props.data.clientPurposeBusinessRelationships,
            tooltipOpen: false,
        }
        this.handlecodeChange = this.handlecodeChange.bind(this);
        this.handlerOpen = this.handlerOpen.bind(this);
        this.select = this.select.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    componentDidUpdate() {
        if (this.state.isOpen === true) {
            document.addEventListener('click', this.handlerOpen);
        }
        else {
            document.removeEventListener('click', this.handlerOpen);
        }
    }

    handlerOpen(e) {
        if (this.state.isOpen && this.container.contains(e.target) && e.target !== e.currentTarget) {
            return;
        } else {
            this.setState((prevState) => { return { isOpen: !prevState.isOpen } });
        }
    }

    handlecodeChange(value) {
        var clientPurposeBusinessRelationshipTemp;

        if (this.state.clientPurposeBusinessRelationships !== null && this.state.clientPurposeBusinessRelationships.includes(value)) {
            clientPurposeBusinessRelationshipTemp = this.state.clientPurposeBusinessRelationships.filter((e) => e !== value);
        } else {
            if (this.state.clientPurposeBusinessRelationships === null) {
                clientPurposeBusinessRelationshipTemp = [value];
            } else {
                clientPurposeBusinessRelationshipTemp = [...this.state.clientPurposeBusinessRelationships, value];
            }
        }

        this.setState({
            clientPurposeBusinessRelationships: clientPurposeBusinessRelationshipTemp
        });

        const clientPurposeBusinessRelationships = { name: 'clientPurposeBusinessRelationships', value: clientPurposeBusinessRelationshipTemp }
        this.props.onChange(clientPurposeBusinessRelationships, "", false);
    }

    select(codeValue) {
        return this.state.clientPurposeBusinessRelationships && this.state.clientPurposeBusinessRelationships.includes(codeValue);
    }

    render(){
        const { options } = this.props;
        const { isOpen } = this.state;

        return(
            <>
                <FormGroup>
                    <Label className="form-label text-uppercase">
                        {this.props.formResources.PurposeBusinessRelationshipLabel}
                    </Label>
                    <p className="form-label m-0"><small>{this.props.formResources.PurposeBusinessRelationshipSubLabel}</small></p>

                    <div  style={{ position: "relative" }}>
                        <div id="TooltipSelect-1" className={`form-control ${this.state.clientPurposeBusinessRelationships === null || this.state.clientPurposeBusinessRelationships.length === 0 ? 'is-invalid' : ''}`} onClick={this.handlerOpen}>
                            {this.state.clientPurposeBusinessRelationships === null || this.state.clientPurposeBusinessRelationships === undefined || options === undefined || this.state.clientPurposeBusinessRelationships.length === 0 ?
                                <p>{this.props.formResources.PurposeBusinessRelationshipText}</p>
                                :
                                <>
                                    <p className="selectedOptions">{this.state.clientPurposeBusinessRelationships.map((value, index) => {
                                        return `${options.filter((e) => e.code === value)[0].description}${(this.state.clientPurposeBusinessRelationships.length > 1) && (index > 0 || index < this.state.clientPurposeBusinessRelationships.length - 1) ? '; ' : ''}`
                                    })}
                                    </p>
                                </>
                            }
                        </div>
                        <div ref={(el) => { this.container = el; }} className={`${isOpen ? 'open' : ''} options-multiple`}>
                            {options && options.map((value, index) => {
                                return (
                                    <div className={this.select(value.code) ? 'options selected' : 'options'} key={`optionsM-${index}`} onClick={() => this.handlecodeChange(value.code)}>
                                        <Input type="checkbox" readOnly checked={this.select(value.code) ? true : false}></Input>
                                        <p>{value.description}</p>
                                    </div>
                                );
                            })}
                        </div>
                        {(this.state.clientPurposeBusinessRelationships === null || this.state.clientPurposeBusinessRelationships.length === 0) &&
                            <div className='invalid-feedback'>{this.props.formResources.FormErrorFieldEmpty}</div>
                        }
                    </div>

                    {options !== undefined && this.state.clientPurposeBusinessRelationships !== null && this.state.clientPurposeBusinessRelationships.length > 0 &&
                        <Tooltip
                            placement="right"
                            modifiers={{ offset: { offset: '0, 15px' } }}
                            isOpen={this.state.tooltipOpen}
                            target={`TooltipSelect-1`}
                            toggle={this.toggleTooltip}
                            autohide={false}
                        >
                            {this.state.clientPurposeBusinessRelationships.map((value, index) => {
                                return `${options.filter((e) => e.code === value)[0].description}${(this.state.clientPurposeBusinessRelationships.length > 1) && (index > 0 || index < this.state.clientPurposeBusinessRelationships.length) ? '; ' : ''}`
                            })}
                        </Tooltip>
                    }
                </FormGroup>
            </>
        )
    }
}




