export class ConfigManager {
    async getConfigs() {
        const response = await fetch('/Settings/GetConfigs', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        });

        //console.log('GetConfigs', response);

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data;
        }

        return null;
    }

    async enableSMSAuth() {
        const response = await fetch('/Settings/EnableSMSAuth', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data;
        }

        return null;
    }

    async enableDigitalSign() {
        const response = await fetch('/Settings/EnableDigitalSign', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data.enableDigitalSign;
        }

        return null;
    }
    
    async getFileSize() {
        const response = await fetch('/Settings/GetFileSizeData', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status === 200 && response.ok) {
            let data = await response.json();

            return data;
        }

        return null;
    }
}