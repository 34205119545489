import JSEncrypt from 'jsencrypt';

export class CryptoService {
    async getPublicKey() {
        const response = await fetch('/DigitalSignature/GetPublicKey', {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' }
        });
        var result = await response.json();

        if (response.status === 200 && response.ok && !result.error) {
            return result.value
        }

        return null;
    }

    encryptMessage(message, publicKey) {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        return jsEncrypt.encrypt(message);
    }
}

const cryptoService = new CryptoService();

export default cryptoService;
