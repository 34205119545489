import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import TooltipItem from '../../Fields/TooltipItem';
import ImgCardCaixaIUHand from '../../../img/content/card-caixa-iu_hand.png';
import ImgCardCaixaIU from '../../../img/content/card-caixa-iu_only.png';

export class CardCaixaIU extends Component {
    static displayName = CardCaixaIU.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            isDesktop: window.innerWidth >= 992 ? true : false
        }

        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        this.setState({ isDesktop: window.innerWidth >= 992 });
    }

    render() {
        const { isDesktop } = this.state;
        const { formResources } = this.props;

        return (
            <section className="product__card-caixaiu">
                <Row className="mx-0">
                    <Col xs="11" lg="8">
                        <div className="pl-5">
                            <h2 className="h3 text-dark mb-4 icn-position"><span className="icn-approved"></span><strong>{formResources.ContaExtratoJovemExtratoTitle}</strong> <TooltipItem key="FormTooltipCartaoEstudante" id="FormTooltipCartaoEstudante" text={formResources.FormTooltipCartaoEstudante} /></h2>

                            <div className="mb-3 ml-5 title-info-docs" dangerouslySetInnerHTML={{ __html: formResources.TitleInfoDocs }} />
                                <div className="mb-5 pl-5" dangerouslySetInnerHTML={{ __html: formResources.ContaExtratoJovemExtratoInfoDocs }} />
                                <div className="h3 mb-5" dangerouslySetInnerHTML={{ __html: formResources.CartaoEstudanteCaixaIUText2 }} />
                                <h3 className="h3 mb-3">{formResources.UniqueCardTitle}</h3>
                            <div dangerouslySetInnerHTML={{ __html: formResources.UniqueCardDescription }} />
                        </div>
                    </Col>
                    <Col xs={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 0 }} className="text-center">
                        {isDesktop
                            ? <img className="img img__desktop" src={ImgCardCaixaIUHand} alt="" />
                            : <img className="img img__mobile" src={ImgCardCaixaIU} alt="" />
                        }
                    </Col>
                </Row>
            </section> 
        );
    }
}
