import React, { Component } from 'react';
import { Row, FormGroup, CustomInput, Label} from 'reactstrap';

export class FirstExperienceField extends Component{

    constructor(props){
        super(props);
        this.onchangeValue = this.onchangeValue.bind(this);
    }

    onchangeValue(event){
        let obj = this.props.questionsData;
        let name= event.target.name;
        let value= event.target.value;
        
        let idx =  obj.Questions.findIndex(object => {
            return object.QuestionCode === name;
        });

        if(idx === undefined || idx === -1){
            obj.Questions.push({QuestionCode: name, ResponseCodes:[{Code: value}]});
        }else{
            obj.Questions[idx] = {QuestionCode: name, ResponseCodes:[{Code: value}]}
        }
        
        this.props.onChange({name: "questionData", value: obj}, "", false);
    }

    render(){

        const {data, questionsData, formResources} = this.props;
        let firstDate = ""
        if(data){firstDate = data[0]}

        let responseData = questionsData.Questions.find(obj => obj.QuestionCode === firstDate.code)
        
        return(
            <FormGroup className="col p-0">
                <Label className="h6"><strong>{firstDate.text}</strong> {firstDate.isRequired? " *": ""} </Label>
                <Row className="columngap-2 m-0 pt-2">
                    {firstDate &&
                        firstDate.answers.map((obj) => 
                        <div key={`col1-${obj.code}`} className="d-flex flex-column align-items-center">
                            <img className="img pb-2" src={require(`../../img/icn/icon-${obj.code}.svg`)} alt="" />
                            <FormGroup className="mb-0"  key={`formgroup1-${obj.code}`}>
                                <CustomInput
                                    key={`CustomInput1-${obj.code}`} 
                                    id={obj.code} 
                                    name={firstDate.code} 
                                    value={obj.code} 
                                    checked = {!responseData? false : responseData.ResponseCodes[0].Code === obj.code}
                                    onChange={this.onchangeValue}
                                    className="mr-0 checkLabelPad-0" 
                                    type="radio">
                                </CustomInput>
                            </FormGroup>
                            <Label key={`label1-${obj.code}`} htmlFor={obj.code} className="form-label">{obj.text}</Label>
                        </div>
                        )
                    }
                    {firstDate && firstDate.isRequired && !responseData &&
                        <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                                {formResources.FieldRequired}
                            </div> 
                        </>
                    }
                </Row>
            </FormGroup>
        )
    }
}