import React, { Component } from "react";
import { LogoutBar } from "../Global/LogoutBar";
import { MainFormStep1 } from "./MainFormStep1";
import { MainFormStep2 } from "./MainFormStep2";
import { MainFormStep3 } from "./MainFormStep3";
import { MainFormStep4 } from "./MainFormStep4";
import MainFormStep5 from "./MainFormStep5";
import { LegalDocumentation } from "./LegalDocumentation";
import { FormManager } from "./FormManager";
import { FooterDocuments } from "../Authentication/FooterDocuments";
import "./MainForm.scss";
import { ConfigManager } from "../Global/SettingsManager";

export class MainForm extends Component {
	constructor(props) {
		super(props);

		this.props = props;
		this.state = {
			summary: {},
			person: {},
			product: {},
			consentData: {},
			finalData: {},
			questionData:{
				Questions: [],
			},
			prevStep: 1,
			step: 1,
			loading: false,
			errors: {},
			isValid: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
			},
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleValidation = this.handleValidation.bind(this);
		this.validateForm = this.validateForm.bind(this);

		this.nextStep = this.nextStep.bind(this);
		this.prevStep = this.prevStep.bind(this);
		this.goToStep = this.goToStep.bind(this);

		if (this.state.summary && this.state.person && this.state.product && this.state.consentData && this.state.finalData) {
			this.state.loading = true;
		}
	}

	componentDidMount() {
		if (this.state.summary && this.state.person && this.state.product && this.state.consentData && this.state.finalData) {
			this.getFormData();
			this.fetchData();
		}
	}

	async fetchData(){
		const response = await fetch('/form/GetReferenceData', {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			},
		});
		var result = await response.json();
		if(response.ok && response.status === 200){
			this.setState({
				...this.state,
				referenceData: result,
			});
		}else{
			console.log("Error");
		}
	}

	async getFormData() {
		var formManager = new FormManager();
		var form = await formManager.getForm();
		var configManager = new ConfigManager();
		var _getFileSizes = await configManager.getFileSize();

		if (form !== null && _getFileSizes !== null && Object.keys(form).length > 0 && form.constructor === Object) {
			var { summary, person, product, consentData } = form;

			

			const mobilePhoneDialogCode = person.mobilePhoneDialogCode !== null && person.mobilePhoneDialogCode !== "" ? person.mobilePhoneDialogCode : "+351";
			const mobilePhone = mobilePhoneDialogCode + " " + person.mobilePhone;

			this.setState({
				summary: {
					...summary,
				},
				person: {
					...person,
					showIssuedBy: false,
				},
				product: {
					contaCaixaM: product && product.contaCaixaM ? product.contaCaixaM : false,
					continentCardNumber: null,
					contaPoupanca: false,
					useExistingAccount: undefined,
					ValidUseExistingAccount: false,
				},
				consentData: {
					...consentData,
					photographyFile: "",
					documentIDCoverFile: "",
					documentIDBackFile: "",
					fiscalNumberCardFile: "",
					addressFile: "",
					secondAddressFile: "",
					fileSizes: _getFileSizes,
				},
				finalData: {
					mobilePhone: mobilePhone,
					agreementLegalDocumentation: null,
					confirmDataAndProducts: null,
					rgpAgrement: null,
					rgpdAgreementCampaignsAndEvents: null,
				},
				loading: false,
			});
		} else {
			this.setState({
				loading: false,
				errors: this.props.globalResources.Erro_Interno,
			});
			this.props.history.push("/Authentication");
		}
	}

	hasErrors() {
		var errors = Object.values(this.state.errors);
		var hasErrors =
			errors.length === 0 ||
			errors.reduce(function (acc, value) {
				return acc || value;
			}, false);

		return hasErrors;
	}

	handleChange(event, target, errors, rawData) {
		const { name, value, checked, files, type } = !!rawData ? rawData : target !== undefined ? event : event.target;
		const { person } = this.state;
		var _value;

		switch (type) {
			case "checkbox":
				_value = checked;
				break;
			case "radio":
				_value = value !== "" ? value : false;
				break;
			default:
				_value = value;
		}

		var _files = files !== null && files !== undefined ? files[0] : _value;
		var _errors = errors !== undefined ? errors : false;

		if (!_errors) {
			let errorsList = this.state.errors;
			delete errorsList[name];

			this.setState(() => ({
				errors: {
					...errorsList,
				},
			}));
		} else {
			this.setState((prevState) => ({
				errors: {
					...prevState.errors,
					[name]: _errors,
				},
			}));
		}

		if (name === "secondAddress" && checked === false) {
			let errorsList = this.state.errors;

			delete errorsList["fiscalCountyCode"];
			delete errorsList["fiscalCountyValue"];
			delete errorsList["fiscalDistrictCode"];
			delete errorsList["fiscalDistrictValue"];
			delete errorsList["fiscalParishCode"];
			delete errorsList["fiscalParishValue"];
			delete errorsList["fiscalPlace"];
			delete errorsList["fiscalPostalCodeCode"];
			delete errorsList["fiscalPostalCodeValue"];
			delete errorsList["fiscalAddress"];

			this.setState((prevState) => ({
				person: {
					...prevState.person,
					fiscalAddress: "",
					fiscalCountyCode: "",
					fiscalCountyValue: "",
					fiscalDistrictCode: "",
					fiscalDistrictValue: "",
					fiscalParishCode: "",
					fiscalParishValue: "",
					fiscalPlace: "",
					fiscalPostalCodeCode: "",
					fiscalPostalCodeValue: "",
				},
				errors: {
					...prevState.errors,
					...errorsList,
				},
			}));
		}

		if (name === "politicalRelationCode") {
			if (_value === "0"  || _value === "" || _value === "2") {
				let errorsList = this.state.errors;

				delete errorsList["entity"];
				delete errorsList["position"];

				this.setState((prevState) => ({
					person: {
						...prevState.person,
						entity: null,
						position: null,
					},
					errors: {
						...prevState.errors,
						...errorsList,
					},
				}));
			} else {
				if (person.entity === "" || person.entity === null) {
					this.setState((prevState) => ({
						errors: {
							...prevState.errors,
							entity: true,
						},
					}));
				}
				if (person.position === "" || person.position === null) {
					this.setState((prevState) => ({
						errors: {
							...prevState.errors,
							position: true,
						},
					}));
				}
			}
		}

		if (name === "placeOfBirthCountryCode") {
			if (_value && _value !== "PT") {
				let errorsList = this.state.errors;

				delete errorsList["placeOfBirthDistrictCode"];
				delete errorsList["placeOfBirthDistrictValue"];
				delete errorsList["placeOfBirthCountyCode"];
				delete errorsList["placeOfBirthCountyValue"];

				this.setState((prevState) => ({
					person: {
						...prevState.person,
						placeOfBirthDistrictCode: null,
						placeOfBirthDistrictValue: null,
						placeOfBirthCountyCode: null,
						placeOfBirthCountyValue: null,
					},
					errors: {
						...prevState.errors,
						...errorsList,
					},
				}));
			}
		}

		this.setState(
			(prevState) => ({
				summary: {
					...prevState.summary,
					[name]: _value,
				},
				person: {
					...prevState.person,
					[name]: _value,
				},
				product: {
					...prevState.product,
					[name]: _value,
				},
				consentData: {
					...prevState.consentData,
					[name]: _files,
				},
				finalData: {
					...prevState.finalData,
					[name]: _value,
				},
			}),
			() =>
				this.setState({
					isValid: {
						2: this.validateForm(2),
						3: Object.keys(this.state.errors) <= 0,
						4: this.validateForm(4),
						5: this.validateForm(5),
					},
				})
		);
	}

	handleSubmit(event) {
		event.preventDefault();
	}

	prevStep(name, value) {
		this.setState(
			(prevState) => {
				return {
					[name]: value,
					step: prevState.step - 1,
					errors: {},
				};
			},
			() =>
				this.setState({
					isValid: {
						2: this.validateForm(2),
						3: Object.keys(this.state.errors) <= 0,
						4: this.validateForm(4),
						5: this.validateForm(5),
					},
				})
		);
	}

	nextStep(name, value) {
		this.setState((prevState) => {
			return {
				[name]: value,
				step: prevState.step + 1,
			};
		});
	}

	goToStep(prevStep) {
		this.setState(() => {
			return {
				prevStep: this.state.step,
				step: typeof prevStep === "object" ? 6 : prevStep,
			};
		});
	}

	validateForm(step) {
		const { person, consentData, product, errors, finalData } = this.state;
		let result = true;

		switch (step) {
			case 2:
				let elementList = {
					...person.email,
					...person.identificationTypeCode,
					...person.identification,
					...person.fiscalCountryCode,
					...person.fiscalCountryValue,
					...person.districtCode,
					...person.districtValue,
					...person.mobilePhone,
					...person.address,
					...person.postalCodeCode,
					...person.place,
					...person.countyCode,
					...person.countyValue,
					...person.parishCode,
					...person.parishValue,
					...person.fiscalNumber,
					...person.addressIsvalid,
					...person.politicalRelationCode,
					...person.placeOfBirthCountryCode,
					...person.placeOfBirthCountryValue,
				};

				if (person.placeOfBirthCountryCode === "PT") {
					elementList = {
						...elementList,
						...person.placeOfBirthCountyCode,
						...person.placeOfBirthCountyValue,
						...person.placeOfBirthDistrictCode,
						...person.placeOfBirthDistrictValue,
					};
				}

				if (person.secondAddress) {
					elementList = {
						...elementList,
						...person.fiscalAddress,
						...person.fiscalPostalCodeCode,
						...person.fiscalPlace,
						...person.fiscalDistrictCode,
						...person.fiscalDistrictValue,
						...person.fiscalCountyCode,
						...person.fiscalCountyValue,
						...person.fiscalParishCode,
						...person.fiscalParishValue,
					};
				}

				if (person.politicalRelationCode) {
					elementList = {
						...elementList,
						...person.position,
						...person.entity,
					};
				}

				if (person.showIssuedBy) {
					elementList = {
						...elementList,
						...person.issuer,
						...person.issuerValue,
					};
				}

				if (person.gender) {
					elementList = {
						...elementList,
						...person.gender,
						...person.genderValue,
					};
				}

				Object.keys(elementList).forEach(function (k) {
					if (result && (elementList[k] === null || elementList[k] === "")) {
						result = false;
					}
				});
				break;
			case 4:
				result = true;

				if (
					result &&
					(product.contaCaixaM || product.ValidUseExistingAccount) &&
					(consentData.photographyFile === null ||
						consentData.photographyFile === "" ||
						consentData.documentIDCoverFile === null ||
						consentData.documentIDCoverFile === "" ||
						consentData.documentIDBackFile === null ||
						consentData.documentIDBackFile === "" ||
						consentData.addressFile === null ||
						consentData.addressFile === "")
				) {
					result = false;
				}
				if (
					result &&
					(product.contaCaixaM || product.ValidUseExistingAccount) && 
					person.identificationTypeCode.split("#")[0] !== "801" &&
					(consentData.fiscalNumberCardFile === null || consentData.fiscalNumberCardFile === "")
				) {
					result = false;
				}
				if (result && (product.contaCaixaM || product.ValidUseExistingAccount) && person.secondAddress && (consentData.secondAddressFile === null || consentData.secondAddressFile === "")) {
					result = false;
				}
				if (
					result &&
					(finalData.agreementLegalDocumentation === null ||
						finalData.agreementLegalDocumentation === "false" ||
						finalData.rgpAgrement === null ||
						finalData.rgpdAgreementCampaignsAndEvents === null)
				) {
					result = false;
				}
				if (result && !product.contaCaixaM && (consentData.photographyFile === null || consentData.photographyFile === "")) {
					result = false;
				}
				if (result && (product.contaCaixaM || product.ValidUseExistingAccount) && (person.clientPurposeBusinessRelationships === null || person.clientPurposeBusinessRelationships.length === 0 || person.clientSourceFunds === null || person.clientSourceFunds.length === 0)) {
					result = false;
				}
				let codeRequeredComments = this.state.referenceData?.sourceFunds?.find((e)=> e.requiredComments === true).code
				if (result && (product.contaCaixaM || product.ValidUseExistingAccount) && (person.clientSourceFunds.includes(codeRequeredComments) && (person.sourceFundsComments === null || person.sourceFundsComments === ""))){
					result = false;
				}
				break;
			case 5:
				result = true;
				if (
					result &&
					(consentData.confirmDataAndProducts === null ||
						consentData.confirmDataAndProducts === "false")
				) {
					result = false;
				}
				break;
			default: result = true;
		}

		return Object.keys(errors) <= 0 ? result : false;
	}

	handleValidation() {
		this.setState({
			isValid: {
				2: this.validateForm(2),
				3: Object.keys(this.state.errors) <= 0,
				4: this.validateForm(4),
				5: this.validateForm(5),
			},
		});
	}

	render() {
		const { step, summary, person, product, consentData, finalData, loading, isValid, errors } = this.state;
		let { globalResources, formResources, faqsStep2 } = this.props;

		switch (step) {
			case 1:
			default:
				return (
					<>
						<main className="page-content main-form main-form__step1">
							<LogoutBar globalResources={globalResources} />
							<MainFormStep1
								activeStep={step}
								nextStep={this.nextStep}
								prevStep={this.prevStep}
								handleChange={this.handleChange}
								data={summary}
								loadingData={loading}
								hasPreviousStep={false}
								hasNextStep={true}
								globalResources={globalResources}
								formResources={formResources}
							/>
						</main>
						<FooterDocuments globalResources={globalResources} noGlossary={true} noDocumentation={true} />
					</>
				);
			case 2:
				return (
					<>
						<main className="page-content main-form main-form__step2">
							<LogoutBar globalResources={globalResources} />
							<MainFormStep2
								referenceData={this.state.referenceData}
								activeStep={step}
								nextStep={this.nextStep}
								prevStep={this.prevStep}
								handleChange={this.handleChange}
								data={person}
								loadingData={loading}
								isValid={isValid[2]}
								hasPreviousStep={true}
								hasNextStep={true}
								globalResources={globalResources}
								formResources={formResources}
								faqsStep={faqsStep2}
								checkFormValidate={this.validateForm}
							/>
						</main>
						<FooterDocuments globalResources={globalResources} noDocumentation={true} />
					</>
				);
			case 3:
				return (
					<>
						<main className="page-content main-form main-form__step3">
							<LogoutBar globalResources={globalResources} />
							<MainFormStep3
								activeStep={step}
								nextStep={this.nextStep}
								prevStep={this.prevStep}
								onChangeFunction={this.handleChange}
								data={product}
								errors={errors}
								personData={person}
								loadingData={loading}
								isValid={isValid[3]}
								hasPreviousStep={true}
								hasNextStep={true}
								globalResources={globalResources}
								formResources={formResources}
								checkFormValidate={this.validateForm}
							/>
						</main>
						<FooterDocuments globalResources={globalResources} onGoToStep={this.goToStep} />
					</>
				);
			case 4:
				return (
					<>
						<main className="page-content main-form main-form__step4">
							<LogoutBar globalResources={globalResources} />
							<MainFormStep4
								referenceData={this.state.referenceData}
								activeStep={step}
								nextStep={this.nextStep}
								prevStep={this.prevStep}
								handleChange={this.handleChange}
								data={consentData}
								step2Values={person}
								loadingData={loading}
								isValid={isValid[4]}
								hasPreviousStep={true}
								hasNextStep={true}
								globalResources={globalResources}
								formResources={formResources}
								checkFormValidate={this.handleValidation}
								fullDate={this.state}
							/>
						</main>
						<FooterDocuments globalResources={globalResources} onGoToStep={this.goToStep} />
					</>
				);
			case 5:
				return (
					<>
						<MainFormStep5
							activeStep={step}
							nextStep={this.nextStep}
							prevStep={this.prevStep}
							handleChange={this.handleChange}
							handleSubmit={this.handleSubmit}
							data={{ ...finalData, ...consentData }}
							questiondata={this.state.questionData}
							loadingData={loading}
							isValid={isValid[5]}
							hasPreviousStep={true}
							hasNextStep={true}
							globalResources={globalResources}
							formResources={formResources}
						/>
						<FooterDocuments globalResources={globalResources} onGoToStep={this.goToStep} />
					</>
				);
			case 6:
				return (
					<>
						<main className="page-content main-form">
							<LogoutBar globalResources={globalResources} />
							<LegalDocumentation
								prevStep={this.prevStep}
								hasPreviousStep={true}
								globalResources={globalResources}
								formResources={formResources}
								data={this.state.prevStep}
								onGoToStep={this.goToStep}
							/>
						</main>
						<FooterDocuments globalResources={globalResources} />
					</>
				);
		}
	}
}
